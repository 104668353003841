// for modal
export const OPEN_CATEGORY_MODAL = "OPEN_CATEGORY_MODAL";
export const CLOSE_CATEGORY_MODAL = "CLOSE_CATEGORY_MODAL";

// for get category
export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST';
export const GET_CATEGORY_LIST_SUCCESS = 'GET_CATEGORY_LIST_SUCCESS';
export const GET_CATEGORY_LIST_FAILURE = 'GET_CATEGORY_LIST_FAILURE';


export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAILURE = 'ADD_CATEGORY_FAILURE';

// for admin adit category
export const GET_EDIT_CATEGORY = 'GET_EDIT_CATEGORY';
export const GET_EDIT_CATEGORY_SUCCESS = 'GET_EDIT_CATEGORY_SUCCESS';
export const GET_EDIT_CATEGORY_FAILURE = 'GET_EDIT_CATEGORY_FAILURE';

// for admin update category
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';

// for admin delete category
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';


// for web category
export const GET_WEB_CATEGORY_LIST = 'GET_WEB_CATEGORY_LIST';
export const GET_WEB_CATEGORY_LIST_SUCCESS = 'GET_WEB_CATEGORY_LIST_SUCCESS';
export const GET_WEB_CATEGORY_LIST_FAILURE = 'GET_WEB_CATEGORY_LIST_FAILURE';


//for get single category slug
export const GET_SINGLE_CATEGORY = 'GET_SINGLE_CATEGORY';
export const GET_SINGLE_CATEGORY_SUCCESS = 'GET_SINGLE_CATEGORY_SUCCESS';
export const GET_SINGLE_CATEGORY_FAILURE = 'GET_SINGLE_CATEGORY_FAILURE';



// for get category with sub category
export const GET_CATEGORY_WITH_SUB_CATEGORY_LIST = 'GET_CATEGORY_WITH_SUB_CATEGORY_LIST';
export const GET_CATEGORY_WITH_SUB_CATEGORY_LIST_SUCCESS = 'GET_CATEGORY_WITH_SUB_CATEGORY_LIST_SUCCESS';
export const GET_CATEGORY_WITH_SUB_CATEGORY_LIST_FAILURE = 'GET_CATEGORY_WITH_SUB_CATEGORY_LIST_FAILURE';



