import * as types from "./constant";

export const initialState = {

    // modal
    isCouponModalOpen: false,

    // coupon list
    isCouponListFetching: false,
    couponList: [],
    couponById: [],
    checkCouponData: {},
    couponListErrr: null,

    // add/update
    isSubmitting: false,
    couponSubmitingErrr: null,
    couponUpdatingErrr: null,
    messagecheckCouponCodeErrr: null,

};

const couponReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ALL_COUPON:
            return {
                ...state,
                isCouponListFetching: true,
                couponListErrr: null,
            };
        case types.GET_ALL_COUPON_SUCCESS:
            return {
                ...state,
                isCouponListFetching: false,
                couponList: action.payload,
                couponListErrr: null,
            };
        case types.GET_ALL_COUPON_FAILURE:
            return {
                ...state,
                isCouponListFetching: false,
                couponListErrr: action.payload,
            };

        case types.ADD_COUPON:
            return {
               ...state,
               isSubmitting: true,
               isCouponModalOpen: false,
               couponSubmitingErrr: null,
            };
        case types.ADD_COUPON_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                isCouponModalOpen: true,
                couponSubmitingErrr: action.payload.message,
            };
        case types.ADD_COUPON_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                isCouponModalOpen: false,
                couponSubmitingErrr: action.payload.error,
            };

        case types.GET_COUPON_BY_ID:
            return {
                ...state,
                isCouponListFetching: true,
                couponListErrr: null,
                isCouponModalOpen: false,
            };
        case types.GET_COUPON_BY_ID_SUCCESS:
            return {
                ...state,
                isCouponListFetching: false,
                couponById: action.payload,
                couponListErrr: null,
            };
        case types.GET_COUPON_BY_ID_FAILURE:
            return {
                ...state,
                isCouponListFetching: false,
                couponListErrr: action.payload,
            };

        case types.UPDATE_COUPON:
            return {
                ...state,
                isSubmitting: true,
                couponUpdatingErrr: null,
            };
        case types.UPDATE_COUPON_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                couponUpdatingErrr: "Coupon code has been updated successfully.",
            };
        case types.UPDATE_COUPON_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                couponUpdatingErrr: action.payload.error,
            };
        
        case types.DELETE_COUPON:
            return {
                ...state,
                isSubmitting: true,
            };
        case types.DELETE_COUPON_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
            };
        case types.DELETE_COUPON_FAILURE:
            return {
                ...state,
                isSubmitting: false,
            };

        case types.CHECK_COUPON_CODE:
            return {
                ...state,
                isSubmitting: true,
                messagecheckCouponCodeErrr: null,
                isCouponModalOpen: false,
            };
        case types.CHECK_COUPON_CODE_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                messagecheckCouponCodeErrr: "Coupon code applied successfully.",
                isCouponModalOpen: true,
                checkCouponData: action.payload.result,
            };
        case types.CHECK_COUPON_CODE_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                messagecheckCouponCodeErrr: action.payload.error,
                isCouponModalOpen: false,
            };   

        default:
            return state;     
    }

};

export default couponReducer;