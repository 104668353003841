import * as types from "./constant";


// for register user
export const registerUser = (payload) => ({
    type: types.REGISTER_USER,
    payload,
});

export const registerUserSuccess = (payload) => ({
    type: types.REGISTER_USER_SUCCESS,
    payload,
});

export const registerUserFailure = (payload) => ({
    type: types.REGISTER_USER_FAILURE,
    payload,
});



// for login user
export const loginUser = (payload) => ({
    type: types.LOGIN_USER,
    payload,
});

export const loginUserSuccess = (payload) => ({
    type: types.LOGIN_USER_SUCCESS,
    payload,
});

export const loginUserFailure = (payload) => ({
    type: types.LOGIN_USER_FAILURE,
    payload,
});

export const logoutUser = (payload) => ({
    type: types.LOGOUT_USER,
    payload,
});


// update password
export const updatePassword = (payload) => ({
    type: types.UPDATE_PASSWORD,
    payload,
});

export const updatePasswordSuccess = (payload) => ({
    type: types.UPDATE_PASSWORD_SUCCESS,
    payload,
});

export const updatePasswordFailure = (payload) => ({
    type: types.UPDATE_PASSWORD_FAILURE,
    payload,
});



// update basic detail
export const updateBasicDetail = (payload) => ({
    type: types.UPDATE_BASIC_DETAIL,
    payload,
});
export const updateBasicDetailSuccess = (payload) => ({
    type: types.UPDATE_BASIC_DETAIL_SUCCESS,
    payload,
});
export const updateBasicDetailFailure = (payload) => ({
    type: types.UPDATE_BASIC_DETAIL_FAILURE,
    payload,
});


// forgot password
export const forgotPassword = (payload) => ({
    type: types.FORGOT_PASSWORD,
    payload,
});
export const forgotPasswordSuccess = (payload) => ({
    type: types.FORGOT_PASSWORD_SUCCESS,
    payload,
});
export const forgotPasswordFailure = (payload) => ({
    type: types.FORGOT_PASSWORD_FAILURE,
    payload,
});


// reset password
export const resetPassword = (payload) => ({
    type: types.RESET_PASSWORD,
    payload,
});
export const resetPasswordSuccess = (payload) => ({
    type: types.RESET_PASSWORD_SUCCESS,
    payload,
});
export const resetPasswordFailure = (payload) => ({
    type: types.RESET_PASSWORD_FAILURE,
    payload,
});



