import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import { useFormik } from "formik";
import { Helmet } from "react-helmet";

function AffiliateProgram() {
  const validationSchema = yup.object().shape({
    first_name: yup.string().required("First name is a required"),
    last_name: yup.string().required("Last name is a required"),
    email_address: yup.string().required("Email address is a required"),
    password: yup.string().required("Password is a required"),
  });

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email_address: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <>
      <Helmet>
        <title>Affiliate Program - Gosoftware</title>
        <meta
          name="description"
          content="Earn! a 10% Cash Back on all purchases through your unique URL."
        />
      </Helmet>

      <Container className="py-4">
        <h4 className="text-center mb-4">
          Make Money with Our Affiliate Program!
        </h4>
        <Row>
          <Col>
            <p>
              -Earn! a 10% Cash Back on all purchases through your unique URL.
            </p>
            <p>-Takes less than 15 seconds to sign-up!</p>
            <p>
              -Easy to use reporting and order management to see how much money
              you’ve earned from each sale!
            </p>
            <p>-Get payment deposited through PayPal or bank transfer</p>
            <p>
              -Use your own unique URL to send it to your friends or colleagues
              to earn cash back on their purchases as well!
            </p>
          </Col>
          <Col>
            <img
                src={`${process.env.PUBLIC_URL}/Assets/shopper-approved-logo.png`}
                width="200"
                alt="shopper approved logo"
            />
            <h5 className="mt-2">
              {" "}
              <FontAwesomeIcon icon={faCheck} /> High Rating Store
            </h5>
            <h5 className="mt-2">
              {" "}
              <FontAwesomeIcon icon={faCheck} /> Instant Digital Delivery
            </h5>
            <h5 className="mt-2">
              {" "}
              <FontAwesomeIcon icon={faCheck} /> The Most Competitive Prices
            </h5>
            <h5 className="mt-2">
              {" "}
              <FontAwesomeIcon icon={faCheck} /> Expert Customer Service Team
            </h5>
          </Col>
        </Row>

        <Row>
          <Col
            sm={6}
            className="m-auto "
            style={{ backgroundColor: "#ececec", padding: "2rem" }}
          >
            <h5>Register</h5>
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group controlId="validationFormik01">
                  <Form.Label>
                    First name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    isInvalid={!!errors.first_name && touched.first_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.first_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group controlId="validationFormik02">
                  <Form.Label>
                    Last name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    isInvalid={!!errors.last_name && touched.last_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.last_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="validationFormik03">
                  <Form.Label>
                    Email address<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email_address"
                    value={values.email_address}
                    onChange={handleChange}
                    isInvalid={!!errors.email_address && touched.email_address}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.email_address}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="validationFormik04">
                  <Form.Label>
                    Password<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={!!errors.password && touched.password}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <div>
                <p>
                  Your personal data will be used to support your experience
                  throughout this website, to manage access to your account, and
                  for other purposes described in our{" "}
                  <Link to="/privacy-policy">privacy policy </Link>.
                </p>
              </div>
              <div className="d-grid mt-2">
                <Button type="submit" variant="dark">
                  Register
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AffiliateProgram;
