import { takeLatest, put, all, call} from "redux-saga/effects";
import { ADD_CATEGORY, DELETE_CATEGORY, GET_CATEGORY_LIST, GET_CATEGORY_WITH_SUB_CATEGORY_LIST, GET_EDIT_CATEGORY, GET_SINGLE_CATEGORY, GET_WEB_CATEGORY_LIST, UPDATE_CATEGORY, } from "./constant";
import api from "../../utils/api"
import { 
        getCategoryListSuccess, 
        getCategoryListFailure,
        getCategoryList,
        addCategorySuccess,
        addCategoryFailure,
        getWebCategoryListSuccess,
        getWebCategoryListFailure,
        getSingleCategorySuccess,
        getSingleCategoryFailure,
        getEditCategorySuccess,
        getEditCategoryFailure,
        updateCategorySuccess,
        updateCategoryFailure,
        deleteCategorySuccess,
        deleteCategoryFailure,
        getCategoryWithSubCategoryListSuccess,
        getCategoryWithSubCategoryListFailure,
        getEditCategory,
    } from "./action";



export function* fetchCategoryList({ payload }) {
    const token = localStorage.getItem("token");
    try {
        let query = payload ? payload:''
        const response = yield call(api(token).get, `/admin/category${query}`,);
        if(response){
            yield put(getCategoryListSuccess(response && response.result));
        }
    } catch (e) {
        yield put(getCategoryListFailure(e));
    }
}



// addCategory------
export function* addCategoryData({payload}) {
    const token = localStorage.getItem("token");
    try {
        const response = yield call(api(token).post, `/admin/category/create`, payload);
        if(response) {
            yield put(addCategorySuccess(response));
            yield put(getCategoryList());
        }
    } catch (e) {
        yield put(addCategoryFailure(e));
    }  
}

// get edit category
export function* fetchEditCategory({payload}) {
    const token = localStorage.getItem("token");
    try {
        let query = payload ? payload:''
        const response = yield call(api(token).get, `/admin/category/edit/${query}`,);
        if (response) {
            yield put(getEditCategorySuccess(response && response.result));
        }
    } catch (e) {
        yield put(getEditCategoryFailure(e));
    }
}

// post admin update category Flow
export function* updateCategoryData({payload}) {
    const token = localStorage.getItem("token");
    try {
        // let query = payload ? payload:''
        const response = yield call(api(token).post, `/admin/category/update/${payload.id}`, payload.formData);
        // response = yield response.json();
        if(response) {
            const id = response.result.id
            yield put(updateCategorySuccess(response));
            yield put(getEditCategory(id));
        }
        
    } catch (e) {
        yield put(updateCategoryFailure(e));
    } 
}

//for admin delete category
export function* deleteCategoryData({payload}) {
    const token = localStorage.getItem("token");
    try {
        const response = yield call(api(token).delete, `/admin/category/delete/${payload}`,);
        // console.log(response)
        if(response) {
            yield put(deleteCategorySuccess(response));
            yield put(getCategoryList());
            
        }
    } catch (e) {
        yield put(deleteCategoryFailure(e));
        
    }
}


// get web category list
export function* fetchWebCategoryList({payload}) {
    try {
        let query = payload ? payload:''
        const response = yield call(api().get, `/category${query}`,);
        if (response) {
            yield put(getWebCategoryListSuccess(response && response.result));
        }
    } catch (e) {
        yield put(getWebCategoryListFailure(e));
    }
}

// for get single category 
export function* fetchSingleCategory({payload}) {
    try {
        let query = payload ? payload : "";
        const response = yield call(api().get, `/category/${query}`,);
        // console.log("response", response);
        if (response) {
            yield put(getSingleCategorySuccess(response && response.result));
        }
    } catch (e) {
        yield put(getSingleCategoryFailure(e));
    }
}

// for get web category with sub category
export function* fetchWebCategoryWithSubCategoryList({payload}) {
    try {
        let query = payload ? payload : "";
        const response = yield call(api().get, `/category/withsubcategory${query}`,);
        if(response){
             yield put(getCategoryWithSubCategoryListSuccess(response && response.result));
        }
    } catch (e) {
        yield put(getCategoryWithSubCategoryListFailure(e));
    } 
}

// saga flow --------------

export function* fetchCategoryListFlow() {
   yield takeLatest(GET_CATEGORY_LIST, fetchCategoryList);
}

export function* addCategoryFlow() {
    yield takeLatest(ADD_CATEGORY, addCategoryData);
}

// get admin edit category flow
export function* fetchEditCategoryFlow() {
    yield takeLatest(GET_EDIT_CATEGORY, fetchEditCategory);
}

// post admin update category Flow
export function* updateCategoryFlow() {
    yield takeLatest(UPDATE_CATEGORY, updateCategoryData);
}

//for admin delete category
export function* deleteCategoryFlow() {
    yield takeLatest(DELETE_CATEGORY, deleteCategoryData);
}

//for web category
export function* fetchWebCategoryListFlow() {
    yield takeLatest(GET_WEB_CATEGORY_LIST, fetchWebCategoryList);
}

//for single category
export function* fetchSingleCategoryFlow() {
    yield takeLatest(GET_SINGLE_CATEGORY, fetchSingleCategory);
}


// for web get category with sub category
export function* fetchWebCategoryWithSubCategoryFlow(){
    yield takeLatest(GET_CATEGORY_WITH_SUB_CATEGORY_LIST, fetchWebCategoryWithSubCategoryList);
}


export default function* categorySaga() {
    yield all ([
        fetchCategoryListFlow(),
        addCategoryFlow(),
        fetchEditCategoryFlow(),
        updateCategoryFlow(),
        deleteCategoryFlow(),
        fetchWebCategoryListFlow(),
        fetchSingleCategoryFlow(),
        fetchWebCategoryWithSubCategoryFlow(),
    ]);
}

