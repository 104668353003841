import * as types from "./constant";


export const initialState = {
    isCartItemsFetching: false,
    cartItems:[],
    cartItemsErrr: null,

    // Add/Edit
   isSubmitting: false,
   isCartPageLoader: false,
   addToCartErrMessg: null,

   // for currency
   isCurrencyItemsFetching: false,
   currencyItems: {},
   currencyItemsErrr: null,

    // for currency change
    currencySelectByUser: {name:'', value:''}
  }
  

const CartReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CART:
          return {
            ...state,
            isCartItemsFetching: true,
            cartItemsErrr: null,
          };
        case types.GET_CART_SUCCESS:
          return {
            ...state,
            isCartItemsFetching: false,
            cartItems: action.payload,
            cartItemsErrr: null,
          };
        case types.GET_CART_FAILURE:
          return {
            ...state,
            isCartItemsFetching: false,
            cartItemsErrr: action.payload,
          }  


        case types.ADD_TO_CART:
            return {
              ...state,
              isSubmitting: true,
              isCartPageLoader: true,
            };
        case types.ADD_TO_CART_SUCCESS:
          return {
            ...state,
            isSubmitting: false,
            isCartPageLoader: false,
          };

        case types.ADD_TO_CART_FAILURE:
          return {
            ...state,
            isSubmitting: false,
            isCartPageLoader: false,
          };


        case types.UPDATE_CART:
          return {
            ...state,
            isSubmitting: true,
          };
        case types.UPDATE_CART_SUCCESS:
          return {
            ...state,
            isSubmitting: false,
          };
        case types.UPDATE_CART_FAILURE:
          return {
            ...state,
            isSubmitting: false,
          };


        case types.DELETE_FROM_CART:
          return {
            ...state,
            isSubmitting: true,
          };
        case types.DELETE_FROM_CART_SUCCESS:
          return {
            ...state,
            isSubmitting: false,
          };
        case types.DELETE_FROM_CART_FAILURE:
          return {
            ...state,
            isSubmitting: false,
          }; 


        case types.CURRENCY_DETAIL:
          return {
            ...state,
            isCurrencyItemsFetching: true,
            currencyItemsErrr: null,
          };
        case types.CURRENCY_DETAIL_SUCCESS:
          return {
            ...state,
            isCurrencyItemsFetching: false,
            currencyItems: action.payload,
            currencyItemsErrr: null,
          };
        case types.CURRENCY_DETAIL_FAILURE:
          return {
            ...state,
            isCurrencyItemsFetching: false,
            currencyItemsErrr: action.payload,
          };

        case types.CURRENCY_CHANGE:
          return {
            ...state,
            currencySelectByUser: action.payload,
            storeData: localStorage.setItem("selectedCurrency", JSON.stringify(action.payload)),
            };

        default:
            return state;  
    }
}

export default CartReducer;













