import * as types from "./constant";

export const initialState = {

    //get order
    isOrderPlacedListFetching: false,
    orderPlacedList: [],
    orderPlacedSingleList: [],
    orderPlacedListErrr: null,
    
    // get all order admin
    isAllOrderListFetching: false,
    allOrderList: [],
    orderListById: [],
    allOrderListErrr: null,

    // add order
    isSubmitOrder: false,
    orderPlacedMessage: null,

    // resend key / send order key
    isResendOrderKey: false,
    resendOrderKeyErr: null,
    isSendOrderKey: false,
    sendOrderKeyErr: null,
}

const OrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PLACED_ORDER:
            return {
                ...state,
                isOrderPlacedListFetching: true,
                orderPlacedListErrr: null,
            };
        case types.GET_PLACED_ORDER_SUCCESS:
            return {
                ...state,
                isOrderPlacedListFetching: false,
                orderPlacedList: action.payload,
                orderPlacedListErrr: null,
            };
        case types.GET_PLACED_ORDER_FAILURE:
            return {
                ...state,
                isOrderPlacedListFetching: false,
                orderPlacedListErrr: action.payload,
            };
            

        case types.ORDER_PLACED:
            return {
                ...state,
                isSubmitOrder: true,
                orderPlacedMessage: null,
            };
        case types.ORDER_PLACED_SUCCESS:
            return {
                ...state,
                isSubmitOrder: false,
                orderPlacedMessage: "Your order has been placed successfully.",
            };
        case types.ORDER_PLACED_FAILURE:
            return {
                ...state,
                isSubmitOrder: false,
                orderPlacedMessage: action.payload.error,
            };

        
        case types.GET_PLACED_ORDER_BY_ID:
            return {
                ...state,
                isOrderPlacedListFetching: true,
                orderPlacedListErrr: null,
            };
        case types.GET_PLACED_ORDER_BY_ID_SUCCESS:
            return {
                ...state,
                isOrderPlacedListFetching: false,
                orderPlacedSingleList: action.payload,
                orderPlacedListErrr: null,
            };
        case types.GET_PLACED_ORDER_BY_ID_FAILURE:
            return {
                isOrderPlacedListFetching: false,
                orderPlacedListErrr: action.payload,
            };

        case types.GET_ALL_ORDER:
            return {
                ...state,
                isAllOrderListFetching: true,
                allOrderListErrr: null,
            };
        case types.GET_ALL_ORDER_SUCCESS:
            return {
                ...state,
                isAllOrderListFetching: false,
                allOrderList: action.payload,
                allOrderListErrr: null,
            };
        case types.GET_ALL_ORDER_FAILURE:
            return {
                ...state,
                isAllOrderListFetching: false,
                allOrderListErrr: action.payload,
            };

        case types.GET_ORDER_BY_ID:
            return {
                ...state,
                isAllOrderListFetching: true,
                allOrderListErrr: null,
            };
        case types.GET_ORDER_BY_ID_SUCCESS:
            return {
                ...state,
                isAllOrderListFetching: false,
                orderListById: action.payload,
                allOrderListErrr: null,
            };
        case types.GET_ORDER_BY_ID_FAILURE:
            return {
                ...state,
                isAllOrderListFetching: false,
                allOrderListErrr: action.payload,
            };

        case types.RESEND_ORDER_KEY:
            return {
                ...state,
                isResendOrderKey: true,
                resendOrderKeyErr: null,
            };
        case types.RESEND_ORDER_KEY_SUCCESS:
            return {
                ...state,
                isResendOrderKey: false,
                resendOrderKeyErr: "Has been successfully send product key.",
            };
        case types.RESEND_ORDER_KEY_FAILURE:
            return {
                ...state,
                isResendOrderKey: false,
                resendOrderKeyErr: action.payload.error,
            };

        case types.SEND_ORDER_KEY:
            return {
                ...state,
                isSendOrderKey: true,
                sendOrderKeyErr: null,
            };
        case types.SEND_ORDER_KEY_SUCCESS:
            return {
                ...state,
                isSendOrderKey: false,
                sendOrderKeyErr: "Has been successfully send product key.",
            };
        case types.SEND_ORDER_KEY_FAILURE:
            return {
                ...state,
                isSendOrderKey: false,
                sendOrderKeyErr: action.payload.error,
            };


        default:
            return state;    
    }
}

export default OrderReducer;