import React from 'react';
import "./loader.scss";

function LoaderThird() {
  return (
    <>
      <div class="lds-ellipsi">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
}

export default LoaderThird;
