import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { updateCart } from "../../../store/Cart/action";
import { useDispatch } from "react-redux";

function CartItem({ value, id }) {

  const [count, setCount] = useState(value);
  const [countChangeValue, setCountChangeValue] = useState();

  // redux
  const dispatch = useDispatch();

  useEffect(()=> {
    setCountChangeValue(false);
  },[countChangeValue])

  useEffect(()=> {
    if((countChangeValue === true) && (count > 0 )){
      dispatch(updateCart({ id: id, qty: count }));
    }
  },[count])

  const countInc = () => {
    //    console.log();
    if (count < 20) {
      setCount(Number(count) + 1);
    }
    setCountChangeValue(true);
  };

  const countDesc = () => {
    if (count > 1) {
      setCount(count - 1);
    }
    setCountChangeValue(true);
  };

  const countChange = (event) => {
    setCount(event.target.value);
  };

  return (
    <>
      <Form.Group className="d-flex col-sm-6 ">
        <div className="input-group-prepend">
          <button
            className="checkout-input-btn-dcr"
            type="button"
            onClick={countDesc}
          >
            -
          </button>
        </div>
        <Form.Control
          type="text"
          className="form-control checkout-input-box"
          name="qty"
          // value={count}
          defaultValue={count}
          onChange={(event) => countChange(event)}
        />
        <div className="input-group-prepend">
          <button
            className="checkout-input-btn-inc"
            type="button"
            onClick={countInc}
          >
            +
          </button>
        </div>

      </Form.Group>
    </>
  );
}

export default CartItem;
