import * as types from "./constant";

// for get sub category
export const getSubCategoryList = (payload) => ({
    type: types.GET_SUB_CATEGORY_LIST,
    payload,
});

export const getSubCategoryListSuccess = (payload) => ({
    type: types.GET_SUB_CATEGORY_LIST_SUCCESS,
    payload,
});

export const getSubCategoryListFailure = (payload) => ({
    type: types.GET_SUB_CATEGORY_LIST_FAILURE,
    payload,
});

// for add sub category
export const addSubCategory = (payload) => ({
    type: types.ADD_SUB_CATEGORY,
    payload,
});
export const addSubCategorySuccess = (payload) => ({
    type: types.ADD_SUB_CATEGORY_SUCCESS,
    payload,
});
export const addSubCategoryFailure = (payload) => ({
    type: types.ADD_SUB_CATEGORY_FAILURE,
    payload,
});

// for get edit sub category
export const getEditSubCategory = (payload) => ({
    type: types.GET_EDIT_SUB_CATEGORY,
    payload,
});
export const getEditSubCategorySuccess = (payload) => ({
    type: types.GET_EDIT_SUB_CATEGORY_SUCCESS,
    payload,
});
export const getEditSubCategoryFailure = (payload) => ({
    type: types.GET_EDIT_SUB_CATEGORY_FAILURE,
    payload,
});

// for update sub category
export const updateSubCategory = (payload) => ({
    type: types.UPDATE_SUB_CATEGORY,
    payload,
});
export const updateSubCategorySuccess = (payload) => ({
    type: types.UPDATE_SUB_CATEGORY_SUCCESS,
    payload,
});
export const updateSubCategoryFailure = (payload) => ({
    type: types.UPDATE_SUB_CATEGORY_FAILURE,
    payload,
});

// for delete sub category
export const deleteSubCategory = (payload) => ({
    type: types.DELETE_SUB_CATEGORY,
    payload,
});
export const deleteSubCategorySuccess = (payload) => ({
    type: types.DELETE_SUB_CATEGORY_SUCCESS,
    payload,
});
export const deleteSubCategoryFailure = (payload) => ({
    type: types.DELETE_SUB_CATEGORY_FAILURE,
    payload,
});


// for web get sub category by category id
export const getSubCategoryByCategoryId = (payload) => ({
    type: types.GET_SUB_CATEGORY_BY_CATEGORY_ID,
    payload,
});
export const getSubCategoryByCategoryIdSuccess = (payload) => ({
    type: types.GET_SUB_CATEGORY_BY_CATEGORY_ID_SUCCESS,
    payload,
});
export const getSubCategoryByCategoryIdFailure = (payload) => ({
    type: types.GET_SUB_CATEGORY_BY_CATEGORY_ID_FAILURE,
    payload,
});


// for web get products by sub category slug
export const getProductsBySubCategorySlug = (payload) => ({
    type: types.GET_PRODUCTS_BY_SUB_CATEGORY_SLUG,
    payload,
});
export const getProductsBySubCategorySlugSuccess = (payload) => ({
    type: types.GET_PRODUCTS_BY_SUB_CATEGORY_SLUG_SUCCESS,
    payload,
});
export const getProductsBySubCategorySlugFailure = (payload) => ({
    type: types.GET_PRODUCTS_BY_SUB_CATEGORY_SLUG_FAILURE,
    payload,
});