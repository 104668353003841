import { takeLatest, put, all, call } from "redux-saga/effects";
import api from "../../utils/api";
import { 
  getAllOrderFailure,
  getAllOrderSuccess,
  getOrderById,
  getOrderByIdFailure,
  getOrderByIdSuccess,
  getPlacedOrderByIdFailure,
  getPlacedOrderByIdSuccess,
  getPlacedOrderFailure, 
  getPlacedOrderSuccess, 
  orderPlacedFailure, 
  orderPlacedSuccess, 
  resendOrderKeyFailure, 
  resendOrderKeySuccess,
  sendOrderKeyFailure,
  sendOrderKeySuccess
} from "./action";
import { GET_ALL_ORDER, GET_ORDER_BY_ID, GET_PLACED_ORDER, GET_PLACED_ORDER_BY_ID, ORDER_PLACED, RESEND_ORDER_KEY, SEND_ORDER_KEY } from "./constant";



// for get placed order
export function* getPlacedOrderData({payload}) {
  const token = localStorage.getItem("token");
  try {
    let query = payload ? payload : "";
    const response = yield call(api(token).get, `/order${query}`,);
    // console.log("query", response);
    
    if(response) {
      yield put(getPlacedOrderSuccess(response && response.result));
    }
  } catch (e) {
    yield put(getPlacedOrderFailure(e));
  }
}


// get placed order by id
export function* getPlacedOrderByIdData({payload}) {
  const token = localStorage.getItem("token");
  try {
    let query = payload ? payload : "";
    const response = yield call(api(token).get, `/order/${query}`);
    if(response) {
      yield put(getPlacedOrderByIdSuccess(response && response.result));
    }
  } catch (e) {
    yield put(getPlacedOrderByIdFailure(e));
  }
}



// for order placed
export function* orderPlacedData({payload}) {
  try {
      const response = yield call(api().post, `/order/create`, payload);
      const id = response.result.id
      let token = response.result.token
        // const profile = response.result
        // console.log("profile", profile)
        localStorage.setItem("token", token);
      localStorage.setItem("id", id);
      if(response) {
        localStorage.setItem("user", JSON.stringify(response.result));
        yield put(orderPlacedSuccess(response));
      }
  } catch (e) {
      yield put(orderPlacedFailure(e));
  }
} 

// get all order by admin
export function* getAllOrderData({payload}){
  const token = localStorage.getItem("token");
  try {
    // let query = payload ? payload : ""
    const response = yield call(api(token).get, `/admin/order?product=${payload.product}&search=${payload.search}&from=${payload.from}&to=${payload.to}`, payload);
    if(response) {
      yield put(getAllOrderSuccess(response && response.result));
    }
  } catch (e) {
    yield put(getAllOrderFailure(e));
  }
}

//get order detail by id admin
export function* getOrderByIdData({payload}){
  const token = localStorage.getItem("token");
  try {
    let query = payload ? payload : ""
    const response = yield call(api(token).get, `/admin/order/${query}`,);
    if(response) {
      yield put(getOrderByIdSuccess(response && response.result));
    }
  } catch (e) {
    yield put(getOrderByIdFailure(e));
  }
}

// resend order key for admin saga
export function* resendOrderKeyData({payload}){
  const token = localStorage.getItem("token");
  try {
    let query = payload ? payload : ""
    const response = yield call(api(token).post, `/admin/order/resend/key/${query}`,);
    if(response){
      yield put(resendOrderKeySuccess(response));
      yield put(getOrderById(payload));
    }
  } catch (e) {
    yield put(resendOrderKeyFailure(e));
  }
}

// send order key for admin saga
export function* sendOrderKeyData({payload}){
  const token = localStorage.getItem("token");
  try {
    // let query = payload ? payload : ""
    const response = yield call(api(token).post, `/admin/order/send/newkey/${payload.id}`, payload.formData);
    if(response){
      yield put(sendOrderKeySuccess(response));
      yield put(getOrderById(payload.id));
    }
  } catch (e) {
    yield put(sendOrderKeyFailure(e));
  }
}



// get placed order flow
export function* getPlacedOrderFlow() {
    yield takeLatest(GET_PLACED_ORDER, getPlacedOrderData);
}


// get placed order by id
export function* getPlacedOrderByIdFlow() {
  yield takeLatest(GET_PLACED_ORDER_BY_ID, getPlacedOrderByIdData);
}


// for order placed flow
export function* orderPlacedFlow() {
  yield takeLatest(ORDER_PLACED, orderPlacedData);
}


// get all order by admin
export function* getAllOrderFlow() {
  yield takeLatest(GET_ALL_ORDER, getAllOrderData);
}

//get order detail by id admin
export function* getOrderByIdFlow() {
  yield takeLatest(GET_ORDER_BY_ID, getOrderByIdData);
}


// resend order key for admin saga flow
export function* resendOrderKeyFlow(){
  yield takeLatest(RESEND_ORDER_KEY, resendOrderKeyData);
}

// send new order key for admin saga flow
export function* sendOrderKeyFlow(){
  yield takeLatest(SEND_ORDER_KEY, sendOrderKeyData);
}


export default function* OrderSaga() {
    yield all([
      orderPlacedFlow(),
      getPlacedOrderFlow(),
      getPlacedOrderByIdFlow(),
      getAllOrderFlow(),
      getOrderByIdFlow(),
      resendOrderKeyFlow(),
      sendOrderKeyFlow(),
    ]);
}