import React from "react";
import "./style.scss";
import { Row, Col } from "react-bootstrap";
import PasswordUpdate from "./Component/PasswordUpdate";
import PersonalDetail from "./Component/PersonalDetail";
import { Helmet } from "react-helmet";

function Profile() {
  return (
    <>
      <Helmet>
        <title>Gosoftware - Profile</title>
      </Helmet>

      <div className="admin-profile-page">
        <div className="admin-profile-page-data">
          <div className="admin-profile-page-title">
            <h4>Profile</h4>
          </div>
          <div className="admin-profile">
            <div className="mt-4">
              <Row>
                <Col md={6}>
                  <div className="profile-box">
                    <h6>Personal detail</h6>
                    <PersonalDetail />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="profile-box">
                    <h6>Password Update</h6>
                    <PasswordUpdate />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
