import React from "react";
import {Container} from "react-bootstrap";
import {Helmet} from "react-helmet";
// import ProdReviews from "../../Component/ProdReviews/ProdReviews";

// import { Link } from "react-router-dom";

function OurReviews() {
    var sa_review_count = 20;
    var sa_date_format = 'F j, Y';

    function saLoadScript(src) {
        var js = window.document.createElement("script");
        js.src = src;
        js.type = "text/javascript";
        document.getElementsByTagName("head")[0].appendChild(js);
    }

    saLoadScript("https://www.shopperapproved.com/merchant/30602.js");

    return (
        <>
            <Helmet>
                <title>Reviews - Gosoftware</title>
            </Helmet>

            <Container className="pt-5">
                <h2 className="text-center">What our customers say about us</h2>

                <div className="text-center">
                    <a
                        href="https://www.shopperapproved.com/reviews/gosoftwarebuy.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/Assets/shopper-approved-medal.png`}
                            alt="Shopper approved"
                        />
                    </a>
                </div>

                <div className="mt-4">
                    <div id="shopper_review_page">
                        <div id="review_header"></div>
                        <div id="merchant_page"></div>
                        <div id="review_image"><a href="https://www.shopperapproved.com/reviews/gosoftwarebuy.com"
                                                  target="_blank" rel="noreferrer"></a></div>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default OurReviews;
