export const GET_PRODUCT_KEY_LIST = 'GET_PRODUCT_KEY_LIST';
export const GET_PRODUCT_KEY_LIST_SUCCESS = 'GET_PRODUCT_KEY_LIST_SUCCESS';
export const GET_PRODUCT_KEY_LIST_FAILURE = 'GET_PRODUCT_KEY_LIST_FAILURE';

// for add product KEY
export const ADD_PRODUCT_KEY = 'ADD_PRODUCT_KEY';
export const ADD_PRODUCT_KEY_SUCCESS = 'ADD_PRODUCT_KEY_SUCCESS';
export const ADD_PRODUCT_KEY_FAILURE = 'ADD_PRODUCT_KEY_FAILURE';


// for update product key
export const UPDATE_PRODUCT_KEY = 'UPDATE_PRODUCT_KEY';
export const UPDATE_PRODUCT_KEY_SUCCESS = 'UPDATE_PRODUCT_KEY_SUCCESS';
export const UPDATE_PRODUCT_KEY_FAILURE = 'UPDATE_PRODUCT_KEY_FAILURE';


// for delete product key
export const DELETE_PRODUCT_KEY = 'DELETE_PRODUCT_KEY';
export const DELETE_PRODUCT_KEY_SUCCESS = 'DELETE_PRODUCT_KEY_SUCCESS';
export const DELETE_PRODUCT_KEY_FAILURE = 'DELETE_PRODUCT_KEY_FAILURE';


// for import product key
export const IMPORT_PRODUCT_KEY = 'IMPORT_PRODUCT_KEY';
export const IMPORT_PRODUCT_KEY_SUCCESS = 'IMPORT_PRODUCT_KEY_SUCCESS';
export const IMPORT_PRODUCT_KEY_FAILURE = 'IMPORT_PRODUCT_KEY_FAILURE';
