import React, {useEffect} from "react";
import "./style.scss";
import {Container, Row, Col, Button} from "react-bootstrap";
import Mycards from "../Shop/Mycards";
import {Helmet} from "react-helmet";
import {useParams, useNavigate} from "react-router-dom";

// start redux---
import {getSingleCategory} from "../../store/Category/action";
import {addToCart} from "../../store/Cart/action";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import Loader from "../../Component/Loader/Loader";
import CategoryBanner from "../../Component/CategoryBanner/CategoryBanner";
import CategoryBottom from "../../Component/CategoryBottom";
import NoDataFound from "../../Component/NoDataFound/index";

// redux end------------

function ProductCategory() {

    let params = useParams();
    let navigate = useNavigate();
    const [headingName, setHeadingName] = useState("");
    const [categoryImage, setCategoryImage] = useState("");
    const [categoryImageAlt, setCategoryImageAlt] = useState("");

    //redux
    const dispatch = useDispatch();
    const {currencyItems, currencySelectByUser} = useSelector((state) => state && state.CartReducer);
    const {isWebCategoryListFecthing, singleCategory, webCategoryList} =
        useSelector((state) => state && state.WebCategoryReducer);
        
    useEffect(() => {
        webCategoryList.map(item => {
            if (item.category_slug === params.slug) {
                setHeadingName(item.category_name);
                setCategoryImage(item.image);
                setCategoryImageAlt(item.category_name);
            }
        })
    }, [isWebCategoryListFecthing, params.slug]);

    useEffect(() => {
        dispatch(getSingleCategory(params.slug));
    }, [params.slug]);
    //redux

    // for category wise product component
    const CategoryProductData = () => {
        const user_id = localStorage.getItem("userId") ? localStorage.getItem("userId") : "";

        const handleClick = (id) => {
            dispatch(
                addToCart({
                    product_id: id,
                    qty: 1,
                    user_id: user_id,
                })
            );
            navigate(`${process.env.PUBLIC_URL}/checkout`);
        };

        // for courrency change start
        const {gbp} = currencyItems;
        const baseCurrency = gbp / currencySelectByUser?.value
        // for courrency change end


        return (
            <>
                {singleCategory.map((productByCat) => {
                    return (
                        <Col sm={3} key={productByCat.id} style={{textAlign: "center"}}>
                            <Mycards
                                cardLinks={
                                    `../product/${productByCat.product_slug}`
                                }
                                product_image={productByCat.product_image}
                                product_image_altName={productByCat.product_name}
                                product_name={productByCat.product_name}
                                mrp={
                                    ((productByCat.mrp) / (baseCurrency)).toFixed(2)
                                }
                                sale_price={
                                    ((productByCat.sale_price) / (baseCurrency)).toFixed(2)
                                }
                                per={
                                    `${((((productByCat.mrp) - (productByCat.sale_price)) * 100) / (productByCat.mrp)).toFixed()}%`
                                }
                                buy_now={
                                    // <Link to="/checkout">
                                    <Button
                                        className="buynow-btn"
                                        onClick={() => handleClick(productByCat.id)}
                                    >
                                        Buy Now
                                    </Button>
                                    // </Link>
                                }
                            />
                        </Col>
                    );
                })}
            </>
        );
    };
    // for category wise product show end

    // for found seo title/description
    const found = webCategoryList.find((obj) => {
        return obj.category_slug === params.slug;
    });


    return (
        <>
            {found && (
                <Helmet>
                    <title>{found.seo_title}</title>
                    <meta name="description" content={found.seo_description}/>
                </Helmet>
            )}
            <Container fluid className="category_page_topbar">
                {categoryImage ? (
                    <CategoryBanner categoryImage={categoryImage} categoryImageAlt={categoryImageAlt}/>
                ) : (
                    <div className="sub-heading-product-category">
                        <h2>{headingName}</h2>
                    </div>
                )}
            </Container>

            <Container className="gosoftware-padding">

                <Row>
                    {isWebCategoryListFecthing ? (
                        <p style={{textAlign: "center", margin: "160px 0"}}>
                            <Loader/>
                        </p>
                    ) : (
                        <>
                        {singleCategory.length === 0 ? 
                        <NoDataFound title={`No product found in ${params.slug}`} /> : 
                        <CategoryProductData/>
                        }
                        </>
                    )}

                    <br/>
                </Row>
            </Container>
            
            <CategoryBottom />
        </>
    );
}

export default ProductCategory;
