import React from "react";
import { Container, Carousel, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsArrowRightCircle } from "react-icons/bs";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import SlideTopbar from "../../../Component/SlideTopbar/SlideTopbar";


function GosoftwareSlider() {

  return (
    <>
      <SlideTopbar />
      <div className="slide">
      <LazyLoadImage 
        className="w-100 slide-img"
        src={`${process.env.PUBLIC_URL}/Assets/gosoftware-slider.png`}
        alt="We're offering the latest software for any of your business needs."
        effect="blur"
        width="100%"
      />
        <Container>
          <Carousel.Caption>
              <div>
                <h2 className="slider_caption_text">
                  We're offering the latest <br /> software for any
                  of your <br /> business needs.
                </h2>
                <Link to="/shop">
                  <Button className="banner-button"><BsArrowRightCircle /> Shop Category</Button>
                </Link>
              </div>
          </Carousel.Caption>
        </Container>
      </div>
    </>
  );
}

export default GosoftwareSlider;
