import React, { useState, useEffect } from "react";
import "../style.scss";
import { Link } from "react-router-dom";
import { FileTextOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Input,
  Table,
  Image,
  Space,
  Select, Button,
} from "antd";
import moment from "moment";
import "antd/dist/antd.css";

//----------------- start redux data
import { getProductList } from "../../../../../store/Product/action";
import { getAllOrder } from "../../../../../store/Order/action";
import { useSelector, connect } from "react-redux";
import { compose } from "redux";
import Loader from "../../../../../Component/Loader/Loader";
import NoDataFound from "../../../../../Component/NoDataFound/index";
import { Helmet } from "react-helmet";
import axios from "axios";
//----------------- end redux data


function NewOrder({allOrderData, getProductAllData}) {

  const { RangePicker } = DatePicker;

  const [loading, setLoading] = useState(true);
  const [productId, setProductId] = useState();
  const [search, setSearch] = useState();
  const [dateValue, setDateValue] = useState("");
  const [firstDate, setFirstDate] = useState();
  const [secondDate, setSecondDate] = useState();
  

  // data from redux
  const { productList } = useSelector(
    (state) => state && state.ProductReducer
  );

  const { isAllOrderListFetching, allOrderList } = useSelector(
    (state) => state && state.OrderReducer
  );

  
  useEffect(() => {
    getProductAllData({
        category: "",
        search: "",
      });
    allOrderData({
      search: search ? search : "",
      product: productId ? productId : "",
      from: firstDate ? firstDate : "",
      to: secondDate ? secondDate : "",
    })
    setLoading(false);
  }, [productId, search, firstDate, allOrderData, getProductAllData, secondDate]);
  // data from redux end


  // for export orders in

  const API_BASE_URL = process.env.REACT_APP_APIURL;
  const exportOrdersInExcelHandle = () => {
    axios(
        {
          url:API_BASE_URL+`/admin/order/export/excel?product=${productId ? productId : ""}&from=${firstDate ? firstDate : ""}&to=${secondDate ? secondDate : ""}`,
          method:'GET',
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
            'content-type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          }
        })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'orders.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
              .catch((error) => {
              });

  }

  
  // for date picker
    const data = undefined
    useEffect(()=> {
      if(dateValue === "" || dateValue === null) {
        setFirstDate(data);
        setSecondDate(data);
      } else {
        setFirstDate(moment(dateValue[0] ? dateValue[0] : "").format("YYYY-MM-DD"));
        setSecondDate(moment(dateValue[1] ? dateValue[1] : "").format("YYYY-MM-DD"));
      }
    },[dateValue, data])

  const List = allOrderList?.map((row) => ({
    key: row.id,
    product_image: row.product.product_image,
    product_name: row.product.product_name,
    customer_name: row.order.user.name,
    email_id: row.order.user.email,
    price: row.sale_price,
    currSymbol: row.order.curr_symbol,
    currencyType: row.order.currency,
    qty: row.qty,
    date: row.created_at,
    id: row.id,
    orderId: row.order_id,
    key_status: row.key_status,
  }));

  const columns = [
    {
      title: "Product",
      colSpan: 2,
      dataIndex: "product_image",
      render: (_, record) => <Link title="View order" to={"/admin/order/" + record.id}><Image src={record.product_image} width="50px" alt="Gosoftware product" /></Link>,
      align: "center",
    },
    {
      title: "Product Name",
      colSpan: 0,
      dataIndex: "product_name",
      render: (_, record) => ( 
      <>
        <Link title="View order" to={"/admin/order/" + record.id}><span style={{ color: "#000" }}>{record.product_name}</span></Link> <br />
        <Link title="View order" to={"/admin/order/" + record.id}><span style={{ color: "#000" }}>Order Number: {record.orderId}</span></Link>
      </>
      ),
      align: "left",
      width: "30%",
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      render: (_, record) => (
        <>
        <Space>
          <span>{record.customer_name}</span>
        </Space><br />
        <Space>
          <span>Key: {record.key_status === "sent" ? <span className="text-success text-capitalize">{record.key_status}</span> : <span className="text-danger text-capitalize">{record.key_status}</span>}</span>
        </Space>
        </>
      ),
      align: "center",
    },
    {
      title: "Email Id",
      dataIndex: "email_id",
      align: "center",
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (_, record) => <span className="text-uppercase">{record.currencyType}{record.currSymbol}{record.price}</span>,
      align: "center",
    },
    {
      title: "Qty",
      dataIndex: "qty",
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (date) => moment(date).format("DD/MM/YYYY"),
      align: "center",
    },
  ];


  // for search text
  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  // for date change
  const DateChange = (value) => {
    setDateValue(value);
  };

  return (
    <>
      <Helmet>
        <title>Gosoftware - Orders</title>
      </Helmet>

      <div className="admin-sales-page container-fluid">
        <div className="admin-sales-page-data">
          <div className="admin-sales-page-title row">
            <div className="col-sm-2 col-xs-12">
              <h4>Orders</h4>
            </div>
            <div className="col-sm-3 col-xs-12">
              <Space>
                <RangePicker
                  placeholder={["Start date", "End date"]}
                  // defaultValue={[moment('01/01/2022', dateFormat), moment('01/01/2022', dateFormat)]}
                  value={dateValue}
                  // format={dateFormat}
                  onChange={DateChange}
                />
              </Space>
            </div>
            <div className="col-sm-3 col-xs-12">
            <Space>
              <Select
                allowClear
                // optionFilterProp="children"
                placeholder="Select a product"
                type="text"
                style={{
                  width: 230,
                }}
                value={productId}
                onChange={(value) => setProductId(value)}
              >
                {productList.map((proItem) => {
                  return (
                    <option value={proItem.id}>
                      {proItem.product_name}
                    </option>
                  );
                })}
              </Select>
              {/* <Button onClick={categorySearch} type="primary">Search</Button> */}
             </Space>
            </div>
            <div className="col-sm-3 col-xs-12">
            <Space>
              <Input
                placeholder="Enter search text"
                onChange={handleChange}
                type="text"
                allowClear
                value={search}
              />
            </Space>
            </div>
            <div className="col-sm-1">
             <Space>
             <Button
            className="bg-success text-white"
            title="Export orders in excel"
            onClick={exportOrdersInExcelHandle}
             >
            <strong><FileTextOutlined style={{ fontWeight: "900", fontSize: "16px", verticalAlign: "middle" }} /></strong>
            </Button>
            </Space>
            </div>
          </div>
          <div className="admin-sales">

            {isAllOrderListFetching ? (
              <p style={{ textAlign: "center", margin: "80px 0px" }}>
                <Loader />
              </p>
            ) : (
              <>
              {allOrderList?.length === 0 ? 
              <NoDataFound title="No orders found" /> :
              <Table
                //  bordered
                className="ant-table-admin"
                dataSource={List}
                columns={columns}
                loading={loading}
              />
              }
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

// export default NewOrder;

function mapDispatchToProps(dispatch) {
  return {
    allOrderData: (data) => dispatch(getAllOrder(data)),
    getProductAllData: (data) => dispatch(getProductList(data)),
  };
}

const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(NewOrder);
