// get all Coupon
export const GET_ALL_COUPON = 'GET_ALL_COUPON';
export const GET_ALL_COUPON_SUCCESS = 'GET_ALL_COUPON_SUCCESS';
export const GET_ALL_COUPON_FAILURE = 'GET_ALL_COUPON_FAILURE';


// add coupon 
export const ADD_COUPON = 'ADD_COUPON';
export const ADD_COUPON_SUCCESS = 'ADD_COUPON_SUCCESS';
export const ADD_COUPON_FAILURE = 'ADD_COUPON_FAILURE';


// update coupon code
export const UPDATE_COUPON = 'UPDATE_COUPON';
export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS';
export const UPDATE_COUPON_FAILURE = 'UPDATE_COUPON_FAILURE';


// get coupon by id
export const GET_COUPON_BY_ID = 'GET_COUPON_BY_ID';
export const GET_COUPON_BY_ID_SUCCESS = 'GET_COUPON_BY_ID_SUCCESS';
export const GET_COUPON_BY_ID_FAILURE = 'GET_COUPON_BY_ID_FAILURE';


// delete coupon
export const DELETE_COUPON = 'DELETE_COUPON';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_FAILURE = 'DELETE_COUPON_FAILURE';


// for web check coupon code
export const CHECK_COUPON_CODE = 'CHECK_COUPON_CODE';
export const CHECK_COUPON_CODE_SUCCESS = 'CHECK_COUPON_CODE_SUCCESS';
export const CHECK_COUPON_CODE_FAILURE = 'CHECK_COUPON_CODE_FAILURE';

