// for get sub category
export const GET_SUB_CATEGORY_LIST = 'GET_SUB_CATEGORY_LIST';
export const GET_SUB_CATEGORY_LIST_SUCCESS = 'GET_SUB_CATEGORY_LIST_SUCCESS';
export const GET_SUB_CATEGORY_LIST_FAILURE = 'GET_SUB_CATEGORY_LIST_FAILURE';

// for add sub category
export const ADD_SUB_CATEGORY = 'ADD_SUB_CATEGORY';
export const ADD_SUB_CATEGORY_SUCCESS = 'ADD_SUB_CATEGORY_SUCCESS';
export const ADD_SUB_CATEGORY_FAILURE = 'ADD_SUB_CATEGORY_FAILURE';

// for get adit sub category
export const GET_EDIT_SUB_CATEGORY = 'GET_EDIT_SUB_CATEGORY';
export const GET_EDIT_SUB_CATEGORY_SUCCESS = 'GET_EDIT_SUB_CATEGORY_SUCCESS';
export const GET_EDIT_SUB_CATEGORY_FAILURE = 'GET_EDIT_SUB_CATEGORY_FAILURE';

// for update sub category
export const UPDATE_SUB_CATEGORY = 'UPDATE_SUB_CATEGORY';
export const UPDATE_SUB_CATEGORY_SUCCESS = 'UPDATE_SUB_CATEGORY_SUCCESS';
export const UPDATE_SUB_CATEGORY_FAILURE = 'UPDATE_SUB_CATEGORY_FAILURE';

// for delete sub category
export const DELETE_SUB_CATEGORY = 'DELETE_SUB_CATEGORY';
export const DELETE_SUB_CATEGORY_SUCCESS = 'DELETE_SUB_CATEGORY_SUCCESS';
export const DELETE_SUB_CATEGORY_FAILURE = 'DELETE_SUB_CATEGORY_FAILURE';


// for web get sub category by category id
export const GET_SUB_CATEGORY_BY_CATEGORY_ID = 'GET_SUB_CATEGORY_BY_CATEGORY_ID';
export const GET_SUB_CATEGORY_BY_CATEGORY_ID_SUCCESS = 'GET_SUB_CATEGORY_BY_CATEGORY_ID_SUCCESS';
export const GET_SUB_CATEGORY_BY_CATEGORY_ID_FAILURE = 'GET_SUB_CATEGORY_BY_CATEGORY_ID_FAILURE';


// for web get product by sub category slug
export const GET_PRODUCTS_BY_SUB_CATEGORY_SLUG = 'GET_PRODUCTS_BY_SUB_CATEGORY_SLUG';
export const GET_PRODUCTS_BY_SUB_CATEGORY_SLUG_SUCCESS = 'GET_PRODUCTS_BY_SUB_CATEGORY_SLUG_SUCCESS';
export const GET_PRODUCTS_BY_SUB_CATEGORY_SLUG_FAILURE = 'GET_PRODUCTS_BY_SUB_CATEGORY_SLUG_FAILURE';

