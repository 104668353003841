import React,{ useEffect, useState } from 'react';
import "./style.scss";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { getSubCategoryList, deleteSubCategory } from "../../../../store/SubCategory/action";
import { useDispatch, useSelector, connect} from "react-redux";
import { compose } from 'redux';
import Loader from '../../../../Component/Loader/Loader';
import NoDataFound from "../../../../Component/NoDataFound/index";
import {
  Button,
  Popconfirm,
  Table,
  Space,
  Image,
} from "antd";
import "antd/dist/antd.css";


function SubCategory ({subCategoryData}) {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  // redux data
  const { isSubCategoryListFetching, subCategoryList } = useSelector((state) => state && state.SubCategoryReducer);

  useEffect(()=> {
    setLoading(false);
    subCategoryData();
  },[subCategoryData]);

  // for data
  const subCatData = subCategoryList.map((row) => ({
    key: row.id,
    id: row.id,
    sub_category_name: row.sub_category_name,
    category_name: row.category.category_name,
    banner: row.banner,
  }))

  const columns = [
    {
      title: "Sub category",
      colSpan: 2,
      dataIndex: "banner",
      render: (banner) => <Image src={banner} width="50px" />,
      align: "center",
    },
    {
      title: "Sub category",
      colSpan: 0,
      dataIndex: "sub_category_name",
      render: (_, record) => (
        <Link to={"/admin/edit-sub-category/" + record.id} style={{color: "#000"}} >{record.sub_category_name}</Link>
      ),
      align: "left",
    },
    {
      title: "Category",
      dataIndex: "category_name",
      render: (_, record) => (
        <span>{record.category_name}</span>
      ),
      align: "center",
    },

    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (_, record) => (
        <Space>
        <Link to={"/admin/edit-sub-category/" + record.id}>
          <Button
            type="primary"
            title="Edit"
            style={{ padding: "4px 8px" }}
          >
            {" "}
            <strong>
              {" "}
              <EditOutlined
                style={{
                  fontWeight: "900",
                  fontSize: "16px",
                  verticalAlign: "middle",
                }}
              />{" "}
            </strong>{" "}
          </Button>
          </Link>

          <Popconfirm
            title="Are you sure want to delete ?"
            key={record.id}
            onConfirm={() => dispatch(deleteSubCategory(record.id))}
          >
            <Button danger type="primary" title="Delete" style={{ padding: "4px 8px" }}>
              {" "}
              <strong>
                {" "}
                <DeleteOutlined
                  style={{
                    fontWeight: "900",
                    fontSize: "16px",
                    verticalAlign: "middle",
                  }}
                />{" "}
              </strong>{" "}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];



  return (
    <>
      <Helmet>
        <title>Gosoftware - Sub Categories</title>
      </Helmet>
      <div className="admin-sub-category-page container-fluid">
        <div className="admin-sub-category-page-data">
          <div className="admin-sub-category-page-title row">
            <div className="col-sm-3 col-xs-12">
              <h4>Sub Categories</h4>
            </div>
            <div className="col-sm-6 col-xs-12">
            </div>
            <div className="col-sm-3 col-xs-12">
              <Link to="/admin/add-sub-category" className="admin-page-button">
                {" "}
                <PlusOutlined /> Add Sub Category
              </Link>
            </div>
          </div>

          <div className="admin-sub-category">
            {isSubCategoryListFetching ? (
              <p style={{ textAlign: "center", margin: "160px 0" }}>
                {" "}
                <Loader />{" "}
              </p>
            ) : (
              <>
              {subCategoryList?.length === 0 ?
              <NoDataFound title="No sub categories found" /> :
              <Table
                // bordered
                className="ant-table-admin"
                dataSource={subCatData}
                columns={columns}
                loading={loading}
              />
              }
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

// export default SubCategory;

function mapDispatchToProps(dispatch){
  return {
    subCategoryData: (data) => dispatch(getSubCategoryList(data)),
  }
}

const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(SubCategory);
