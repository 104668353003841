import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./style.scss";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import FrequentlyAskedQuestions from "../../Component/FrequentlyAskedQuestions/index"
import { Helmet } from "react-helmet";
import Select from 'react-select'

// redux
import { getSingleProduct } from "../../store/Product/action";
import { addToCart, currencyChange } from "../../store/Cart/action";
import { useDispatch, useSelector } from "react-redux";
//redux

import Loader from "../../Component/Loader/Loader";
import RelatedProducts from "./Component/RelatedProducts";


function Product() {
  
  let params = useParams();
  const navegate = useNavigate();
  const [qty, setQty] = useState(1);

  //redux
  const dispatch = useDispatch();
  const { currencyItems, currencySelectByUser } = useSelector((state) => state && state.CartReducer);
  const { isWebProductListFetching, singleProduct } = useSelector(
    (state) => state && state.WebProductReducer
  );

  const { seo_title, seo_description, product_name, sale_price, short_desc, mrp, id, product_image, desc, relevant_products } = singleProduct;

  useEffect(() => {
    dispatch(getSingleProduct(params.slug));
  }, [params.slug, dispatch]);
  //redux

  // for increment decr....
  const incNum = () => {
    if (qty < 20) {
      setQty(Number(qty) + 1);
    }
  };
  const decNum = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };
  
  const user_id = localStorage.getItem("userId") ? localStorage.getItem("userId") : "";

  // for get id
  const handaleClick = (id) => {
    dispatch(
      addToCart({
        product_id: id,
        qty: qty,
        user_id: user_id,
      })
    );
    navegate(`${process.env.PUBLIC_URL}/checkout`);
  };


  // for countries select
  const countries = [
    { value: 'GBP£', label: 'Pound sterling (£) - GBP', flag: '/Assets/uk-flag.png' },
    { value: 'USD$', label: 'United States dollar ($) - USD', flag: '/Assets/usa-flag.png' },
    { value: 'CAD$', label: 'Canada Canadian dollar ($) - CAD', flag: '/Assets/canada-flag.png' },
    { value: 'EUR€', label: 'Euro (€) - EUR', flag: '/Assets/euro-flag.png' },
  ]
  
  const updatedCountries = countries.map((item) => {
    item.label = (
      <div className="label">
        <img src={item.flag} alt="flag" width="40" />
        <span style={{ marginLeft: "4px" }}>{item.label}</span>
      </div>
    );
    return item;
  });


  const { cad, eur, gbp, usd } = currencyItems;

  const handleCountryChange = (selectedOption) => {
    let currencyValue = 0;
    if(selectedOption.value === "GBP£"){
        currencyValue = gbp
    } else if(selectedOption.value === "USD$"){
        currencyValue = usd
    } else if(selectedOption.value === "CAD$"){
      currencyValue = cad
    } else if(selectedOption.value === "EUR€"){
      currencyValue = eur
    } 
    dispatch(currencyChange({
      name: selectedOption.value,
      value: currencyValue
    }));
  }

    const baseCurrency = gbp / currencySelectByUser?.value
  // for countries select
  
  return (
    <>
      <Helmet>
        <title>{seo_title}</title>
        <meta name="description" content={seo_description} />
      </Helmet>

      <Container className="gosoftware-padding product-page">
        {isWebProductListFetching ? (
          <p style={{ textAlign: "center" }}>
            {" "}
            <Loader />{" "}
          </p>
        ) : (
          <Row>
            <Col sm={6}>
              <div>
                <h2><b>{product_name}</b></h2>
                <div className="mt-4">
                  <span style={{ fontSize: "1rem", marginRight: "5px" }}>5.0</span>
                  <span style={{ color: "#ff9e10" }}>
                    {" "}
                    <FontAwesomeIcon icon={faStar} />{" "}
                  </span>
                  <span style={{ color: "#ff9e10" }}>
                    {" "}
                    <FontAwesomeIcon icon={faStar} />{" "}
                  </span>
                  <span style={{ color: "#ff9e10" }}>
                    {" "}
                    <FontAwesomeIcon icon={faStar} />{" "}
                  </span>
                  <span style={{ color: "#ff9e10" }}>
                    {" "}
                    <FontAwesomeIcon icon={faStar} />{" "}
                  </span>
                  <span style={{ color: "#ff9e10" }}>
                    {" "}
                    <FontAwesomeIcon icon={faStar} />{" "}
                  </span>
                </div>
                {/* <p className="mt-4">
                  <strong>Includes: </strong>{" "}
                  <img src="../images/Includes.png" />
                </p> */}
                <p className="mt-4 exclusive-features" >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: short_desc,
                      }}
                    />
                </p>
                <hr style={{ color: "#b2b2b2" }}/>
                <div>
                  <h3>
                    <del style={{ color: "#84ade8", marginRight: "8px" }}>
                    {currencySelectByUser?.name}
                      {((mrp)/(baseCurrency)).toFixed(2)}</del>
                    <b>
                    <span style={{ color: "#0d6efd" }}>
                      {currencySelectByUser?.name}
                      {((sale_price)/(baseCurrency)).toFixed(2)}
                    </span>
                    </b>
                  </h3>

                  <div className="mt-4 buy-now">
                        <div className="col-md-8 mb-2">
                          <h6>Currency:</h6>
                          <Select 
                            defaultValue={updatedCountries.find(option => {
                              return option.value.includes(currencySelectByUser?.name)
                            })}
                            className="country-price"
                            options={updatedCountries}
                            onChange={(selectedOption)=>{
                              handleCountryChange(selectedOption);
                            }}
                          />


                        </div>
                    {/* <Form onSubmit={handleSubmit}> */}
                        <div className="col-md-4 col-xs-4">
                          <Form.Group className="d-flex product-page-proqty">
                            <div className="input-group-prepend">
                              <button
                                className="btn IncrDecrStyle"
                                type="button"
                                onClick={decNum}
                              >
                                -
                              </button>
                            </div>
                            <Form.Control
                              type="text"
                              className="IncrDecrStyle"
                              value={qty}
                              onChange={(e) => setQty(e.target.value)}
                            />
                            <div className="input-group-prepend">
                              <button
                                className="btn IncrDecrStyle"
                                type="button"
                                onClick={incNum}
                              >
                                +
                              </button>
                            </div>
                          </Form.Group>
                        </div>
                      
                      <Button
                        type="submit"
                        className="goSoftbtn"
                        onClick={() => {
                          handaleClick(id);
                        }}
                      >
                        Buy Now
                      </Button>
                    {/* </Form> */}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6}>
              <div className="product-image">
                <img
                    src={product_image}
                    alt={product_name}
                />
              </div>
            </Col>
        
            <div className="">
               <h3 className="product-page-heading">Product Description</h3>
              <div className="product_page_description smoothed" dangerouslySetInnerHTML={{ __html: desc }} />
            </div>
          </Row>
          )}

            <div className="gosoftware-padding">
            <h3 className="product-page-heading">Frequently Asked Questions</h3>
             <FrequentlyAskedQuestions />
            </div>
      </Container>

      <Container className="gosoftware-padding">
        <RelatedProducts relevant_products={relevant_products} />
      </Container>

    </>
  );
}

export default Product;
