import React,{ useEffect } from 'react';
import { useNavigate, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function ProtectedRoute(props) {
  
    const { Component } = props

  const { isAuthenticated } = useSelector((state) => state && state.AuthReducer);

  const navigate = useNavigate();
  // const history = useHistory();


// from redux
  const user = JSON.parse(localStorage.getItem("user"))
  ? JSON.parse(localStorage.getItem("user"))
  : "";

  const { roles } = user
  const userName = roles ? roles[0].name : ""

  // console.log("roles", userName);

  useEffect(() => {
    if(!isAuthenticated){
        navigate("/account");
    }else if(userName === "customer"){
      navigate("/my-account/dashboard");
    }
  },[isAuthenticated, navigate, userName]);

  return (
    <>
        {isAuthenticated ? <Component /> : <Navigate replace to={"/account"} />}
    </>
  );
}

export default ProtectedRoute;
