import React from "react";
import { Space, Popconfirm, Button, Table } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Loader from "../../../Component/Loader/Loader";
import { Row, Col } from "react-bootstrap";
import { deleteFromCart } from "../../../store/Cart/action";
import { useDispatch, useSelector } from "react-redux";
import CartDataItem from "./CartDataItem";
import ApplyCoupon from "../../Checkout/Component/ApplyCoupon";
import LoaderSecond from "../../../Component/Loader/LoaderSecond";


function CartData() {

  const dispatch = useDispatch();
  const {
    isCartItemsFetching,
    cartItems,
    currencyItems,
    currencySelectByUser
  } = useSelector((state) => state && state.CartReducer);

  // redux data end

  // for delete cart
  const handleDelete = (id) => {
    dispatch(deleteFromCart(id));
  };

  // for courrency change start
  const { gbp } = currencyItems;
  const baseCurrency = gbp / currencySelectByUser?.value
  // for courrency change end

  // for table
  const data = cartItems?.carts?.map((row) => ({
    key: row.id,
    id: row.id,
    image: row.product.product_image,
    product: row.product.product_name,
    price: row.product.sale_price,
    productslug: row.product.product_slug,
    qty: row.qty,
    subtotal: row.product.sale_price * row.qty,
  }));

  const columns = [
    {
      title: "",
      dataIndex: "action",
      align: "center",
      backgroundColor: "#f2f2f2",
      width: "10%",
      render: (_, record) => (
        <Space>
          <Popconfirm
            title="Are you sure want to delete ?"
            key={record.id}
            onConfirm={() => handleDelete(record.id)}
          >
            <Button type="primary" title="Delete" className="delete-cartItem">
              <strong>
                {" "}
                <CloseOutlined
                  className="text-danger"
                  style={{ fontSize: "20px" }}
                />{" "}
              </strong>{" "}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: "Product",
      dataIndex: "image",
      align: "left",
      colSpan: 2,
      width: "10%",
      render: (_, record) => (
        <Link style={{ color: "#000" }} to={"/product/" + record.productslug}>
          <img
              src={record.image}
              width="60"
              alt="product"
          />
        </Link>
      ),
    },
    {
      title: "Product",
      dataIndex: "product",
      colSpan: 0,
      width: "32%",
      render: (_, record) => (
        <Link style={{ color: "#000" }} to={"/product/" + record.productslug}>
          {record.product}
        </Link>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (price) => (
        <span>
          {currencySelectByUser?.name}
          {(price / baseCurrency).toFixed(2)}
        </span>
      ),
      align: "center",
    },
    {
      title: "Qty",
      dataIndex: "qty",
      align: "center",
      width: "26%",
      render: (_, record) => (
        <Space direction="vertical">
          <CartDataItem value={record.qty} id={record.id} />
        </Space>
      ),
    },

    {
      title: "Subtotal",
      dataIndex: "subtotal",
      align: "center",
      width: "14%",
      render: (_, record) => (
        <Space>
          {isCartItemsFetching ? (
            <LoaderSecond />
          ) : (
            <p>
              {currencySelectByUser?.name}
              {(record.subtotal / baseCurrency).toFixed(2)}
            </p>
          )}
        </Space>
      ),
    },
  ];


  return (
    <>
      {isCartItemsFetching ? (
        <p style={{ textAlign: "center", margin: "60px 0 60px 0" }}>
          <Loader />
        </p>
      ) : (
        <div>
          <Table
            // bordered
            className="ant-table-cart-page"
            dataSource={data}
            columns={columns}
          />
        </div>
      )}

      <Row className="mt-2 mb-2">
        <Col sm={6}>
          <ApplyCoupon />
        </Col>
      </Row>
    </>
  );
}

export default CartData;
