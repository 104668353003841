import { put, takeLatest, call, all} from "redux-saga/effects";
import api from "../../utils/api";
import { ADD_PRODUCT_KEY, DELETE_PRODUCT_KEY, GET_PRODUCT_KEY_LIST, IMPORT_PRODUCT_KEY, UPDATE_PRODUCT_KEY } from "./constant";
import { addProductKeyFailure, addProductKeySuccess, deleteProductKeyFailure, deleteProductKeySuccess, getProductKeyList, getProductKeyListFailure, 
    getProductKeyListSuccess, 
    importProductKeyFailure, 
    importProductKeySuccess, 
    updateProductKeyFailure, 
    updateProductKeySuccess
} from "./action";


// get product key list
export function* fetchProductKeyList({payload}) {
    const token = localStorage.getItem("token");
    try {
        // let query = payload ? payload : "";
        const response = yield call(api(token).get, `/admin/keys?status=${payload.status}`, payload);

        if(response) {
            yield put(getProductKeyListSuccess(response && response.result));
        }
    } catch (e) {
       yield put(getProductKeyListFailure(e));
    }
}

// add product key data
export function* addProductKeyData({payload}) {
    const token = localStorage.getItem("token");
    try {
       const response = yield call(api(token).post, `/admin/keys/create`, payload);
       if(response) {
        yield put(addProductKeySuccess(response));
        yield put(getProductKeyList());
       }
    } catch (e) {
       yield put(addProductKeyFailure(e));
    }
}


//update Product Key
export function* updateProductKeyData({payload}) {
    const token = localStorage.getItem("token");
    try {
        const response = yield call(api(token).post, `/admin/keys/update/${payload.id}`, payload.formData);
        
        if(response) {
            const keyStatus = response.result.status;
            yield put(updateProductKeySuccess(response));
            yield put(getProductKeyList({
                status: keyStatus,
              }));
        }
    } catch (e) {
        yield put(updateProductKeyFailure(e));
    }
}


// delete product key data
export function* deleteProductKeyData({payload}) {
    const token = localStorage.getItem("token");
    try {
        const response = yield call(api(token).delete, `/admin/keys/delete/${payload}`,);
        if (response) {
            yield put(deleteProductKeySuccess(response));
            yield put(getProductKeyList({
                status: "",
              }));
        }
    } catch (e) {
        yield put(deleteProductKeyFailure(e));
    }
}

// for import product key data
export function* importProductKeyData({payload}){
    const token = localStorage.getItem("token");
    try {
        const response = yield call(api(token).post, `/admin/keys/import`, payload);
        console.log("response", response);
        if(response) {
            yield put(importProductKeySuccess(response));
            yield put(getProductKeyList({
                status: "",
              }));
        }
    } catch (e) {
        yield put(importProductKeyFailure(e));
    }
}


// saga get Product key flow
export function* fetchProductKeyListFlow() {
    yield takeLatest(GET_PRODUCT_KEY_LIST, fetchProductKeyList);
}

// add product key flow
export function* addProductKeyFlow() {
    yield takeLatest(ADD_PRODUCT_KEY, addProductKeyData);
}

//update product key flow
export function* updateProductKeyFlow() {
    yield takeLatest(UPDATE_PRODUCT_KEY, updateProductKeyData);
}

// delete product key flow 
export function* deleteProductKeyFlow() {
   yield takeLatest(DELETE_PRODUCT_KEY, deleteProductKeyData);
}

// for import product key flow
export function* importProductKeyFlow() {
    yield takeLatest(IMPORT_PRODUCT_KEY, importProductKeyData);
}

export default function* productKeySaga() {
    yield all([
        fetchProductKeyListFlow(),
        addProductKeyFlow(),
        updateProductKeyFlow(),
        deleteProductKeyFlow(),
        importProductKeyFlow(),
    ]);
}