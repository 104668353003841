export const GET_CART = 'GET_CART';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_CART_FAILURE = 'GET_CART_FAILURE';

export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE';


export const UPDATE_CART = 'UPDATE_CART';
export const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS';
export const UPDATE_CART_FAILURE = 'UPDATE_CART_FAILURE';


export const DELETE_FROM_CART = 'DELETE_FROM_CART';
export const DELETE_FROM_CART_SUCCESS = 'DELETE_FROM_CART_SUCCESS';
export const DELETE_FROM_CART_FAILURE = 'DELETE_FROM_CART_FAILURE';



// for currency
export const CURRENCY_DETAIL = 'CURRENCY_DETAIL';
export const CURRENCY_DETAIL_SUCCESS = 'CURRENCY_DETAIL_SUCCESS';
export const CURRENCY_DETAIL_FAILURE = 'CURRENCY_DETAIL_FAILURE';


// for currency change
export const CURRENCY_CHANGE = 'CURRENCY_CHANGE';




