import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BsWindows } from "react-icons/bs";
import { FaCloudDownloadAlt, FaComments } from "react-icons/fa";
import { HiCreditCard } from "react-icons/hi";

function SlideTopbar() {
  return (
    <div className="slide-topbar">
      <Container fluid>
        <Row style={{ alignItems: "baseline" }}>
          <Col xs={6} md={3} sm={3}>
            <div>
              <h6 className="slider-top-bar">
                <span>
                  <BsWindows
                    style={{
                      color: "#0d6efd",
                      fontSize: "1.2rem",
                      marginRight: "3px",
                    }}
                  />
                </span>
                Authorized Microsoft Partner
              </h6>
            </div>
          </Col>
          <Col xs={6} md={3} sm={3}>
            <div>
              <h6 className="slider-top-bar">
                <span>
                  <FaCloudDownloadAlt
                    style={{
                      color: "#0d6efd",
                      fontSize: "1.6rem",
                      marginRight: "3px",
                    }}
                  />
                </span>
                Fast Delivery Within 24 hours
              </h6>
            </div>
          </Col>
          <Col xs={6} md={3} sm={3}>
            <div>
              <h6 className="slider-top-bar">
                <span>
                  <HiCreditCard
                    style={{
                      color: "#0d6efd",
                      fontSize: "1.6rem",
                      marginRight: "3px",
                    }}
                  />
                </span>
                100% Online Secure Payments
              </h6>
            </div>
          </Col>
          <Col xs={6} md={3} sm={3}>
            <div>
              <h6 className="slider-top-bar">
                <span>
                  <FaComments
                    style={{
                      color: "#0d6efd",
                      fontSize: "1.6rem",
                      marginRight: "3px",
                    }}
                  />
                </span>
                24/7 Customer Service
              </h6>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SlideTopbar;
