import React, { useEffect, useState } from "react";
import { Form, Button, FloatingLabel } from "react-bootstrap";
import FormCheckout from "../Component/FormCheckout";
import { useSelector } from "react-redux";

function Emailsub(props) {
  const [emailId, setEmailId] = useState();
  const [validEmail, setValidEmail] = useState(true);
  const [isPreviewShown, setPreviewShown] = useState(false);
  const [mailOptionShow, setMailOptionShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [disable, setDisable] = useState(false);
  const [showButton, setShowButton] = useState(true);

  // redux
  const { isAuthenticated } = useSelector(
    (state) => state && state.AuthReducer
  );

  useEffect(() => {
    setMailOptionShow(isAuthenticated);
    setPreviewShown(isAuthenticated);
    localStorage.setItem("emailId", emailId);
  }, [emailId, isAuthenticated]);
  // redux end

  const emailSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidEmail(true);
    setPreviewShown(true);

    setDisable(true);
    setEdit(true);
    setShowButton(false);
  };

  const handlePreview = (event) => {
    // event.preventDefault();
    setValidEmail(true);
    setPreviewShown(true);

    setEdit(true);
  };

  const emailHandler = (event) => {
    setEmailId(event.target.value);

    const item = event.target.value;

    if (item.length < 6) {
      setValidEmail(false);
    }
    setValidEmail(true);
  };

  const editClick = () => {
    setPreviewShown(false);
    setEdit(false);
    setDisable(false);
    setShowButton(true);
  };

  const user = JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : "";


  const { name, email } = user;

  return (
    <>
      {mailOptionShow ? (
        <div className="user-data">
          <h6 className="mb-0">
            Welcome back, {name} ({email ? email : emailId})
          </h6>
        </div>
      ) : (
        <>
          <h6 className="pay-title mb-3"><span className="pay_title_number">1</span>Contact Info</h6>
          <Form validated={validEmail} onSubmit={emailSubmit}>
            <Form.Group className="mb-3" controlId="validationCustomEmail">
              <FloatingLabel controlId="floatingEmail" label={<span>Email address<span className="text-danger">*</span></span>}>
                <Form.Control
                  type="email"
                  className="checkout-page-floating-input"
                  placeholder="Demo@gmail.com"
                  onChange={emailHandler}
                  value={emailId}
                  required
                  // pattern=".+@gmail\.com"
                  disabled={disable}
                />

                {edit ? (
                  <span onClick={editClick} className="emailEdit">
                    Edit
                  </span>
                ) : (
                  ""
                )}

                <Form.Control.Feedback type="invalid">
                  This value should be a valid email.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            <div className="d-grid">
              {showButton && (
                <Button
                  type="submit"
                  size="lg"
                  className="emailSubmit-btn"
                  onClick={props.emailSubmit}
                >
                  Continue to Payment Details
                </Button>
              )}
            </div>
          </Form>
        </>
      )}

      {props.render && (
        <Button type="submit" onClick={handlePreview}>
          Preview
        </Button>
      )}
      {isPreviewShown && <FormCheckout emailId={emailId} />}
    </>
  );
}

export default Emailsub;
