import React, { useEffect, useState } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Popconfirm,
  Table,
  Image,
  Space,
} from "antd";
import "antd/dist/antd.css";

//start redux---
import { getCategoryList, deleteCategory } from "../../../../store/Category/action";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../Component/Loader/Loader";
import NoDataFound from "../../../../Component/NoDataFound/index";
import { Helmet } from "react-helmet";
//end redux data


function Category() {

  const [loading, setLoading] = useState(true);

  //redux data start
  const dispatch = useDispatch();
  const { isCategoryListFetching, categoryList } =
    useSelector((state) => state && state.CategoryReducer);
  //redux data start

  useEffect(() => {
    setLoading(false);
    dispatch(getCategoryList());
  }, [dispatch]);

  const columns = [
    {
      title: "Category",
      colSpan: 2,
      dataIndex: "image",
      render: (image) => <Image src={image} width="50px" alt="Gosoftware category" />,
      align: "center",
    },
    {
      title: "Category",
      colSpan: 0,
      dataIndex: "category_name",
      render: (_, record) => (
        <Link to={"/admin/edit-category/" + record.id} style={{color: "#000"}} >{record.category_name}</Link>
      ),
      align: "left",
    },
    {
      title: "Order No",
      dataIndex: "order_no",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (_, record) => (
        <Space>
        <Link to={"/admin/edit-category/" + record.id}>
          <Button
            type="primary"
            title="Edit"
            style={{ padding: "4px 8px" }}
          >
            {" "}
            <strong>
              {" "}
              <EditOutlined
                style={{
                  fontWeight: "900",
                  fontSize: "16px",
                  verticalAlign: "middle",
                }}
              />{" "}
            </strong>{" "}
          </Button>
          </Link>

          <Popconfirm
            title="Are you sure want to delete ?"
            key={record.id}
            onConfirm={() => dispatch(deleteCategory(record.id))}
          >
            <Button danger type="primary" title="Delete" style={{ padding: "4px 8px" }}>
              {" "}
              <strong>
                {" "}
                <DeleteOutlined
                  style={{
                    fontWeight: "900",
                    fontSize: "16px",
                    verticalAlign: "middle",
                  }}
                />{" "}
              </strong>{" "}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  //---------------------------------------------------------

  return (
    <>
      <Helmet>
        <title>Gosoftware - Categories</title>
      </Helmet>
      <div className="admin-category-page container-fluid">
        <div className="admin-category-page-data">
          <div className="admin-category-page-title row">
            <div className="col-sm-3 col-xs-12">
              <h4>Categories</h4>
            </div>
            <div className="col-sm-6 col-xs-12">
            </div>
            <div className="col-sm-3 col-xs-12">
              <Link to="/admin/add-category" className="admin-page-button">
                {" "}
                <PlusOutlined /> Add Category
              </Link>
            </div>
          </div>

          <div className="admin-category">
            {isCategoryListFetching ? (
              <p style={{ textAlign: "center", margin: "160px 0" }}>
                {" "}
                <Loader />{" "}
              </p>
            ) : (
              <>
              {categoryList?.length === 0 ?
              <NoDataFound title="No categories found" /> : 
              <Table
                // bordered
                className="ant-table-admin"
                dataSource={categoryList}
                columns={columns}
                loading={loading}
              />
              }
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Category;
