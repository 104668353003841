import React, { useState } from "react";
import "../style.scss";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { Form, Row, Col, Spinner, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";

// for ntd table
import { LeftOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";

// for redux start
import { addCoupon } from "../../../../../store/Coupon/action";
import { useSelector, connect } from "react-redux";
import { compose } from "redux";
// for redux end

function AddCouponCode({ saveCoupon }) {
  const navigate = useNavigate();
  const [visible, setIsVisible] = useState(false);
  const { isSubmitting, couponSubmitingErrr } = useSelector(
    (state) => state && state.couponReducer
  );

  const validationSchema = yup.object().shape({
    coupon_code: yup.string().required("Coupon code is required."),
    discount_per: yup.string().required("Discount percentage is required."),
    status: yup.mixed().required("status is required"),
  });

  const onSubmit = () => {
    // console.log("values", values);

    const formData = new FormData();

    formData.append("coupon_code", values.coupon_code);
    formData.append("discount_per", values.discount_per);
    formData.append("status", values.status);

    saveCoupon(formData);

    // for error message start
      setIsVisible(true);
    // for error message end
    setTimeout(function () {
      setIsVisible(false);
    }, 8000);
  };

  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: {
      coupon_code: "",
      discount_per: "",
      status: "",
    },
    validationSchema,
    onSubmit,
  });

  return (
    <>
      <Helmet>
        <title>Gosoftware - Add Coupon Code</title>
      </Helmet>

      <div className="admin-coupon-code-page">
        <div className="admin-coupon-code-page-data">
          <div className="admin-coupon-code-page-title">
            <h4>Add Coupon Code</h4>
            <Link to="/admin/coupon" className="admin-page-button">
              {" "}
              <LeftOutlined /> Back
            </Link>
          </div>
          <div className="admin-coupon-code" style={{ padding: "1rem 2.2rem" }}>
                <div>
                  {isSubmitting ? (
                    ""
                  ) : (
                    <>
                      {visible && (
                        <>
                          {couponSubmitingErrr === "Success" ? (
                            navigate("/admin/coupon")
                          ) : (
                            <p className="error-message">
                              *{couponSubmitingErrr}
                            </p>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                  encType="multipart/form-data"
                  className="ant-table-admin"
                  style={{ padding: "2.2rem" }}
                >
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>
                        Coupon code<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="coupon_code"
                        value={values.coupon_code}
                        onChange={handleChange}
                        isInvalid={!!errors.coupon_code && touched.coupon_code}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.coupon_code}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>
                        Discount percentage
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="discount_per"
                        value={values.discount_per}
                        onChange={handleChange}
                        isInvalid={
                          !!errors.discount_per && touched.discount_per
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.discount_per}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>
                        Status<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        type="text"
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        isInvalid={!!errors.status && touched.status}
                      >
                        <option>Select status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Form.Select>

                      <Form.Control.Feedback type="invalid">
                        {errors.status}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>

                  <div>
                    <Button
                      type="submit"
                      className="subFormButton"
                      size="lg"
                      disabled={isSubmitting}
                    >
                      {isSubmitting && (
                        <Spinner animation="border" size="sm" as="span" />
                      )}
                      Submit{" "}
                    </Button>
                  </div>
                </Form>
          </div>
        </div>
      </div>
    </>
  );
}

// export default AddCouponCode;

function mapDispatchToProps(dispatch) {
  return {
    saveCoupon: (data) => dispatch(addCoupon(data)),
  };
}

const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(AddCouponCode);
