// get placed order
export const GET_PLACED_ORDER = 'GET_PLACED_ORDER';
export const GET_PLACED_ORDER_SUCCESS = 'GET_PLACED_ORDER_SUCCESS';
export const GET_PLACED_ORDER_FAILURE = 'GET_PLACED_ORDER_FAILURE';


// get placed order by id
export const GET_PLACED_ORDER_BY_ID = 'GET_PLACED_ORDER_BY_ID';
export const GET_PLACED_ORDER_BY_ID_SUCCESS = 'GET_PLACED_ORDER_BY_ID_SUCCESS';
export const GET_PLACED_ORDER_BY_ID_FAILURE = 'GET_PLACED_ORDER_BY_ID_FAILURE';


// order plased
export const ORDER_PLACED = 'ORDER_PLACED';
export const ORDER_PLACED_SUCCESS = 'ORDER_PLACED_SUCCESS';
export const ORDER_PLACED_FAILURE = 'ORDER_PLACED_FAILURE';


// get all order by admin
export const GET_ALL_ORDER = 'GET_ALL_ORDER';
export const GET_ALL_ORDER_SUCCESS = 'GET_ALL_ORDER_SUCCESS';
export const GET_ALL_ORDER_FAILURE = 'GET_ALL_ORDER_FAILURE';

// get order detail by id admin
export const GET_ORDER_BY_ID = 'GET_ORDER_BY_ID';
export const GET_ORDER_BY_ID_SUCCESS = 'GET_ORDER_BY_ID_SUCCESS';
export const GET_ORDER_BY_ID_FAILURE = 'GET_ORDER_BY_ID_FAILURE';


// resend new order key for admin
export const RESEND_ORDER_KEY = 'RESEND_ORDER_KEY';
export const RESEND_ORDER_KEY_SUCCESS = 'RESEND_ORDER_KEY_SUCCESS';
export const RESEND_ORDER_KEY_FAILURE = 'RESEND_ORDER_KEY_FAILURE';


// send new key
export const SEND_ORDER_KEY = 'SEND_ORDER_KEY';
export const SEND_ORDER_KEY_SUCCESS = 'SEND_ORDER_KEY_SUCCESS';
export const SEND_ORDER_KEY_FAILURE = 'SEND_ORDER_KEY_FAILURE';

