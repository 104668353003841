import { configureStore } from "@reduxjs/toolkit";
import productSaga from "./Product/saga";
import categorySaga from "./Category/saga";
import AuthSaga from "./Auth/saga";
import CartSaga from "./Cart/saga";
import productKeySaga from "./ProductKey/saga";
import OrderSaga from "./Order/saga";
import CouponSaga from "./Coupon/saga"; 
import SubCategorySaga from "./SubCategory/saga";

import rootReducer from "./rootReducer";
import createSageMiddleware from "redux-saga";

const sagaMiddleware = createSageMiddleware();


const store = configureStore({
    reducer: rootReducer,
    middleware: () => [sagaMiddleware]
});

sagaMiddleware.run(productSaga);
sagaMiddleware.run(categorySaga);
sagaMiddleware.run(AuthSaga);
sagaMiddleware.run(CartSaga);
sagaMiddleware.run(productKeySaga);
sagaMiddleware.run(OrderSaga);
sagaMiddleware.run(CouponSaga);
sagaMiddleware.run(SubCategorySaga);
export default store;
