import React, { useState } from "react";
import "../style.scss";
import { Form, Row, Col, Button, Spinner, InputGroup } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai"
// redux
import { registerUser } from "../../../store/Auth/action";
import { useDispatch, useSelector } from "react-redux";


function Register() {

  const [visible, setIsVisible] = useState(false);
  const [type, setType] = useState("password");
  const [eyeIcon, setEyeIcon] = useState(<AiOutlineEyeInvisible />);
  const dispatch = useDispatch();
  const { errorMessageReg, isSubmittingReg } = useSelector((state)=>state && state.AuthReducer);

  
  const validationSchema = yup.object().shape({
    email: yup.string().required("Email is a required"),
    password: yup.string().required("Password is a required"),
  });

  const onSubmit = () => {
    // console.log("values", values);

    let formData = new FormData();
    formData.append("email", values.email);
    formData.append("password", values.password);

    dispatch(registerUser(formData));

    // for error message start
      setIsVisible(true);
    // for error message end
    setTimeout(function() {
      setIsVisible(false);
    }, 8000);
  };

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit,
  });


  const passwordView = () => {
    if(type === "password"){
      setType("text");
      setEyeIcon(<AiOutlineEye />);
    }else{
      setType("password");
      setEyeIcon(<AiOutlineEyeInvisible />);
    }
  }

  return (
    <>
      <div className="login-box">
        <h2 style={{ textAlign: "center" }}>Register</h2>
        <div className="login-box-form">
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group controlId="validationFormik01">
                <Form.Label>
                  Email address<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email && touched.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="validationFormik02">
                <Form.Label>
                  Password<span className="text-danger">*</span>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type={type}
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={!!errors.password && touched.password}
                  />
                  <InputGroup.Text 
                    onClick={passwordView} 
                    className="icon-btn"
                  > 
                    {eyeIcon}
                  </InputGroup.Text>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <div>
              <p>
                Your personal data will be used to support your experience
                throughout this website, to manage access to your account, and
                for other purposes described in our{" "}
                <Link to="/privacy-policy">privacy policy </Link>.
              </p>
            </div>
            <div className="d-grid mt-2">
              <Button 
                type="submit"
                disabled={isSubmittingReg}
              >
                {isSubmittingReg && (
                  <Spinner animation="border" size="sm" as="span" style={{marginRight: "2px"}} />
                )}
                Register
              </Button>
            </div>
          </Form>
              {isSubmittingReg ? "" : 
                <>
                {visible && (
                  <>
                  {errorMessageReg === "You have successfully registered." ? 
                  "" : 
                  <p className="error-message">*{errorMessageReg}</p>
                  }
                  </>
                  )}
                </>
              }  
        </div>
      </div>
    </>
  );
}

export default Register;
