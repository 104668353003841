import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

function PlacedOrder() {
  return (
    <Container className="gosoftware-padding">
            <div className="placed_order_text">
            <img 
                src={`${process.env.PUBLIC_URL}/Assets/order-successfully.png`}
                width="100px" 
                alt="order successfully"    
                />
            <h4>Congratulations</h4>
            <h6>Your order has been placed successfully.</h6>
            <Link className="placed_order_page_btn" to={`/my-account/${"orders"}`}>
                My orders
            </Link>
            </div>
    </Container>
  );
}

export default PlacedOrder;
