import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

function AuthorizedReseller() {
  return (
    <>
      <Helmet>
        <title>Authorised Reseller - Gosoftware</title>
        <meta name="description" content="Shopping with us means knowing your purchase will be backed by a manufacturer’s warranty. As an Authorized Reseller, we only deal with brand-new," />
      </Helmet>

      <Container className="py-4">
        <h4>WHY BUY FROM AN AUTHORIZED RESELLER?</h4>

        <div>
          <h6>WARRANTIES</h6>
          <p>
            Shopping with us means knowing your purchase will be backed by a
            manufacturer’s warranty. As an Authorized Reseller, we only deal
            with brand-new, factory-fresh products with authentic, verifiable
            license keys. You’ll enjoy the peace of mind that comes with knowing
            anything you purchase is fully backed by a warranty.
          </p>
        </div>

        <div>
          <h6>INFORMATION</h6>
          <p>
            Our status as an Authorized Reseller means we’re always kept
            announced about the products we sell. Our sales team is regularly
            updated with details regarding the latest technologies and products
            from the manufacturers. Nothing beats a shopping experience with
            sellers who are able to answer almost any question you throw their
            way!
          </p>
        </div>
        <div>
          <h6>ACCESS</h6>
          <p>
            What if you have a question that we can’t immediately answer? Thanks
            to our customer service teams, we’ll be able to get a speedy answer
            to any query you may have. It also means we get immediate
            information on the latest promotions and special offers, keeping you
            in the know about the best deals.
            <br />
            Being an Authorized Reseller plays a major role in our commitment to
            always delivering excellent customer service. Our connection to all
            the brands we sell means we are ready and able to answer all your
            inquiries, and you’ll have the information you need to select the
            products that are right for you. You can rest straightforward
            understanding that Gosoftware will get you the best possible
            shopping experience before, during, and after the sale.
          </p>
        </div>
      </Container>
    </>
  );
}

export default AuthorizedReseller;
