import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const mBottom = {
  marginBottom: "40px",
};

function OrderShipment() {
  return (
    <>
      <Helmet>
        <title>Order & Delivery - Gosoftware</title>
        <meta
          name="description"
          content="The following conditions apply:"
        />
      </Helmet>

      <Container className="gosoftware-padding">
        <h4>Payment & Shipping</h4>
        <div style={mBottom}>
          <p>The following conditions apply:</p>
          <p>Shipping policy</p>
          <p>Delivery of download products:</p>
          <p>
            Download products (digital content that is not delivered on a
            physical medium) will not be shipped.
          </p>
          <p>
            Download products will be sent to you as a file by e-mail within 24
            hours after the payment.
          </p>
          <p>
            Download products can be downloaded via the link sent by email. The
            process of sending this e-mail with access data for the execution of
            the download takes place within 24 hours after placing the order.
          </p>
        </div>

        <div style={mBottom}>
          <p>Accepted payment options</p>
          <h6>– Payment by credit card</h6>
          <h6>– Payment by Paypal</h6>
        </div>

        <div style={mBottom}>
          <p>More details about the payment</p>
          <p>
            If you have any questions you will find our contact details in the
            imprint.
          </p>
        </div>
      </Container>
    </>
  );
}

export default OrderShipment;
