import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PersonalDetailWeb from "./AccountDetails/PersonalDetailWeb";
import PasswordChangeWeb from "./AccountDetails/PasswordChangeWeb";


function AccountDetails() {

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className="account-detail-form">
              <PersonalDetailWeb />
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="account-detail-form">
              <PasswordChangeWeb />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AccountDetails;
