import React, { useEffect, useState, useRef } from "react";
import "../style.scss";
import { useParams, Link } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import Loader from "../../../../../Component/Loader/Loader";
import PreviewImage from "../Component/PreviewImage";

// start redux
import { getCategoryList } from "../../../../../store/Category/action";
import {
  getEditSubCategory,
  updateSubCategory,
} from "../../../../../store/SubCategory/action";
import { useDispatch, useSelector, connect } from "react-redux";
import { compose } from "redux";
import { Helmet } from "react-helmet";
// end redux data

function EditSubCategory({ updateSubCategoryData }) {
  const fileRef = useRef(null);
  const params = useParams();
  const dispatch = useDispatch();
  const [visible, setIsVisible] = useState(false);

  const validationSchema = yup.object().shape({
    sub_category_name: yup.string().required("Sub category name is required."),
    category_id: yup.string().required("Category is required."),
    image: yup.mixed(),
    sub_category_slug: yup.string().required("Sub category slug is required."),
    seo_title: yup.string().required("SEO title is required"),
    seo_description: yup.string().required("SEO description is required"),
  });

  // redux data
  const { categoryList } = useSelector(
    (state) => state && state.CategoryReducer
  );
  const {
    editSubCategory,
    isSubmitting,
    subCategoryUpdatingErrr,
    isSubCategoryListFetching,
  } = useSelector((state) => state && state.SubCategoryReducer);
  //redux data start
  const { category_id, seo_description, seo_title, sub_category_name, sub_category_slug, banner } =
    editSubCategory;

  useEffect(() => {
    dispatch(getEditSubCategory(params.id));
    dispatch(getCategoryList());
  }, [params.id]);

  const onSubmit = () => {
    // console.log("values", values);
    const formData = new FormData();

    if (values.image) {
      formData.append("banner", values.image);
    }
    formData.append("sub_category_name", values.sub_category_name);
    formData.append("category_id", values.category_id);
    formData.append("sub_category_slug", values.sub_category_slug);
    formData.append("seo_title", values.seo_title);
    formData.append("seo_description", values.seo_description);

    updateSubCategoryData({
      id: params.id,
      formData: formData,
    });

    // for error message start
    setIsVisible(true);
    // for error message end
    setTimeout(function () {
      setIsVisible(false);
    }, 8000);
  };

  const { handleSubmit, handleChange, setFieldValue, values, touched, errors } =
    useFormik({
      initialValues: {
        sub_category_name: sub_category_name ? sub_category_name : "",
        category_id: category_id ? category_id : "",
        image: null,
        sub_category_slug: sub_category_slug ? sub_category_slug : "",
        seo_title: seo_title ? seo_title : "",
        seo_description: seo_description ? seo_description : "",
      },
      validateOnBlur: true,
      validationSchema,
      onSubmit,
      enableReinitialize: true,
    });


  return (
    <>
      <Helmet>
        <title>Gosoftware - Edit Sub Category</title>
      </Helmet>

      <div className="admin-category-page">
        <div className="admin-category-page-data">
          <div className="admin-category-page-title">
            <h4>Edit sub category</h4>
            <Link to="/admin/sub-category" className="admin-page-button">
              {" "}
              <LeftOutlined /> Back
            </Link>
          </div>

          <div className="admin-category" style={{ padding: "1rem 2.2rem" }}>
            {isSubCategoryListFetching ? (
              <p style={{ textAlign: "center", margin: "160px 0" }}>
                <Loader />
              </p>
            ) : (
              <>
                <div>
                  {isSubmitting ? (
                    ""
                  ) : (
                    <>
                      {visible && (
                        <>
                          {subCategoryUpdatingErrr ===
                          "Sub category has been updated successfully." ? (
                            <p className="error-message-success">
                              *{subCategoryUpdatingErrr}
                            </p>
                          ) : (
                            <p className="error-message">
                              *{subCategoryUpdatingErrr}
                            </p>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                  encType="multipart/form-data"
                  className="ant-table-admin"
                  style={{ padding: "2.2rem" }}
                >
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>
                        Sub category name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="sub_category_name"
                        value={values.sub_category_name}
                        onChange={handleChange}
                        isInvalid={
                          !!errors.sub_category_name &&
                          touched.sub_category_name
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.sub_category_name}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>
                        Category<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        type="text"
                        name="category_id"
                        value={values.category_id}
                        onChange={handleChange}
                        isInvalid={!!errors.category_id && touched.category_id}
                      >
                        <option>Select category</option>
                        {categoryList.map((option) => (
                          <option
                            value={option.id}
                            key={option.id}
                            selected={
                              option.id === editSubCategory.category_id
                                ? "selected"
                                : ""
                            }
                          >
                            {option.category_name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.category_id}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Col>
                      <Form.Group>
                        {/* <Form.Label>Category Image</Form.Label> */}
                        <Form.Label htmlFor="category_image_id">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            onClick={() => fileRef.current.click()}
                            className="category-image-button"
                          >
                            Upload banner
                          </Button>
                        </Form.Label>

                        <Form.Control
                          ref={fileRef}
                          id="category_image_id"
                          hidden
                          type="file"
                          // required
                          name="image"
                          accept="image/*"
                          onChange={(event) => {
                            setFieldValue("image", event.target.files[0]);
                          }}
                          isInvalid={!!errors.image}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.image}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col>
                      <div>
                        {banner === null ? 
                        "" : 
                        <img src={banner} height="120px" alt="banner" />
                        }
                        {values.image && <PreviewImage file={values.image} />}
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>
                        Sub category slug<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="sub_category_slug"
                        value={values.sub_category_slug}
                        onChange={handleChange}
                        isInvalid={!!errors.sub_category_slug && touched.sub_category_slug}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.sub_category_slug}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <h5>SEO</h5>
                    <Form.Group as={Col}>
                      <Form.Label>
                        Title<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="seo_title"
                        value={values.seo_title}
                        onChange={handleChange}
                        isInvalid={!!errors.seo_title && touched.seo_title}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.seo_title}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>
                        Meta description<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="seo_description"
                        value={values.seo_description}
                        onChange={handleChange}
                        isInvalid={
                          !!errors.seo_description && touched.seo_description
                        }
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.seo_description}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>

                  <div>
                    <Button
                      type="submit"
                      className="subFormButton"
                      size="lg"
                      disabled={isSubmitting}
                    >
                      {isSubmitting && (
                        <Spinner animation="border" size="sm" as="span" />
                      )}
                      Submit{" "}
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

// export default EditSubCategory;

function mapDispatchToProps(dispatch) {
  return {
    updateSubCategoryData: (data) => dispatch(updateSubCategory(data)),
  };
}

const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(EditSubCategory);
