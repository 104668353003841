import * as types from "./constant";

export const initialState = {

  // Category list
  isCategoryListFetching: false,
  categoryList: [],
  editCategory: [],
  categoryListErrr: null,

   // Add/Edit
   isSubmitting: false,
   categorySubmitingErrr: null,
};

const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.GET_CATEGORY_LIST:
      return {
        ...state,
        isCategoryListFetching: true,
        categoryListErrr: null,
      };
    case types.GET_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        isCategoryListFetching: false,
        categoryList: action.payload,
        categoryListErrr: null,
      };
    case types.GET_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        isCategoryListFetching: false,
        categoryListErrr: action.payload,
      };

    case types.ADD_CATEGORY:
      return {
        ...state,
        isSubmitting: true,
        categorySubmitingErrr: null,
      };
    case types.ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        categorySubmitingErrr: action.payload.message,
      };
    case types.ADD_CATEGORY_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        categorySubmitingErrr: action.payload.error,
      };
    
    case types.GET_EDIT_CATEGORY:
      return {
        ...state,
        isCategoryListFetching: true,
        categoryListErrr: null,
      };
    case types.GET_EDIT_CATEGORY_SUCCESS:
      return {
        ...state,
        isCategoryListFetching: false,
        editCategory: action.payload,
        categoryListErrr: null,
      };
    case types.GET_EDIT_CATEGORY_FAILURE:
      return {
        ...state,
        isCategoryListFetching: false,
        categoryListErrr: action.payload,
      };
      
    case types.UPDATE_CATEGORY:
      return {
        ...state,
        isSubmitting: true,
        categorySubmitingErrr: null,
      };
    case types.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        categorySubmitingErrr: "Category has been updated successfully.",
      };
    case types.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        categorySubmitingErrr: action.payload.error,
      };
    
    case types.DELETE_CATEGORY:
      return {
        ...state,
        isSubmitting: true,
      };
    case types.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case types.DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        isSubmitting: false,
      };

    default:
      return state;
  }
};

export default CategoryReducer;
