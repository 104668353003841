import React, { useEffect } from "react";
import { Container, Tab, Row, Col, Nav, Button } from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Orders from "./Component/Orders";
import Downloads from "./Component/Downloads";
import Addresses from "./Component/Addresses";
import PaymentMethods from "./Component/PaymentMethods";
import AccountDetails from "./Component/AccountDetails";

// for redux
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../store/Auth/action";


function Myaccount() {

  const param = useParams();
  const dispach = useDispatch();

  const { isAuthenticated } = useSelector((state) => state && state.AuthReducer);

  const navigate = useNavigate();
  // const history = useHistory();


// from redux
  useEffect(() => {
    if(isAuthenticated === false){
      navigate('/account');
    }
  },[isAuthenticated, navigate]);

  const user = JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : "";

  const { email } = user;

  return (
    <>
      <Helmet>
        <title>My Account - Gosoftware</title>
      </Helmet>

      <Container className="gosoftware-padding">
        <Tab.Container id="left-tabs-example" defaultActiveKey={param.active_tab}>
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Link to={`/my-account/${"dashboard"}`}>
                    <Nav.Link eventKey="dashboard" className="GoTabsReg">
                      Dashboard
                    </Nav.Link>
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to={`/my-account/${"orders"}`}>
                    <Nav.Link eventKey="orders" className="GoTabsReg">
                      Orders
                    </Nav.Link>
                  </Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Link to={`/my-account/${"downloads"}`}>
                    <Nav.Link eventKey="downloads" className="GoTabsReg">
                      Downloads
                    </Nav.Link>
                  </Link>
                </Nav.Item>
                <Nav.Item>
                <Link to={`/my-account/${"addresses"}`}>
                  <Nav.Link eventKey="addresses" className="GoTabsReg">
                    Addresses
                  </Nav.Link>
                </Link>  
                </Nav.Item> */}
                {/* <Nav.Item>
                  <Link to={`/my-account/${"payment-methods"}`}>
                    <Nav.Link eventKey="payment-methods" className="GoTabsReg">
                      Payment methods
                    </Nav.Link>
                  </Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Link to={`/my-account/${"account-details"}`}>
                    <Nav.Link eventKey="account-details" className="GoTabsReg">
                        Account details
                    </Nav.Link>
                  </Link>
                </Nav.Item>
                <Button
                  onClick={() => dispach(logoutUser())}
                  className="GoTabsReg GoTabsReg-btn"
                >
                  Logout
                </Button>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="dashboard">
                  <Container>
                    <p>
                      Hello <strong>{email}</strong> (not{" "}
                      <strong>{email}</strong>?{" "}
                      <Button
                        onClick={() => dispach(logoutUser())}
                        className="GoTabsRegbtn-in-line"
                      >
                        Log out
                      </Button>
                      )
                    </p>
                    <p>
                      From your account dashboard you can view your recent
                      orders, manage your shipping and billing addresses, and
                      edit your password and account details.{" "}
                    </p>
                  </Container>
                </Tab.Pane>
                <Tab.Pane eventKey="orders">
                  <Orders />
                </Tab.Pane>
                <Tab.Pane eventKey="downloads">
                  <Downloads />
                </Tab.Pane>
                <Tab.Pane eventKey="addresses">
                  <Addresses />
                </Tab.Pane>
                <Tab.Pane eventKey="payment-methods">
                  <PaymentMethods />
                </Tab.Pane>
                <Tab.Pane eventKey="account-details">
                  <AccountDetails />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
}

export default Myaccount;
