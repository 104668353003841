import React from "react";

function NoDataFound({title}) {
  return (
    <div className="no_data_found">
      <img
        src={`${process.env.PUBLIC_URL}/Assets/no-data-icon.png`}
        width="150px"
        alt="no-data-found"
      />
      <h4>{title}</h4>
    </div>
  );
}

export default NoDataFound;
