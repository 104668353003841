export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';
export const GET_PRODUCT_LIST_FAILURE = 'GET_PRODUCT_LIST_FAILURE';

export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAILURE = 'ADD_PRODUCT_FAILURE';

// for admin edit product
export const GET_EDIT_PRODUCT = 'GET_EDIT_PRODUCT';
export const GET_EDIT_PRODUCT_SUCCESS = 'GET_EDIT_PRODUCT_SUCCESS';
export const GET_EDIT_PRODUCT_FAILURE = 'GET_EDIT_PRODUCT_FAILURE';


// for admin update product
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILURE = 'UPDATE_PRODUCT_FAILURE';


// for admin delete product
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';


// for web
export const GET_WEB_PRODUCT_LIST = 'GET_WEB_PRODUCT_LIST';
export const GET_WEB_PRODUCT_LIST_SUCCESS = 'GET_WEB_PRODUCT_LIST_SUCCESS';
export const GET_WEB_PRODUCT_LIST_FAILURE = 'GET_WEB_PRODUCT_LIST_FAILURE';

// get single product for web
export const GET_SINGLE_PRODUCT = 'GET_SINGLE_PRODUCT';
export const GET_SINGLE_PRODUCT_SUCCESS = 'GET_SINGLE_PRODUCT_SUCCESS';
export const GET_SINGLE_PRODUCT_FAILURE = 'GET_SINGLE_PRODUCT_FAILURE';


// get product by search web
export const GET_PRODUCT_BY_SEARCH = 'GET_PRODUCT_BY_SEARCH';
export const GET_PRODUCT_BY_SEARCH_SUCCESS = 'GET_PRODUCT_BY_SEARCH_SUCCESS';
export const GET_PRODUCT_BY_SEARCH_FAILURE = 'GET_PRODUCT_BY_SEARCH_FAILURE';


// get best seller for web
export const GET_BEST_SELLER_PRODUCT = 'GET_BEST_SELLER_PRODUCT';
export const GET_BEST_SELLER_PRODUCT_SUCCESS = 'GET_BEST_SELLER_PRODUCT_SUCCESS';
export const GET_BEST_SELLER_PRODUCT_FAILURE = 'GET_BEST_SELLER_PRODUCT_FAILURE';