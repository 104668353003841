import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Work() {
  return (
    <>
      <div style={{ backgroundColor: "#f6f6f6" }}>
        <Container className="gosoftware-padding">
          <h1 className="home-page-heading">How it works?</h1>

          <Row>
            <Col sm={6}>
              <div>
                <img
                    src={`${process.env.PUBLIC_URL}/Assets/one.jpg`}
                    className="works-homepage-img"
                    alt="Gosoftware add product to cart"
                />
              </div>
            </Col>

            <Col sm={6}>
              <div style={{ marginTop: "90px" }}>
                <h4>
                  {" "}
                  <span className="myCircle">1</span>
                </h4>
                <h2 className="works-heading">Add product to cart</h2>
                <p>
                  Use the quick search feature to look for the program you want.
                  Make your selection and add it to your cart.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <div style={{ marginTop: "90px" }}>
                <h4>
                  {" "}
                  <span className="myCircle">2</span>
                </h4>
                <h2 className="works-heading">Secure checkout</h2>
                <p>
                  Keep in mind that special offers are frequently available on
                  the website.
                </p>
              </div>
            </Col>

            <Col sm={6}>
              <div>
                <img
                    src={`${process.env.PUBLIC_URL}/Assets/two.jpg`}
                    className="works-homepage-img"
                    alt="Gosoftware secure checkout"
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <div>
                <img
                    src={`${process.env.PUBLIC_URL}/Assets/three.jpg`}
                    className="works-homepage-img"
                    alt="Gosoftware product download link"
                />
              </div>
            </Col>

            <Col sm={6}>
              <div style={{ marginTop: "90px" }}>
                <h4>
                  {" "}
                  <span className="myCircle">3</span>
                </h4>
                <h2 className="works-heading">Receive download link in 24 hours</h2>
                <p>
                  You will receive your product in 24 hours after you’ve paid
                  for it. You won’t have to queue up at a store. Instead, you’ll
                  get an email with a download link. Install the program and
                  begin using it.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Work;
