import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";

const regResultBg = {
  backgroundColor: "#e8e8e8",
  borderTop: "4px solid #0d6efd",
  padding: "20px 0px 10px 16px",
};

function PaymentMethods() {
  return (
    <>
      <Container>
        <Row style={regResultBg}>
          <Col>
            <p>No saved methods found.</p>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col>
            <Button className="goSoftbtn">
              Add payment method
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PaymentMethods;
