import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { Form, Button, Spinner } from "react-bootstrap";
import { checkCouponCode } from "../../../store/Coupon/action";
import { useDispatch, useSelector } from "react-redux";

function ApplyCoupon() {
  const [visible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const {
    isSubmitting,
    messagecheckCouponCodeErrr,
  } = useSelector((state) => state && state.couponReducer);

  const emailId = localStorage.getItem("emailId");

  const user = JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : "";
  const { email } = user;

  const setEmail = email == null ? emailId : email;

  // for apply coupon code form
  const onSubmit = () => {
    // console.log("values", values);
    let formData = new FormData();
    formData.append("coupon_code", values.coupon_code);
    formData.append("email", setEmail);

    dispatch(checkCouponCode(formData));

    setTimeout(function () {
      setIsVisible(true);
    }, 500);

    setTimeout(function () {
      setIsVisible(false);
    }, 5000);

  };

  const validationSchema = yup.object().shape({
    coupon_code: yup.string().required("Coupon code is required"),
  });

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      coupon_code: "",
    },
    validationSchema,
    onSubmit,
  });

  return (
    <>
      {/* <h6 className="text-start" style={{marginLeft: "9px"}}>Have coupon?</h6> */}
      <Form noValidate onSubmit={handleSubmit}>
        <div className="d-flex px-2">
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Have coupon?"
              name="coupon_code"
              value={values.coupon_code}
              onChange={handleChange}
              isInvalid={!!errors.coupon_code && touched.coupon_code}
            />
            <Form.Control.Feedback type="invalid">
              {errors.coupon_code}
            </Form.Control.Feedback>
          </Form.Group>
          <Button
            type="submit"
            // htmlType="submit"
            style={{ height: "42px", fontSize: "16px", width: "100px" }}
            className="mx-2"
            disabled={isSubmitting}
          >
            {isSubmitting && <Spinner animation="border" size="sm" as="span" />}
            Apply
          </Button>
        </div>
      </Form>

      {isSubmitting ? "" :
        <>
        {visible && (
            <>
              {messagecheckCouponCodeErrr === "Coupon code applied successfully." ?
                  <p className="error-message-success">*{messagecheckCouponCodeErrr}</p> :
                  <p className="error-message">*{messagecheckCouponCodeErrr}</p>
              }
            </>
        )}
        </>
      }
    </>
  );
}

export default ApplyCoupon;
