import React from "react";
import "./style.scss";
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Emailsub from "./Component/Emailsub";
import Helmet from "react-helmet";
import CartOnCheckount from "./Component/CartOnCheckount";
import Loader from "../../Component/Loader/Loader";
import { useSelector } from "react-redux";

function Checkout() {

  const { isCartPageLoader, cartItems, isCartItemsFetching } = useSelector(
    (state) => state && state.CartReducer
  );


  return (
    <>
      <Helmet>
        <title>Checkout - Gosoftware</title>
      </Helmet>

      <main id="checkout" className="wrap">
          <Container fluid className="checkout_topbar">
              <div><Link to="/" className="checkout_topbar_text"><img src={`${process.env.PUBLIC_URL}/Assets/checkout-page-icon.png`} width="40px" alt="checkout page" /> <span> Gosoftware </span></Link></div>
          </Container>
          <Container id="checkout_box" className="sticky">
              {(isCartItemsFetching && cartItems?.carts?.length === 0 ) || isCartPageLoader ? (
                  <p style={{ textAlign: "center", marginTop: "4rem" }}>
                      <Loader />
                  </p>
              ) : (
                  <>
                      {cartItems?.carts?.length === 0 ?
                          <div className="checkout_nodata_found">
                              <img
                                  src={`${process.env.PUBLIC_URL}/Assets/cart-icon.png`}
                                  width="150px"
                                  alt="cart"
                              />
                              <h4>No data found</h4>
                          </div> :
                          <Container >
                              <div>
                                  <Row>
                                      <Col sm={7}>
                                          <div className="subHeader">
                                              <Link to="/cart" className="Back-to-Cart">
                                                  {" "}
                                                  ← Back to Cart
                                              </Link>
                                              <div className="sec-badge">
                                                  <div className="sec-text">Secure Checkout</div>
                                                  256-bit, Bank-Grade TLS Encryption
                                              </div>
                                          </div>

                                          <div className="pay-steps">
                                              <div className="pay-step">
                                                  <Emailsub />
                                              </div>
                                          </div>
                                      </Col>

                                      <Col sm={5} className="">
                                          <CartOnCheckount />
                                      </Col>
                                  </Row>
                              </div>
                          </Container>
                      }
                  </>
              )}
          </Container>

      </main>
    </>
  );
}

export default Checkout;
