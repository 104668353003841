import React, { useRef, useState } from "react";
import "../style.scss";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector, connect } from "react-redux";
import { compose } from "redux";
import { importProductKey } from "../../../../../store/ProductKey/action";
import { Spinner } from "reactstrap";


function ImportKey({importKeyFile}) {

    const fileRef = useRef(null);
    const navigate = useNavigate();
    const [visible, setIsVisible] = useState(false);

    const { isImportingFile, errorMessageImportingFile } = useSelector(
        (state) => state && state.ProductKeyReducer
      );


    const validationSchema = yup.object().shape({
        file: yup.mixed().required("Please select file."),
      });
    
      const onSubmit = () => {
        // console.log("file", values);
        const formData = new FormData();
        if (values.file) {
          formData.append("file", values.file);
        }
    
        importKeyFile(formData);

        // for error message start
        setIsVisible(true);
        // for error message end
        setTimeout(function () {
            setIsVisible(false);
        }, 8000);
        
      };
    
      const { handleSubmit, values, setFieldValue, touched, errors } =
        useFormik({
          initialValues: {
            file: null,
          },
          validationSchema,
          onSubmit,
        });

  return (
    <>
      <Helmet>
        <title>Gosoftware - Import Key</title>
      </Helmet>

      <div className="admin-product-key-page">
        <div className="admin-product-key-page-data">
          <div className="admin-product-key-page-title">
            <h4>Import Keys</h4>
            <Link to="/admin/product-key" className="admin-page-button">
              <LeftOutlined /> Back
            </Link>
          </div>

          <div className="admin-product-key" style={{ padding: "1rem 2.2rem" }} >
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Upload file<span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                      ref={fileRef}
                    //   hidden
                      type="file"
                      name="file"
                      accept=".csv"
                      onChange={(event) => {
                        setFieldValue("file", event.target.files[0]);
                      }}
                      isInvalid={!!errors.file && touched.file}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.file}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Button 
                type="submit"
                className="subFormButton"
                disabled={isImportingFile}
              >
                {isImportingFile && (
                    <Spinner animation="border" size="sm" as="span" />
                )}
                Upload
              </Button>
            </Form>
            <div>
            {isImportingFile ? (
                ""
              ) : (
                <>
                  {visible && (
                    <>
                      {errorMessageImportingFile === "Success" ? (
                        navigate("/admin/product-key")
                      ) : (
                        <p className="error-message">
                          *{errorMessageImportingFile}
                        </p>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// export default ImportKey;

function mapDispatchToProps(dispatch){
    return {
        importKeyFile : (data) => dispatch(importProductKey(data)),
    };
}

const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(ImportKey);
