import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function ModalBoxWeb({ show, modalname, message, btnlink, btntext }) {


  // Modal
  const [showModal, setShowModal] = useState(show);

  const handleClose = () => setShowModal(false);
  // const handleShow = () => setShow(true);

  // Modal

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        className="fade"
        backdrop="static"
        // keyboard={false}
      >
        <Modal.Header style={{ borderBottom: "none" }}>
        </Modal.Header>

          <Modal.Title style={{ textAlign: "center" }}>{modalname}</Modal.Title>

        <Modal.Body style={{ textAlign: "center" }}>
           <h6>{message}</h6>
        </Modal.Body>

        <Modal.Footer style={{ justifyContent: "center", borderTop: "none", padding: "0rem 0rem 2rem 0" }}>
          <Link to={btnlink}>
            <Button type="button">{btntext}</Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalBoxWeb;
