import React, { useEffect, useState } from "react";
import { Card, Tooltip, Button } from "antd";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Spinner, Form } from "react-bootstrap";
import { FaAngleLeft } from "react-icons/fa";
import { AiOutlineSend } from "react-icons/ai";
import { MdOutlineSendAndArchive } from "react-icons/md";
// for redux
import {
  getOrderById,
  resendOrderkey,
  sendOrderKey,
} from "../../../../../store/Order/action";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../Component/Loader/Loader";
import { Helmet } from "react-helmet";

function Order() {
  const dispatch = useDispatch();
  const param = useParams();
  const [visible, setIsVisible] = useState(false);
  const [qty, setQty] = useState(1);
  const [visibleMessage, setIsVisibleMessage] = useState(false);

  const {
    isAllOrderListFetching,
    orderListById,
    isResendOrderKey,
    resendOrderKeyErr,
    isSendOrderKey,
    sendOrderKeyErr,
  } = useSelector((state) => state && state.OrderReducer);

  useEffect(() => {
    dispatch(getOrderById(param.id));
  }, [param.id]);


  const sendOrderKeyHandle = () => {
    const formData = new FormData();
    formData.append("qty", qty);
    dispatch(sendOrderKey({
      id: orderListById?.id,
      formData: formData,
      }));

    // for error message start
    setIsVisibleMessage(true);
    // for error message end
    setTimeout(function () {
      setIsVisibleMessage(false);
    }, 8000);
  };

  const resendOrderKeyHandle = () => {
    dispatch(resendOrderkey(orderListById?.id));

    // for error message start
    setIsVisible(true);
    // for error message end
    setTimeout(function () {
      setIsVisible(false);
    }, 8000);
  };

  // for Data/time
  const NewDate = moment(orderListById?.created_at).format("DD/MM/YYYY LT");

  // for increment decr....
  const incNum = () => {
    if (qty < 10) {
      setQty(Number(qty) + 1);
    }
  };
  const decNum = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };


  return (
    <>
      <Helmet>
        <title>Gosoftware - Order Details</title>
      </Helmet>

      <div className="admin-sales-page">
        <div className="admin-sales-page-data">
          <div className="admin-sales-page-title">
            <h4>Order Details</h4>
            <Link to="/admin/orders">
              <Tooltip placement="leftTop" title="Go to new orders">
                <div className="admin-page-button">
                  {" "}
                  <FaAngleLeft /> Back{" "}
                </div>
              </Tooltip>
            </Link>
          </div>
          {isAllOrderListFetching ? (
            <p style={{ textAlign: "center", margin: "80px 0px" }}>
              <Loader />
            </p>
          ) : (
            <div className="admin-sales">
              <Row>
                <Col sm={4}>
                  <Card className="orderCard">
                    <div>
                      <h4>Order Info</h4>
                      <div style={{ overflow: "hidden" }}>
                        {" "}
                        {/*className="table-responsive" */}
                        <table className="table table-borderless mb-0">
                          <tbody>
                            <tr className="white-space-no-wrap">
                              <td className="text-muted pl-0">Order ID</td>
                              <td>{orderListById?.id}</td>
                            </tr>
                            <tr className="white-space-no-wrap">
                              <td className="text-muted pl-0">Order Number</td>
                              <td>{orderListById?.order_id}</td>
                            </tr>
                            <tr className="white-space-no-wrap">
                              <td className="text-muted pl-0">Date</td>
                              <td>{NewDate}</td>
                            </tr>
                            <tr className="white-space-no-wrap">
                              <td className="text-muted pl-0">T Id</td>
                              <td>{orderListById?.order?.transaction_id}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <hr />
                    <div>
                      <h5>Customer Details</h5>
                      <div style={{ overflow: "hidden" }}>
                        <table className="table table-borderless mb-0">
                          <tbody>
                            <tr className="white-space-no-wrap">
                              <td className="text-muted pl-0">Name</td>
                              <td>
                                {orderListById?.order?.first_name}{" "}
                                {orderListById?.order?.last_name}
                              </td>
                            </tr>
                            <tr className="white-space-no-wrap">
                              <td className="text-muted pl-0">Email</td>
                              <td>{orderListById?.order?.user?.email}</td>
                            </tr>
                            <tr className="white-space-no-wrap">
                              <td className="text-muted pl-0">Phone</td>
                              <td>{orderListById?.order?.phone}</td>
                            </tr>
                            <tr className="white-space-no-wrap">
                              <td className="text-muted pl-0">Country</td>
                              <td>{orderListById?.order?.country}</td>
                            </tr>
                            <tr className="white-space-no-wrap">
                              <td className="text-muted pl-0">Post code</td>
                              <td>{orderListById?.order?.post_code}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Card>
                </Col>

                <Col sm={8}>
                  <Card className="orderCard">
                    <div>
                      <h5>Order Item</h5>
                      <hr />
                      <div className="order-info-responsive">
                        <Row>
                          <Col sm={4}>
                            <img
                              src={orderListById?.product?.product_image}
                              width="200"
                              alt="Order product"
                            />
                          </Col>
                          <Col sm={8} style={{ marginTop: "1rem" }}>
                            <h6>
                              <strong>
                                {orderListById?.product?.product_name}
                              </strong>
                            </h6>
                            <span className="text-uppercase">
                              {orderListById?.order?.currency}
                              {orderListById?.order?.curr_symbol}
                              {orderListById?.sale_price}
                            </span>
                            <hr />
                            <h6>
                              {" "}
                              <span className="text-muted">Qty:</span>{" "}
                              <strong>{orderListById?.qty}</strong>
                            </h6>
                            <hr />
                            <h6>
                              {" "}
                              <span className="text-muted">
                                Total amount:
                              </span>{" "}
                              <strong className="text-uppercase">
                                {" "}
                                {orderListById?.order?.currency}
                                {orderListById?.order?.curr_symbol}
                                {(
                                  orderListById?.sale_price * orderListById?.qty
                                ).toFixed(2)}
                              </strong>
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="product-key-box">
                              <h6>
                                Key Status:
                                {orderListById?.key_status === "sent" ? (
                                  <span className="text-success text-capitalize">
                                    {" "}
                                    {orderListById?.key_status}
                                  </span>
                                ) : (
                                  <span className="text-danger text-capitalize">
                                    {" "}
                                    {orderListById?.key_status}
                                  </span>
                                )}
                              </h6>
                              <h6 className="text-muted">Product Key:</h6>
                              {orderListById?.product_keys?.map((item) => (
                                <h6>
                                  <strong>{item.code}</strong>
                                </h6>
                              ))}
                            </div>
                            <div style={{ marginTop: "1rem" }}>
                              {/* <Form onSubmit={handleSubmit}> */}
                              <div className="col-md-3 col-xs-3">
                                <Form.Group className="d-flex order-page-keyqty">
                                  <div className="input-group-prepend">
                                    <button
                                      className="btn IncrDecrStyle"
                                      type="button"
                                      onClick={decNum}
                                    >
                                      -
                                    </button>
                                  </div>
                                  <Form.Control
                                    type="text"
                                    className="IncrDecrStyle"
                                    value={qty}
                                    onChange={(e) => setQty(e.target.value)}
                                  />
                                  <div className="input-group-prepend">
                                    <button
                                      className="btn IncrDecrStyle"
                                      type="button"
                                      onClick={incNum}
                                    >
                                      +
                                    </button>
                                  </div>
                                </Form.Group>
                              </div>
                              <Button
                                type="primary"
                                onClick={sendOrderKeyHandle}
                                disabled={isSendOrderKey}
                                className="send_key_btn"
                              >
                                {isSendOrderKey && (
                                  <Spinner
                                    animation="border"
                                    size="sm"
                                    as="span"
                                  />
                                )}
                                <AiOutlineSend />
                                Send new key
                              </Button>

                              <Button
                                type="primary"
                                style={{ marginLeft: "1rem" }}
                                onClick={resendOrderKeyHandle}
                                disabled={isResendOrderKey}
                                className="send_key_btn"
                                ghost
                              >
                                {isResendOrderKey && (
                                  <Spinner
                                    animation="border"
                                    size="sm"
                                    as="span"
                                  />
                                )}
                                <MdOutlineSendAndArchive />
                                Resend order key
                              </Button>
                              {isResendOrderKey ? (
                                ""
                              ) : (
                                <>
                                  {visible && (
                                    <>
                                      {resendOrderKeyErr ===
                                      "Has been successfully send product key." ? (
                                        <p className="error-message-success">
                                          *{resendOrderKeyErr}
                                        </p>
                                      ) : (
                                        <p className="error-message">
                                          *{resendOrderKeyErr}
                                        </p>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                              {isSendOrderKey ? (
                                ""
                              ) : (
                                <>
                                  {visibleMessage && (
                                    <>
                                      {sendOrderKeyErr ===
                                      "Has been successfully send product key." ? (
                                        <p className="error-message-success">
                                          *{sendOrderKeyErr}
                                        </p>
                                      ) : (
                                        <p className="error-message">
                                          *{sendOrderKeyErr}
                                        </p>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Order;
