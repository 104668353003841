import React, { useState, useEffect } from "react";
import "./style.scss";
import { Outlet, Link, useNavigate } from "react-router-dom";
import {
  Navbar,
  Container,
  Nav,
  Form,
  Button,
  Row,
  Col,
  NavDropdown,
  Badge,
} from "react-bootstrap";
import { BsCartCheck } from "react-icons/bs";
import Footer from "./Footer";
import GoToTop from "../Component/GoToTop/index";
import LoaderThird from "../Component/Loader/LoaderThird";
import LoaderSecond from "../Component/Loader/LoaderSecond";
//start redux---
import { getWebCategoryList, getCategoryWithSubCategoryList } from "../store/Category/action";
import { getCart, currencyDetail, currencyChange } from "../store/Cart/action";
import { useDispatch, useSelector } from "react-redux";
//start redux---

function Header() {

  const [search, setSearch] = useState();

  // for dropdown element
  const [isToolsHovered, setIsToolsHovered] = useState('');
  
  
  // for user id
  const [user_id, setUser_id] = useState(localStorage.getItem("userId"));

  //redux data
  const dispatch = useDispatch();
  const { webCategoryWithSubCategory, isWebCategoryWithSubCategoryFecthing } = useSelector((state) => state && state.WebCategoryReducer);
  const { cartItems, currencyItems, isCurrencyItemsFetching, currencySelectByUser } = useSelector(
    (state) => state && state.CartReducer
  );
  const { userId, isAuthenticated } = useSelector(
    (state) => state && state.AuthReducer
  );
  //redux data


  useEffect(() => {
    if (user_id === null) {
      setUser_id(userId);
    }
  }, [user_id, userId]);
  localStorage.setItem("userId", user_id);

  const userIdForSend = localStorage.getItem("userId") ? localStorage.getItem("userId") : "";
 
  useEffect(() => {
    dispatch(getCart(userIdForSend));
    dispatch(currencyDetail());
  }, [userIdForSend]);


  const { gbp } = currencyItems;
  useEffect(() => {
    const selectedCurrencyData = {
      name: "GBP£",
      value:  currencyItems?.gbp
    }
    if(!localStorage.getItem("selectedCurrency") && gbp){
      localStorage.setItem("selectedCurrency", JSON.stringify(selectedCurrencyData));
    }
  },[gbp]);

  
  useEffect(() => {
    dispatch(getWebCategoryList());
    dispatch(getCategoryWithSubCategoryList());
  }, [dispatch]);

  let navigate = useNavigate();

  const submitSearch = (e) => {
    e.preventDefault();
    navigate({
      pathname: `/search/${search}`, // search-result?search=${search}   //category=${dropd}&
    });
  };

  // for cart page btn click functionality
  const cartClick = () => {
    if (cartLength === 0) {
      navigate("#");
    } else {
      navigate("/cart");
    }
  };

  // for MyAccount page btn functionality
  const user = JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : "";

    const { roles } = user
    const userName = roles ? roles[0].name : ""

  const MyAccountLink = () => {
    if(userName === "admin"){
      navigate("/admin");
    }else{
      navigate(`/my-account/${"dashboard"}`); //{`/my-account/${"orders"}`}
    }
  };

 
  const selectedCurrencySaved = JSON.parse(localStorage.getItem("selectedCurrency"));

  useEffect(() => {
    if((currencySelectByUser?.name === "") && localStorage.getItem("selectedCurrency")){
     dispatch(currencyChange({
       name: selectedCurrencySaved?.name,
       value: selectedCurrencySaved?.value,
     }));
    }
   },[localStorage.getItem("selectedCurrency")]);

  // for currency change start
  const baseCurrency = currencyItems?.gbp / currencySelectByUser?.value
  const cartTotal = ((cartItems?.total) / baseCurrency).toFixed(2);
  const cartLength = cartItems?.carts?.length;
  // for currency change end



  // dropdown element start
  const showDropdown = (index) => {
    setIsToolsHovered(index);
  };
  const hideDropdown = () => {
    setIsToolsHovered('');
  };
  // dropdown element end

  
  return (
    <>
      <div className="topbar-gosoftware" bg="">
        <Container fluid>
          <Row>
            <Col xs={12} md={8} sm={8}>
              <div>
                <p className="text-light top-bor-style ps-5">
                  <b>#1 Software Specialist </b>
                  <span style={{ marginLeft: "1rem" }}>
                   Get 15% Off on all products. Use code at checkout <b style={{color: "#ffff00"}}>GOSOFTK15</b>
                  </span>
                </p>
              </div>
            </Col>

            <Col xs={12} md={4} sm={4}>
              <Nav className="topbar-second-child">
                <Nav.Link className="nav-link-topbar">
                  <Link
                    to="/contact"
                    className="Mymenutop text-light top-bor-style"
                  >
                    Contact
                  </Link>
                </Nav.Link>
                {isAuthenticated ? (
                  <>
                    <Nav.Link className="nav-link-topbar">
                      <div
                        onClick={MyAccountLink}
                        className="Mymenutop text-light top-bor-style"
                      >
                        My Account
                      </div>
                    </Nav.Link>
                  </>
                ) : (
                  <>
                    <Nav.Link className="nav-link-topbar">
                      <Link
                        to="/account"
                        className="Mymenutop text-light top-bor-style"
                      >
                        Account
                      </Link>
                    </Nav.Link>
                  </>
                )}
              </Nav>
            </Col>
          </Row>
        </Container>
      </div>

      <Navbar
        bg=""
        expand="lg"
        style={{
          padding: "0.6rem 1.2rem",
          backgroundColor: "#fff",
          borderBottom: "1px solid #d3d3d3",
        }}
      >
        <Container fluid>
          <Navbar.Brand>
            {" "}
            <Link to="/">
              <img
                  src={`${process.env.PUBLIC_URL}/Assets/logo.png`}
                  width="160px"
                  alt="gosoftware"
              />
            </Link>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto text-light">
            {isWebCategoryWithSubCategoryFecthing || (webCategoryWithSubCategory.length === 0) ? 
            (<span style={{ marginTop: "0.6rem", marginRight: "4rem" }}><LoaderSecond /></span>) : 
             <>
              {webCategoryWithSubCategory.map((option, index)=>{
                  return (
                  <NavDropdown
                    title={option.category_name} 
                    className="gosoftware-textLight"
                    key={index}
                    as={Link}
                    to={`${process.env.PUBLIC_URL}/product-category/${option.category_slug}`}
                    onToggle={() => navigate(`${process.env.PUBLIC_URL}/product-category/${option.category_slug}`)}
                    onMouseEnter={() => showDropdown(index)}
                    onMouseLeave={() => hideDropdown()}
                    show={isToolsHovered === index}
                  >
                    {option.sub_categories.map((subOption, index)=>{
                      return (
                      <NavDropdown.Item 
                        className="gosoftware-dropdown"
                        as={Link}
                        to={`${process.env.PUBLIC_URL}/product-category/${option.category_slug}/${subOption.sub_category_slug}`}
                        key={index}
                      >
                      {subOption.sub_category_name}
                      </NavDropdown.Item>
                      )
                    })}
                  </NavDropdown>
                );
                })}
              </>
            }

              
              <Nav.Link>
                {" "}
                <Link to="/shop" className="Mymenu gosoftware-textLight">
                  Shop
                </Link>{" "}
              </Nav.Link>
              <Nav.Link>
                {" "}
                <Link to="/reviews" className="Mymenu gosoftware-textLight">
                  Reviews
                </Link>{" "}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>

          <div className="header-search-bar">
            <Form className="d-flex" onSubmit={submitSearch}>
              <Form.Control
                required
                className="header-form-control"
                type="text"
                placeholder="Search..."
                aria-label="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button type="submit" variant="dark" className="header-form-btn">
                Search
              </Button>
            </Form>
          </div>

          {/* <Link to="/cart"> */}
          <div className="header-cart" onClick={cartClick}>
              {isCurrencyItemsFetching || (currencySelectByUser?.name === "") ? <LoaderThird /> :
              <>
            <span style={{ fontSize: "0.9rem" }}>
              {currencySelectByUser?.name} {cartTotal ? cartTotal : "0.00"}
            </span>
            <div className="header-cart-icon">
              <BsCartCheck size={20} />
              <Badge pill className="header-cart-icon-child bg-danger">
                {cartLength ? cartLength : "0"}
              </Badge>
            </div>
            </> }
          </div>
          {/* </Link> */}
        </Container>
      </Navbar>

      <Outlet />
      <GoToTop />
      <Footer />
    </>
  );
}

export default Header;
