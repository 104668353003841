import React from "react";
import { Container } from "react-bootstrap";


function About() {
  return (
    <>
      <Container className="gosoftware-padding">
        <div>
          <h3>Buy your software cheaply online at Gosoftware</h3>
          <p>
            <a
              style={{ color: "#000" }}
              href="https://gosoftwarebuy.com/"
              target="_blank"
              rel="noreferrer"
            >
              <b>Gosoftware</b>
            </a>{" "}
            is your provider to buy software cheaply online. In our software
            shop you can order general PC software, antivirus programs,
            Microsoft Office and servers with corresponding license. You will
            receive the software that you buy online from us easily by e-mail,
            so that you have immediate access to your software. The licenses
            purchased from us are exclusively new. Should problems arise during
            installation, our trained customer service team can assist you via
            email, telephone, live chat or screen sharing. Of course we do not
            charge for this service.
          </p>
        </div>

        <div>
          <h3>Operating systems</h3>
          <p>
            Operating systems make your hardware usable. At{" "}
            <a
              style={{ color: "#000" }}
              href="https://gosoftwarebuy.com/"
              target="_blank"
              rel="noreferrer"
            >
              <b>Gosoftware</b>
            </a>{" "}
            you can order the operating systems for Windows 7, 8 and 10. The
            systems of these are therefore available in different versions. But
            which one should you choose? This depends on your requirements and
            wishes. When you have questions or are you not sure what to do? Our
            customer service is happy to help you on your way.
          </p>
        </div>

        <div>
          <h3>Microsoft Office</h3>
          <p>
            <a
              style={{ color: "#000" }}
              href="https://gosoftwarebuy.com/product-category/office/"
              target="_blank"
              rel="noreferrer"
            >
              <b>Microsoft Office</b>
            </a>{" "}
            is a software package that is known to everyone. However? Microsoft
            office is known for Word, Excel, PowerPoint and Outlook. Microsoft
            Office is therefore the most widely used software package for text
            and data processing. The Microsoft Office packages are suitable for
            both Windows and Mac. Microsoft is expanding more and more and
            adding more and more software to their offer. Think of Publisher,
            Access, OneNote, Project, Visio and Visual Studio.{" "}
          </p>
        </div>

        <div>
          <h3>Security software</h3>
          <p>
            It can always happen that your laptop or computer comes into contact
            with a malicious program. There are several security software or
            antivirus programs (i.e. McAfee, Norton, Kaspersky, Trend Micro,
            Webroot, Bitdefender and etc) that can counter this and protect your
            device. In the{" "}
            <a
              style={{ color: "#000" }}
              href="https://gosoftwarebuy.com/"
              target="_blank"
              rel="noreferrer"
            >
              <b>Gosoftware</b>
            </a>{" "}
            online store you will find applications that protect your mobile
            devices and your computer.{" "}
          </p>
          <p>
            In short, we at{" "}
            <a
              style={{ color: "#000" }}
              href="https://gosoftwarebuy.com/"
              target="_blank"
              rel="noreferrer"
            >
              <b>Gosoftware</b>
            </a>{" "}
            are a provider of affordable software. Take a look at our software
            shop for general software, operating systems, security software and
            everything related to this software.{" "}
          </p>
        </div>
      </Container>
    </>
  );
}

export default About;
