import * as types from './constant';

export const initialState = {
    //product Key Lst
    isProductKeyListFetching: false,
    productKeyList: [],
    productKeyListErrr: null,

    // add/edit
    isSubmitting: false,
    errorMessageProductKey: null,

    // import product key
    isImportingFile: false,
    errorMessageImportingFile: null,
    
}

const ProductKeyReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PRODUCT_KEY_LIST:
            return {
                ...state,
                isProductKeyListFetching: true,
                productKeyListErrr: null,
            };
        case types.GET_PRODUCT_KEY_LIST_SUCCESS:
            return {
                ...state,
                isProductKeyListFetching: false,
                productKeyList: action.payload,
                productKeyListErrr: null,
            };
        case types.GET_PRODUCT_KEY_LIST_FAILURE:
            return {
                ...state,
                isProductKeyListFetching: false,
                productKeyListErrr: action.payload,
            };
        
        case types.ADD_PRODUCT_KEY:
            return {
                ...state,
                isSubmitting: true,
                errorMessageProductKey: null,
            };
        case types.ADD_PRODUCT_KEY_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                errorMessageProductKey: action.payload.message,
            };
        case types.ADD_PRODUCT_KEY_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                errorMessageProductKey: action.payload.error,
            };
            
        case types.UPDATE_PRODUCT_KEY:
            return {
                ...state,
                isSubmitting: true,
            };
        case types.UPDATE_PRODUCT_KEY_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
            };
        case types.UPDATE_PRODUCT_KEY_FAILURE:
            return {
                ...state,
                isSubmitting: false,
            };
        
        case types.DELETE_PRODUCT_KEY:
            return {
               ...state,
               isSubmitting: true,
            };
        case types.DELETE_PRODUCT_KEY_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
            };
        case types.DELETE_PRODUCT_KEY_FAILURE:
            return {
                ...state,
                isSubmitting: false,
            };    

        case types.IMPORT_PRODUCT_KEY:
            return {
                ...state,
                isImportingFile: true,
                errorMessageImportingFile: null,
            };
        case types.IMPORT_PRODUCT_KEY_SUCCESS:
            return {
                ...state,
                isImportingFile: false,
                errorMessageImportingFile: action.payload.message,
            };
        case types.IMPORT_PRODUCT_KEY_FAILURE:
            return {
                ...state,
                isImportingFile: false,
                errorMessageImportingFile: action.payload.error,
            };

        default: 
          return state;
    }
};

export default ProductKeyReducer;