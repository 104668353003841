import { combineReducers } from "redux";
import ProductReducer from "./Product/reducer";
import CategoryReducer from "./Category/reducer";
import WebCategoryReducer from "./Category/webCategoryReducer";
import WebProductReducer from "./Product/webProductReducer";
import AuthReducer from "./Auth/reducer";
import CartReducer from "./Cart/reducer";
import ProductKeyReducer from "./ProductKey/reducer";
import OrderReducer from "./Order/reducer";
import couponReducer from "./Coupon/reducer";
import SubCategoryReducer from "./SubCategory/reducer";


export default combineReducers({
    ProductReducer,
    CategoryReducer,
    WebCategoryReducer,
    WebProductReducer,
    AuthReducer,
    CartReducer,
    ProductKeyReducer,
    OrderReducer,
    couponReducer,
    SubCategoryReducer,
});