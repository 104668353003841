import * as types from "./constant";


// get placed order
export const getPlacedOrder = (payload) => ({
    type: types.GET_PLACED_ORDER,
    payload,
});

export const getPlacedOrderSuccess = (payload) => ({
    type: types.GET_PLACED_ORDER_SUCCESS,
    payload,
});

export const getPlacedOrderFailure = (payload) => ({
    type: types.GET_PLACED_ORDER_FAILURE,
    payload,
});



// get placed order by id

export const getPlacedOrderById = (payload) => ({
    type: types.GET_PLACED_ORDER_BY_ID,
    payload,
});

export const getPlacedOrderByIdSuccess = (payload) => ({
    type: types.GET_PLACED_ORDER_BY_ID_SUCCESS,
    payload,
});

export const getPlacedOrderByIdFailure = (payload) => ({
    type: types.GET_PLACED_ORDER_BY_ID_FAILURE,
    payload,
});


// for order placed
export const orderPlaced = (payload) => ({
    type: types.ORDER_PLACED,
    payload,
});

export const orderPlacedSuccess = (payload) => ({
    type: types.ORDER_PLACED_SUCCESS,
    payload,
});

export const orderPlacedFailure = (payload) => ({
    type: types.ORDER_PLACED_FAILURE,
    payload,
});



// get all order by admin
export const getAllOrder = (payload) => ({
   type: types.GET_ALL_ORDER,
   payload,
});

export const getAllOrderSuccess = (payload) => ({
    type: types.GET_ALL_ORDER_SUCCESS,
    payload,
});

export const getAllOrderFailure = (payload) => ({
    type: types.GET_ALL_ORDER_FAILURE,
    payload,
});

//get order detail by id
export const getOrderById = (payload) => ({
    type: types.GET_ORDER_BY_ID,
    payload,
});

export const getOrderByIdSuccess = (payload) => ({
    type: types.GET_ORDER_BY_ID_SUCCESS,
    payload,
});

export const getOrderByIdFailure = (payload) => ({
    type: types.GET_ORDER_BY_ID_FAILURE,
    payload,
});


// resend new order key for admin
export const resendOrderkey = (payload) => ({
    type: types.RESEND_ORDER_KEY,
    payload,
});
export const resendOrderKeySuccess = (payload) => ({
    type: types.RESEND_ORDER_KEY_SUCCESS,
    payload,
});
export const resendOrderKeyFailure = (payload) => ({
    type: types.RESEND_ORDER_KEY_FAILURE,
    payload,
});


// send new key for admin
export const sendOrderKey = (payload) => ({
    type: types.SEND_ORDER_KEY,
    payload,
});
export const sendOrderKeySuccess = (payload) => ({
    type: types.SEND_ORDER_KEY_SUCCESS,
    payload,
});
export const sendOrderKeyFailure = (payload) => ({
    type: types.SEND_ORDER_KEY_FAILURE,
    payload,
});




