import React, { useEffect } from "react";
import "../style.scss";
import { Container, Row, Col, Button } from "react-bootstrap";
import Mycards from "../../Shop/Mycards";
import { Helmet } from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";

// start redux---
import { getProductsBySubCategorySlug } from "../../../store/SubCategory/action";
import { addToCart } from "../../../store/Cart/action";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Component/Loader/Loader";
import CategoryBanner from "../../../Component/CategoryBanner/CategoryBanner";
import NoDataFound from "../../../Component/NoDataFound/index";
// redux end------------

function ProductSubCategory() {

  let params = useParams();
  let navigate = useNavigate();

  //redux
  const dispatch = useDispatch();
  const { currencyItems, currencySelectByUser } = useSelector((state) => state && state.CartReducer);
  const { isWebSubCategoryListFetching, webProductBySubCategoryList } =
    useSelector((state) => state && state.SubCategoryReducer);


  useEffect(() => {
    dispatch(getProductsBySubCategorySlug(params.subcategoryslug));
  }, [params.subcategoryslug]);
  //redux


  // for sub category wise product component
  const SubCategoryProductData = () => {

    const user_id = localStorage.getItem("userId") ? localStorage.getItem("userId") : "";

    const handleClick = (id) => {
      dispatch(
        addToCart({
          product_id: id,
          qty: 1,
          user_id: user_id,
        })
      );
      navigate(`${process.env.PUBLIC_URL}/checkout`);
    };

  // for courrency change start
  const { gbp } = currencyItems;
  const baseCurrency = gbp / currencySelectByUser?.value
  // for courrency change end


    return (
      <>
        {webProductBySubCategoryList?.products?.map((productByCat) => {
          return (
            <Col sm={3} key={productByCat.id} style={{ textAlign: "center" }}>
                <Mycards
                  cardLinks={
                          `../product/${productByCat.product_slug}`
                        }
                  product_image={productByCat.product_image}
                  product_image_altName={productByCat.product_name}
                  product_name={productByCat.product_name}
                  mrp={
                    ((productByCat.mrp)/(baseCurrency)).toFixed(2)
                  }
                  sale_price={
                    ((productByCat.sale_price)/(baseCurrency)).toFixed(2)
                  }
                  per={
                     `${((((productByCat.mrp)-(productByCat.sale_price))*100) / (productByCat.mrp)).toFixed()}%`
                    }
                  buy_now={
                    // <Link to="/checkout">
                      <Button
                        className="buynow-btn"
                        onClick={() => handleClick(productByCat.id)}
                      >
                        Buy Now
                      </Button>
                    // </Link>
                  }
                />
            </Col>
          );
        })}
      </>
    );
  };
  // for category wise product show end


  return (
    <>
        <Helmet>
          <title>{webProductBySubCategoryList?.seo_title}</title>
          <meta name="description" content={webProductBySubCategoryList?.seo_description} />
        </Helmet>

        <Container fluid className="category_page_topbar">
          {webProductBySubCategoryList?.banner ? 
            <CategoryBanner categoryImage={webProductBySubCategoryList?.banner} categoryImageAlt={webProductBySubCategoryList?.sub_category_name} /> : 
          <div className="sub-heading-product-category">
            <h2>{webProductBySubCategoryList?.sub_category_name}</h2>
          </div>
          }
        </Container>  

      <Container className="gosoftware-padding">
        {/* <Row className="shortPageLine">
          <Col sm={9}>
            <h6 className="mt-2"> Showing all {length} results </h6>
          </Col>
          <Col sm={3}>
            <Form.Select>
              <option value="">Sort by popularity</option>
              <option value="">Sort by latest</option>
              <option value="">Sort by price</option>
            </Form.Select>
          </Col>
        </Row> */}
        <Row>
          {isWebSubCategoryListFetching ? (
            <p style={{ textAlign: "center", margin: "160px 0" }}>
              <Loader />
            </p>
          ) : (
            <>
              {webProductBySubCategoryList?.length === 0 ? 
              <NoDataFound title={`No product found in ${params.subcategoryslug}`} /> :
              <SubCategoryProductData />
              }
            </>
          )}

          <br />
        </Row>
      </Container>
    </>
  );
}

export default ProductSubCategory;
