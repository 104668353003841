import React, {useState, useEffect} from "react";
import "./style.scss";
import {Container, Row, Col, Button} from "react-bootstrap";
import Helmet from "react-helmet";
import {Link} from "react-router-dom";
import CartData from "./Component/CartData";
import {useSelector} from "react-redux";
import LoaderSecond from "../../Component/Loader/LoaderSecond";
import NoDataFound from "../../Component/NoDataFound/index";


function Cart() {

    const [totalAmount, setTotalAmount] = useState();
    const [discountAmount, setDiscountAmount] = useState();

    // redux data
    const {isCartItemsFetching, cartItems, currencyItems, currencySelectByUser} = useSelector(
        (state) => state && state.CartReducer
    );


    const {isSubmitting, checkCouponData} =
        useSelector((state) => state && state.couponReducer);

    // for calculate total Amount
    const {discount_per} = checkCouponData;
    const data = undefined;
    useEffect(() => {
        if (discount_per === data) {
            setTotalAmount(cartItems?.total?.toFixed(2));
        } else {
            setTotalAmount((cartItems?.total - ((cartItems?.total * discount_per) / 100)).toFixed(2));
            setDiscountAmount(((cartItems?.total) - (cartItems?.total - ((cartItems?.total * discount_per) / 100))).toFixed(2));
        }
    }, [isSubmitting, isCartItemsFetching, data, discount_per]);


    // for courrency change start
    const {gbp} = currencyItems;
    const baseCurrency = gbp / currencySelectByUser?.value
    // for courrency change end


    return (
        <>
            <Helmet>
                <title>Cart - Gosoftware</title>
            </Helmet>

            {/* No products in the cart.</h2>  */}
            {cartItems?.carts?.length === 0 ?
             <NoDataFound title="No products in the cart." /> :
            <Container className="gosoftware-padding">
                <div className="cartTotal">
                    <Row>
                        <Col sm={12}>
                            <CartData/>
                        </Col>
                    </Row>
                </div>

                <Row>
                    <Col sm={7}></Col>
                    <Col sm={5}>
                        <div className="mt-4 cartTotal">
                            <Row>
                                <Col>
                                    <h4>Cart total</h4>
                                    <div>
                                        <div className="mt-4 checkout-subTotal">
                                            <Row>
                                                <Col sm={3}>
                                                    <h6>Subtotal</h6>
                                                </Col>
                                                <Col sm={9}>
                                                    {isCartItemsFetching ? <LoaderSecond/> : (
                                                        <h6>
                                                            {currencySelectByUser?.name}
                                                            {((cartItems?.total) / (baseCurrency)).toFixed(2)}
                                                        </h6>
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>

                                        {discountAmount && (
                                            <div className="checkout-subTotal">
                                                <Row>
                                                    <Col sm={3}>
                                                        <h6>Discount</h6>
                                                    </Col>
                                                    <Col sm={9}>
                                                        <h6>-{currencySelectByUser?.name}
                                                            {((discountAmount) / (baseCurrency)).toFixed(2)}</h6>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}

                                        <div className="checkout-subTotal">
                                            <Row>
                                                <Col sm={3}>
                                                    <h6>Total</h6>
                                                </Col>
                                                <Col sm={9}>
                                                    {isCartItemsFetching ? <LoaderSecond/> : (
                                                        <h6>
                                                            {currencySelectByUser?.name}
                                                            {((totalAmount) / (baseCurrency)).toFixed(2)}
                                                        </h6>
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <Link to="/checkout">
                                            <Button className="cart-page-checkout-btn">
                                                Proceed to checkout
                                            </Button>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
            }
        </>
    );
}

export default Cart;
