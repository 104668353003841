import * as types from "./constant";

// get all Coupon
export const getAllCoupon = (payload) => ({
    type: types.GET_ALL_COUPON,
    payload,
});
export const getAllCouponSuccess = (payload) => ({
    type: types.GET_ALL_COUPON_SUCCESS,
    payload,
});
export const getAllCouponFailure = (payload) => ({
    type: types.GET_ALL_COUPON_FAILURE,
    payload,
});


// add coupon 
export const addCoupon = (payload) => ({
    type: types.ADD_COUPON,
    payload,
});
export const addCouponSuccess = (payload) => ({
    type: types.ADD_COUPON_SUCCESS,
    payload,
});
export const addCouponFailure = (payload) => ({
    type: types.ADD_COUPON_FAILURE,
    payload,
});

//update coupon 
export const updateCoupon = (payload) => ({
    type: types.UPDATE_COUPON,
    payload,
});
export const updateCouponSuccess = (payload) => ({
    type: types.UPDATE_COUPON_SUCCESS,
    payload,
});
export const updateCouponFailure = (payload) => ({
    type: types.UPDATE_COUPON_FAILURE,
    payload,
});


//get coupon by id
export const getCouponById = (payload) => ({
    type: types.GET_COUPON_BY_ID,
    payload,
});
export const getCouponByIdSuccess = (payload) => ({
    type: types.GET_COUPON_BY_ID_SUCCESS,
    payload,
});
export const getCouponByIdFailure = (payload) => ({
    type: types.GET_COUPON_BY_ID_SUCCESS,
    payload,
});


// delete coupon 
export const deleteCoupon = (payload) => ({
    type: types.DELETE_COUPON,
    payload,
});
export const deleteCouponSuccess = (payload) => ({
    type: types.DELETE_COUPON_SUCCESS,
    payload,
});
export const deleteCouponFailure = (payload) => ({
    type: types.DELETE_COUPON_FAILURE,
    payload,
}); 


// for web check coupon code
export const checkCouponCode = (payload) => ({
    type: types.CHECK_COUPON_CODE,
    payload,
});
export const checkCouponCodeSuccess = (payload) => ({
    type: types.CHECK_COUPON_CODE_SUCCESS,
    payload,
});
export const checkCouponCodeFailure = (payload) => ({
    type: types.CHECK_COUPON_CODE_FAILURE,
    payload,
});
