import React, { useEffect, useState } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Popconfirm,
  Table,
  Image,
  Space,
  Select,
} from "antd";
// const { Link } = Anchor;
import "antd/dist/antd.css";
import Loader from "../../../../Component/Loader/Loader";
import NoDataFound from "../../../../Component/NoDataFound/index";

//----------------- start redux data
import { getCategoryList } from "../../../../store/Category/action";
import { getProductList, deleteProduct } from "../../../../store/Product/action";
import { useDispatch, useSelector, connect } from "react-redux";
import { compose } from "redux";
import { Helmet } from "react-helmet";
//----------------- start redux end

function ManageProduct({getAllProductData}) {

  const [search, setSearch] = useState();
  const [categoryId, setCategoryId] = useState();
  const [loading, setLoading] = useState(true);

  
  //--------redux data start
  const dispatch = useDispatch();
  const { isProductListFetching, productList, isSubmitting } =
  useSelector((state) => state && state.ProductReducer);
  

  const { categoryList } = useSelector(
    (state) => state && state.CategoryReducer);

  //--------redux data end

  useEffect(() => {
    dispatch(getCategoryList());
    getAllProductData({
      category: categoryId ? categoryId : "",
      search: search ? search : "",
    });
    setLoading(false);
  }, [isSubmitting, search, categoryId]);

 
  const handleDelete = (id) => {
    dispatch(deleteProduct(id));
  }

  // extra data
  const data = productList.map((row) => ({
    key: row.id,
    Image: row.product_image,
    Name: row.product_name,
    MRP: row.mrp,
    SalePrice: row.sale_price,
    Category: row.category.category_name,
    id: row.id,
  }));

  // extra data

  const columns = [
    {
      title: "Product",
      colSpan: 2,
      dataIndex: "Image",
      render: (product_image) => <Image src={product_image} width="50px" alt="gosoftware product" />,
      align: "center",
    },
    {
      title: "Name",
      colSpan: 0,
      dataIndex: "Name",
      render: (_, record) => (
        <Link to={"/admin/edit-product/" + record.id} style={{color: "#000"}} >{record.Name}</Link>
      ),
      width: "30%",
      align: "left",
    },
    {
      title: "MRP",
      dataIndex: "MRP",
      render: (MRP) => (
        <span>
          GBP£{MRP}
        </span>
      ),
      align: "center",
    },
    {
      title: "Sale Price",
      dataIndex: "SalePrice",
      render: (SalePrice) => (
        <span>
          GBP£{SalePrice}
        </span>
      ),
      align: "center",
    },
    {
      title: "Category",
      dataIndex: "Category",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (_, record) => (
        <Space>
        <Link to={"/admin/edit-product/" + record.id}>
          <Button
            type="primary"
            title="Edit"
            style={{ padding: "4px 8px" }}
          >
            {" "}
            <strong>
              {" "}
              <EditOutlined
                style={{
                  fontWeight: "900",
                  fontSize: "16px",
                  verticalAlign: "middle",
                }}
              />{" "}
            </strong>{" "}
          </Button>
          </Link>

          <Popconfirm
            title="Are you sure want to delete ?"
            onConfirm={() => handleDelete(record.id)}
          >
            <Button
              danger
              type="primary"
              title="Delete"
              style={{ padding: "4px 8px" }}
            >
              {" "}
              <strong>
                {" "}
                <DeleteOutlined
                  style={{
                    fontWeight: "900",
                    fontSize: "16px",
                    verticalAlign: "middle",
                  }}
                />{" "}
              </strong>{" "}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  //---------------------------------------------------------

  const handleSearch = (e) => {
    // console.log(e.target.value);
    setSearch(e.target.value);
  };


  return (
    <>
      <Helmet>
        <title>Gosoftware - Product's</title>
      </Helmet>

      <div className="admin-product-page container-fluid">
        <div className="admin-product-page-data">
          <div className="admin-product-page-title row">
            <div className="col-sm-2 col-xs-12">
              <h4>Product's</h4>
            </div>
            <div className="col-sm-4 col-xs-12">
              <Space>
                <Select 
                  allowClear 
                  placeholder="Select a category"
                  type="text"
                  style={{
                    width: 200,
                  }}
                  value={categoryId}
                  onChange={(value) => setCategoryId(value)}
                >
                  {categoryList.map((cateItem) => {
                    return (
                      <option value={cateItem.id} key={cateItem.id}>
                        {cateItem.category_name}
                      </option>
                    );
                  })}
                </Select>
                {/* <Button onClick={categorySearch} type="primary">
                  Search
                </Button> */}
              </Space>
            </div>

            <div className="col-sm-3 col-xs-12">
              <Space>
                <Input
                  placeholder="Enter search text"
                  onChange={handleSearch}
                  type="text"
                  allowClear
                  value={search}
                />
                {/* <Button onClick={globalSearch} type="primary">Search</Button> */}
                {/* <Button onClick={reset}>Reset</Button> */}
              </Space>
            </div>

            <div className="col-sm-3 col-xs-12">
              <Link to="/admin/add-product" className="admin-page-button">
                {" "}
                <PlusOutlined /> Add Product
              </Link>
            </div>
          </div>

          <div className="admin-product">
            <div>
              {isProductListFetching ? (
                <p style={{ textAlign: "center", margin: "160px 0" }}>
                  <Loader />
                </p>
              ) : (
                <>
                {productList?.length === 0 ? 
                <NoDataFound title="No data found in product" /> : 
                <Table
                  // bordered
                  className="ant-table-admin"
                  dataSource={data}
                  columns={columns}
                  loading={loading}
                />
                }
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// export default ManageProduct;

function mapDispatchToProps(dispatch) {
  return {
    getAllProductData: (data) => dispatch(getProductList(data)),
  };
}

const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(ManageProduct);
