import React, { useState, useEffect } from "react";
import "../style.scss";
import { Link, useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";

//----------------- start redux data
import { getProductList } from "../../../../../store/Product/action";
import { addProductKey } from "../../../../../store/ProductKey/action";
import { useDispatch, useSelector, connect } from "react-redux";
import { compose } from "redux";
import { Helmet } from "react-helmet";
//----------------- start redux end

function AddProductKeys({ saveProductKey }) {
  
  const navigate = useNavigate();
  const [visible, setIsVisible] = useState(false);
  //--------redux data start
  const dispatch = useDispatch();
  const { productList } = useSelector((state) => state && state.ProductReducer);

  const { isSubmitting, errorMessageProductKey } = useSelector(
    (state) => state && state.ProductKeyReducer
  );

  //--------redux data end
  useEffect(() => {
    dispatch(
      getProductList({
        category: "",
        search: "",
      })
    );
  }, []);

  const validationSchema = yup.object().shape({
    product_id: yup.string().required("Product name is required."),
    code: yup.string().required("Product key is required"),
  });

  // onSubmit form data
  const onSubmit = () => {
    // console.log("values", values);
    const formData = new FormData();
    formData.append("product_id", values.product_id);
    formData.append("code", values.code);

    saveProductKey(formData);

    // for error message start
      setIsVisible(true);
    // for error message end
    setTimeout(function () {
      setIsVisible(false);
    }, 8000);
  };

  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: {
      product_id: "",
      code: "",
    },
    validationSchema,
    onSubmit,
  });

  return (
    <>
      <Helmet>
        <title>Gosoftware - Add Product Key</title>
      </Helmet>

      <div className="admin-product-key-page">
        <div className="admin-product-key-page-data">
          <div className="admin-product-key-page-title">
            <h4>Add Product Key</h4>
            <Link to="/admin/product-key" className="admin-page-button">
              {" "}
              <LeftOutlined /> Back{" "}
            </Link>
          </div>

          <div className="admin-product-key" style={{ padding: "1rem 2.2rem" }}>
            <div>
              {isSubmitting ? (
                ""
              ) : (
                <>
                  {visible && (
                    <>
                      {errorMessageProductKey === "Success" ? (
                        navigate("/admin/product-key")
                      ) : (
                        <p className="error-message">
                          *{errorMessageProductKey}
                        </p>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            <Form 
              noValidate 
              onSubmit={handleSubmit}
              className="ant-table-admin"
              style={{ padding: "2.2rem" }}
              >
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Product Name<span className="text-danger">*</span></Form.Label>
                  <Form.Select
                    type="text"
                    name="product_id"
                    value={values.product_id}
                    onChange={handleChange}
                    isInvalid={!!errors.product_id && touched.product_id}
                  >
                    <option>Select product name</option>
                    {productList.map((option) => (
                      <option value={option.id} key={option.id}>
                        {option.product_name}
                      </option>
                    ))}
                  </Form.Select>

                  <Form.Control.Feedback type="invalid">
                    {errors.product_id}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Product Key<span className="text-danger">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    //  as="textarea"
                    name="code"
                    value={values.code}
                    onChange={handleChange}
                    isInvalid={!!errors.code && touched.code}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.code}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <div>
                <Button
                  type="submit"
                  className="subFormButton"
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <Spinner animation="border" size="sm" as="span" />
                  )}
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

// export default AddProductKeys;

function mapDispatchToProps(dispatch) {
  return {
    saveProductKey: (data) => dispatch(addProductKey(data)),
  };
}

const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(AddProductKeys);
