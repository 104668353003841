import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Mycards from "../Shop/Mycards";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

// redux data-----------
import { getProductBySearch } from "../../store/Product/action";
import { addToCart } from "../../store/Cart/action";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Component/Loader/Loader";
import NoDataFound from "../../Component/NoDataFound/index";
//redux data----------


function Search() {

  const params = useParams();
  let navigate = useNavigate();
  //redux data-------
  const dispatch = useDispatch();
  const { currencyItems, currencySelectByUser } = useSelector((state) => state && state.CartReducer);
  const { isWebProductListFetching, productSearchData } = useSelector(
    (state) => state && state.WebProductReducer
  );
  //redux data end---------

  useEffect(() => {
    dispatch(getProductBySearch(params.slug));
  }, [params.slug]);

  const DataSearch = () => {
    // for send add to cart
    const user_id = localStorage.getItem("userId") ? localStorage.getItem("userId") : "";

    const handleClick = (id) => {
      dispatch(
        addToCart({
          product_id: id,
          qty: 1,
          user_id: user_id,
        })
      );
      navigate(`${process.env.PUBLIC_URL}/checkout`);
    };

     // for courrency change start
      const { gbp } = currencyItems;
      const baseCurrency = gbp / currencySelectByUser?.value
      // for courrency change end

    return (
      <>
        {productSearchData?.length === 0 ? (
          <>
          <NoDataFound title="No product found. Sorry, but nothing matched your search terms. Please try again with
            some different keywords." />
          </>
        ) : (
          ""
        )}
        {productSearchData?.map((proSearch) => {
          return (
            <Col sm={3} key={proSearch.id} style={{ textAlign: "center" }}>
                <Mycards
                  cardLinks={
                          `${process.env.PUBLIC_URL}/product/${proSearch.product_slug}`
                        }
                  product_image={proSearch.product_image}
                  product_image_altName={proSearch.product_name}
                  product_name={proSearch.product_name}
                  mrp={
                    ((proSearch.mrp)/(baseCurrency)).toFixed(2)
                  }
                  sale_price={
                    ((proSearch.sale_price)/(baseCurrency)).toFixed(2)
                  }
                  per={
                     `${((((proSearch.mrp)-(proSearch.sale_price))*100) / (proSearch.mrp)).toFixed()}%`
                     }
                  buy_now={
                    // <Link to="/checkout">
                      <Button
                        className="buynow-btn"
                        onClick={() => handleClick(proSearch.id)}
                      >
                        Buy Now
                      </Button>
                    // </Link>
                  }
                />
            </Col>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>You searched for - Gosoftware</title>
      </Helmet>

      <Container className="gosoftware-padding">
        <Row>
          {isWebProductListFetching ? (
            <p style={{ textAlign: "center", margin: "160px 0" }}>
              {" "}
              <Loader />{" "}
            </p>
          ) : (
            <DataSearch />
          )}
        </Row>
      </Container>
    </>
  );
}

export default Search;
