import React from 'react';
import "./loader.scss";

function LoaderSecond() {
  return (
    <>
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
}

export default LoaderSecond;
