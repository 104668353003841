import * as types from "./constant";

export const initialState = {


  // Product list
  isProductListFetching: false,
  productList: [],
  editProduct: [],
  bestSellerProduct: [],
  productListErrr: null,
  
  // Add/Edit
  isSubmitting: false,
  productSubmitErrr: null,
  errrMessageUdpdateProduct: null,
};

const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PRODUCT_LIST:
      return {
        ...state,
        isProductListFetching: true,
        productListErrr: null,
      };
    case types.GET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        isProductListFetching: false,
        productList: action.payload,
        productListErrr: null,
      };
    case types.GET_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        isProductListFetching: false,
        productListErrr: action.payload,
      };

    case types.ADD_PRODUCT:
      return {
        ...state,
        isSubmitting: true,
        productSubmitErrr: null,
      };
    case types.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        productSubmitErrr: action.payload.message,
      }
    case types.ADD_PRODUCT_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        productSubmitErrr: action.payload.error,
      };  

    case types.GET_EDIT_PRODUCT:
      return {
        ...state,
        isProductListFetching: true,
        productListErrr: null,
        isEditProductModalOpen: false,
      };
    case types.GET_EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        isProductListFetching: false,
        editProduct: action.payload,
        productListErrr: null,
      };
    case types.GET_EDIT_PRODUCT_FAILURE:
      return {
        ...state,
        isProductListFetching: false,
        productListErrr: action.payload,
      };

    case types.UPDATE_PRODUCT:
      return {
        ...state,
        isSubmitting: true,
        errrMessageUdpdateProduct: null,
      };
    case types.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        errrMessageUdpdateProduct: "Product has been updated successfully.",
      };
    case types.UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        errrMessageUdpdateProduct: action.payload.error,
      };

    case types.DELETE_PRODUCT:
      return {
          ...state,
          isSubmitting: true,
        };
    case types.DELETE_PRODUCT_SUCCESS:
      return {
          ...state,
          isSubmitting: false,
        };
    case types.DELETE_PRODUCT_FAILURE:
      return {
          ...state,
          isSubmitting: false,
        };  

    default:
      return state;
  }
};

export default ProductReducer;
