import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  updateBasicDetail,
} from "../../../../../store/Auth/action";
import { useDispatch, useSelector } from "react-redux";

function PersonalDetail() {

  const [visible, setIsVisible] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const { isSubmitting, isUpdateBasicDetailsData, errorMessageBasicDetail } = useSelector(
    (state) => state && state.AuthReducer
  );


    const user = JSON.parse(localStorage.getItem("user"))
      ? JSON.parse(localStorage.getItem("user"))
      : "";

    useEffect(()=>{
      setName(isUpdateBasicDetailsData?.name ? isUpdateBasicDetailsData?.name : user?.name);
      setEmail(isUpdateBasicDetailsData?.email ? isUpdateBasicDetailsData?.email : user?.email);
    },[isSubmitting]);

  const onSubmit = () => {
    // console.log("values", values);
    let formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);

    dispatch(updateBasicDetail(formData));

    // for error message start
      setIsVisible(true);
    // for error message end
    setTimeout(function() {
      setIsVisible(false);
    }, 8000);
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is a required"),
    email: yup.string().required("Email is a required"),
  });

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      name: name ? name : "",
      email: email ? email : "",
    },
    validateOnBlur: true,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <>

      <Form noValidate onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>
              Name<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              // value={values.name}
              defaultValue={name}
              onChange={handleChange}
              isInvalid={!!errors.name && touched.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>
              Email<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              name="email"
              // value={values.email}
              defaultValue={email}
              onChange={handleChange}
              isInvalid={!!errors.email && touched.email}
            />

            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Button type="submit" className="mt-2" disabled={isSubmitting}>
          {isSubmitting && <Spinner animation="border" size="sm" as="span" />}
          Update profile
        </Button>
        <div>
        {isSubmitting ? "" :
              <>
              {visible && (
                  <>
                    {errorMessageBasicDetail === "Personal details has been changed successfully." ?
                        <p className="error-message-success">*{errorMessageBasicDetail}</p> :
                        <p className="error-message">*{errorMessageBasicDetail}</p>
                    }
                  </>
              )}
              </>
            }
        </div>
      </Form>
    </>
  );
}

export default PersonalDetail;
