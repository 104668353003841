import React, { useState, useRef, useEffect } from "react";
import "../style.scss";
import { useParams, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { LeftOutlined } from "@ant-design/icons";
import PreviewProductImage from "../Component/PreviewProductImage";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loader from "../../../../../Component/Loader/Loader";

// start redux data
import { getCategoryList } from "../../../../../store/Category/action";
import { getSubCategoryByCategoryId } from "../../../../../store/SubCategory/action";
import {
  getEditProduct,
  updateProduct,
} from "../../../../../store/Product/action";
import { useDispatch, useSelector, connect } from "react-redux";
import { compose } from "redux";
import { Helmet } from "react-helmet";
// end redux data

function EditProduct({ updateSaveProduct }) {
  const params = useParams();
  const fileRef = useRef(null);
  const [visible, setIsVisible] = useState(false);

  const validationSchema = yup.object().shape({
    product_name: yup.string().required("Product name is required."),
    category_id: yup.string().required("Product category is required."),
    sub_category_id: yup.string().required("Sub category is required."),
    product_status: yup.string().required("Product status is required"),
    mrp: yup.string().required("MRP is required."),
    sale_price: yup.string().required("Sale price is required."),
    short_desc: yup.string().required("short description is required."),
    desc: yup.string().required("description is required."),
    product_image: yup.mixed(),
    // gallery: yup.mixed(),
    product_slug: yup.string().required("Product slug is required."),
    download_link: yup.string(),
    seo_title: yup.string().required("SEO title is required."),
    seo_description: yup.string().required("SEO description is required."),
  });

  // redux data
  const dispatch = useDispatch();
  const {
    isProductListFetching,
    editProduct,
    isSubmitting,
    errrMessageUdpdateProduct,
  } = useSelector((state) => state && state.ProductReducer);

  const { categoryList } = useSelector(
    (state) => state && state.CategoryReducer
  );
  const { webSubCategoryList } = useSelector(
    (state) => state && state.SubCategoryReducer
  );

  const {
    product_name,
    category_id,
    product_status,
    mrp,
    sale_price,
    best_seller,
    short_desc,
    desc,
    product_image,
    download_link,
    seo_title,
    seo_description,
    sub_category_id,
    product_slug
  } = editProduct;


  useEffect(() => {
    dispatch(getEditProduct(params.id));
    dispatch(getCategoryList());
  },[params.id]);
  
  useEffect(() => {
    dispatch(getSubCategoryByCategoryId(category_id));
  },[category_id]);
  // redux data end


  const onSubmit = () => {
    // console.log("edit form values", values);
    const formData = new FormData();
    // if (values.product_gallery) {
    //   formData.append("product_gallery", values.product_gallery);
    // }
    if (values.product_image) {
      formData.append("product_image", values.product_image);
    }
    formData.append("product_name", values.product_name);
    formData.append("category_id", values.category_id);
    formData.append("sub_category_id", values.sub_category_id);
    formData.append("product_status", values.product_status);
    formData.append("best_seller", values.best_seller);
    formData.append("mrp", values.mrp);
    formData.append("sale_price", values.sale_price);
    formData.append("short_desc", values.short_desc);
    formData.append("desc", values.desc);
    formData.append("download_link", values.download_link);
    formData.append("product_slug", values.product_slug);
    formData.append("seo_title", values.seo_title);
    formData.append("seo_description", values.seo_description);

    updateSaveProduct({
      id: params.id,
      formData: formData,
    });

    // for error message start
      setIsVisible(true);
    // for error message end
    setTimeout(function () {
      setIsVisible(false);
    }, 8000);
  };

  const { handleSubmit, handleChange, values, setFieldValue, touched, errors } =
    useFormik({
      initialValues: {
        product_name: product_name ? product_name : "",
        category_id: category_id ? category_id : "",
        sub_category_id: sub_category_id ? sub_category_id : "",
        product_status: product_status ? product_status : "",
        best_seller: best_seller ? best_seller : "",
        mrp: mrp ? mrp : "",
        sale_price: sale_price ? sale_price : "",
        short_desc: short_desc ? short_desc : "",
        desc: desc ? desc : "",
        product_image: null,
        // product_gallery: null,
        download_link: download_link ? download_link : "",
        product_slug: product_slug ? product_slug : "",
        seo_title: seo_title ? seo_title : "",
        seo_description: seo_description ? seo_description : "",
      },
      validateOnBlur: true,
      validationSchema,
      onSubmit,
      enableReinitialize: true,
    });

  // product status data
  const ProductStatusData = [{ data: "Active" }, { data: "Inactive" }];
  // product status data
  // for best seller
  const bestSellerData = [{ data: "Yes" }, { data: "No" }];
  // for best seller

  return (
    <>
      <Helmet>
        <title>Gosoftware - Edit Product</title>
      </Helmet>

      <div className="admin-product-page">
        <div className="admin-product-page-data">
          <div className="admin-product-page-title">
            <h4>Edit Product</h4>
            <Link to="/admin/product" className="admin-page-button">
              {" "}
              <LeftOutlined /> Back
            </Link>
          </div>

          <div className="admin-product" style={{ padding: "1rem 2.2rem" }}>
            {isProductListFetching ? (
              <p style={{ textAlign: "center", margin: "160px 0" }}>
                <Loader />
              </p>
            ) : (
              <>
              <div>
              {isSubmitting ? (
                ""
              ) : (
                <>
                  {visible && (
                    <>
                      {errrMessageUdpdateProduct ===
                      "Product has been updated successfully." ? (
                        <p className="error-message-success">
                          *{errrMessageUdpdateProduct}
                        </p>
                      ) : (
                        <p className="error-message">
                          *{errrMessageUdpdateProduct}
                        </p>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
              <Form
                noValidate
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                className="ant-table-admin"
                style={{ padding: "2.2rem" }}
              >
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>
                      Product Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="product_name"
                      // value={editProduct.product_name}
                      defaultValue={product_name}
                      onChange={handleChange}
                      isInvalid={touched.product_name && errors.product_name}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.product_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>
                      Product Category<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      type="text"
                      name="category_id"
                      // value={editProduct.category_id}
                      defaultValue={category_id}
                      onChange={(e) => {
                        setFieldValue("category_id", e.target.value);
                        dispatch(getSubCategoryByCategoryId(e.target.value));
                      }}
                      isInvalid={touched.category_id && errors.category_id}
                    >
                      <option>Select Category</option>
                      {categoryList.map((option) => {
                        return (
                          <option
                            value={option.id}
                            key={option.id}
                            selected={
                              option.id === editProduct.category_id
                                ? "selected"
                                : ""
                            }
                          >
                            {option.category_name}
                          </option>
                        );
                      })}
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                      {errors.category_id}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>
                      Sub Category<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      type="text"
                      name="sub_category_id"
                      // value={editProduct.sub_category_id}
                      defaultValue={sub_category_id}
                      onChange={handleChange}
                      isInvalid={
                        touched.sub_category_id && errors.sub_category_id
                      }
                    >
                      <option>Select sub Category</option>
                      {webSubCategoryList.map((option) => {
                        return (
                          <option
                            value={option.id}
                            key={option.id}
                            selected={
                              option.id === editProduct.sub_category_id
                                ? "selected"
                                : ""
                            }
                          >
                            {option.sub_category_name}
                          </option>
                        );
                      })}
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                      {errors.sub_category_id}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>
                      Product Status<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      type="text"
                      name="product_status"
                      // value={values.product_status}
                      defaultValue={product_status}
                      onChange={handleChange}
                      isInvalid={
                        touched.product_status && errors.product_status
                      }
                    >
                      <option>Select Status</option>
                      {ProductStatusData.map((items) => {
                        return (
                          <option
                            value={items.data}
                            key={items.data}
                            selected={
                              items.data === editProduct.product_status
                                ? "selected"
                                : ""
                            }
                          >
                            {items.data}
                          </option>
                        );
                      })}
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                      {errors.product_status}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>Best Seller</Form.Label>
                    <Form.Select
                      type="text"
                      name="best_seller"
                      // value={values.best_seller}
                      defaultValue={best_seller}
                      onChange={handleChange}
                      isInvalid={touched.best_seller && errors.best_seller}
                    >
                      {bestSellerData.map((items) => {
                        return (
                          <option
                            value={items.data}
                            key={items.data}
                            selected={
                              items.data === editProduct.best_seller
                                ? "selected"
                                : ""
                            }
                          >
                            {items.data}
                          </option>
                        );
                      })}
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                      {errors.best_seller}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>
                      MRP<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      className="Form-control"
                      type="number"
                      name="mrp"
                      step={0.01}
                      precision={2}
                      // value={values.mrp}
                      defaultValue={mrp}
                      onChange={handleChange}
                      isInvalid={touched.mrp && errors.mrp}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.mrp}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>
                      Sale Price<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="sale_price"
                      step={0.01}
                      precision={2}
                      // value={values.sale_price}
                      defaultValue={sale_price}
                      onChange={handleChange}
                      isInvalid={touched.sale_price && errors.sale_price}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.sale_price}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="short_desc">
                    <Form.Label>
                      Short Description<span className="text-danger">*</span>
                    </Form.Label>
                    <div className="editor">
                      <CKEditor
                        editor={ClassicEditor}
                        id="inputText"
                        name="short_desc"
                        data={short_desc}
                        // onChange={handleCKeditor}
                        onChange={(event, editor) => {
                          // setFieldValue("short_desc", editor.setData("<h3>Hello</h3>"));
                          setFieldValue("short_desc", editor.getData());
                        }}
                      />
                    </div>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>
                      Description<span className="text-danger">*</span>
                    </Form.Label>
                    <div className="editor">
                      <CKEditor
                        editor={ClassicEditor}
                        id="inputText"
                        name="desc"
                        data={desc}
                        // onChange={handleCKeditor}
                        onChange={(event, editor) => {
                          setFieldValue("desc", editor.getData());
                        }}
                      />
                    </div>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Col>
                    <Form.Group>
                      {/* <Form.Label>Product Image</Form.Label> */}
                      <Form.Label htmlFor="product_image_id">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          onClick={() => fileRef.current.click()}
                          className="product-image-button"
                        >
                          Upload image
                        </Button>
                      </Form.Label>

                      <Form.Control
                        ref={fileRef}
                        id="product_image_id"
                        hidden
                        type="file"
                        // required
                        name="product_image"
                        accept="image/*"
                        onChange={(event) => {
                          setFieldValue("product_image", event.target.files[0]);
                        }}
                        isInvalid={!!errors.product_image}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.product_image}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <div>
                      <img src={product_image} width="120" alt="Gosoftware product" />
                      {values.product_image && (
                        <PreviewProductImage file={values.product_image} />
                      )}
                    </div>
                  </Col>
                </Row>

                {/* <Row>
                  <div>
                    <ImageGallery setFieldValue={setFieldValue} />
                  </div>
                </Row> */}

                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Product slug<span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      name="product_slug"
                      // value={values.product_slug}
                      defaultValue={product_slug}
                      onChange={handleChange}
                      isInvalid={
                        !!errors.product_slug && touched.product_slug
                      }
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.product_slug}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Download link</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={1}
                      name="download_link"
                      // value={values.download_link}
                      defaultValue={download_link}
                      onChange={handleChange}
                      isInvalid={
                        !!errors.download_link && touched.download_link
                      }
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.download_link}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <h4>SEO</h4>
                  <Form.Group as={Col}>
                    <Form.Label>
                      Title<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="seo_title"
                      // value={values.seo_title}
                      defaultValue={seo_title}
                      onChange={handleChange}
                      isInvalid={!!errors.seo_title && touched.seo_title}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.seo_title}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>
                      Meta description<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="seo_description"
                      rows={3}
                      // value={values.seo_description}
                      defaultValue={seo_description}
                      onChange={handleChange}
                      isInvalid={
                        !!errors.seo_description && touched.seo_description
                      }
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.seo_description}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <div>
                  <Button
                    type="submit"
                    className="subFormButton"
                    size="lg"
                    disabled={isSubmitting}
                  >
                    {isSubmitting && (
                      <Spinner animation="border" size="sm" as="span" />
                    )}
                    Submit
                  </Button>
                </div>
              </Form>
            </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

// export default EditProduct;

function mapDispatchToProps(dispatch) {
  return {
    updateSaveProduct: (data) => dispatch(updateProduct(data)),
  };
}

const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(EditProduct);
