import React, { useRef, useState } from "react";
import "../style.scss";
import { Link, useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import PreviewImage from "../Component/PreviewImage";

// start redux
import { addCategory } from "../../../../../store/Category/action";
import { useSelector, connect } from "react-redux";
import { compose } from "redux";
import { Helmet } from "react-helmet";
// end redux data

function AddCategory({ saveCategory }) {
  
  const fileRef = useRef(null);
  const navigate = useNavigate();
  const [visible, setIsVisible] = useState(false);

  const validationSchema = yup.object().shape({
    category_name: yup.string().required("Category name is required."),
    order_no: yup.string().required("Order number is required."),
    image: yup.mixed(),
    seo_title: yup.string().required("SEO title is required"),
    seo_description: yup.string().required("SEO description is required"),
  });

  // redux data
  const { isSubmitting, categorySubmitingErrr } = useSelector(
    (state) => state && state.CategoryReducer
  );
  //redux data start

  const onSubmit = () => {
    // console.log("category data values", values);
    const formData = new FormData();
    if (values.image) {
      formData.append("image", values.image);
    }
    formData.append("category_name", values.category_name);
    formData.append("order_no", values.order_no);
    formData.append("seo_title", values.seo_title);
    formData.append("seo_description", values.seo_description);

    saveCategory(formData);

    // for error message start
      setIsVisible(true);
    // for error message end
    setTimeout(function () {
      setIsVisible(false);
    }, 8000);
  };

  const { handleSubmit, handleChange, values, setFieldValue, touched, errors } =
    useFormik({
      initialValues: {
        category_name: "",
        order_no: "",
        image: null,
        seo_title: "",
        seo_description: "",
      },
      validationSchema,
      onSubmit,
    });

  return (
    <>
      <Helmet>
        <title>Gosoftware - Add Category</title>
      </Helmet>

      <div className="admin-category-page">
        <div className="admin-category-page-data">
          <div className="admin-category-page-title">
            <h4>Add New Category</h4>
            <Link to="/admin/category" className="admin-page-button">
              {" "}
              <LeftOutlined /> Back
            </Link>
          </div>

          <div className="admin-category" style={{ padding: "1rem 2.2rem" }}>
            <div>
              {isSubmitting ? (
                ""
              ) : (
                <>
                  {visible && (
                    <>
                      {categorySubmitingErrr === "Success" ? (
                        navigate("/admin/category")
                      ) : (
                        <p className="error-message">
                          *{categorySubmitingErrr}
                        </p>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            <Form
              noValidate
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              className="ant-table-admin"
              style={{ padding: "2.2rem" }}
            >
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>
                    Category name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="category_name"
                    value={values.category_name}
                    onChange={handleChange}
                    isInvalid={!!errors.category_name && touched.category_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.category_name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>
                    Order No.<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="order_no"
                    value={values.order_no}
                    onChange={handleChange}
                    isInvalid={!!errors.order_no && touched.order_no}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.order_no}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Form.Group>
                    {/* <Form.Label>Category Image<span className='text-danger'>*</span></Form.Label> */}
                    <Form.Label htmlFor="category_image_id">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        onClick={() => fileRef.current.click()}
                        className="category-image-button"
                      >
                        Upload banner
                      </Button>
                    </Form.Label>

                    <Form.Control
                      ref={fileRef}
                      id="category_image_id"
                      hidden
                      type="file"
                      name="image"
                      accept="image/*"
                      onChange={(event) => {
                        setFieldValue("image", event.target.files[0]);
                      }}
                      isInvalid={!!errors.image && touched.image}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.image}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col>
                  <div>
                    {values.image && <PreviewImage file={values.image} />}
                  </div>
                </Col>
              </Row>

              <Row className="mb-3">
                <h5>SEO</h5>
                <Form.Group as={Col}>
                  <Form.Label>
                    Title<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="seo_title"
                    value={values.seo_title}
                    onChange={handleChange}
                    isInvalid={!!errors.seo_title && touched.seo_title}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.seo_title}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>
                    Meta description<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="seo_description"
                    value={values.seo_description}
                    onChange={handleChange}
                    isInvalid={
                      !!errors.seo_description && touched.seo_description
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.seo_description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <div>
                <Button
                  type="submit"
                  className="subFormButton"
                  size="lg"
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <Spinner animation="border" size="sm" as="span" />
                  )}
                  Submit{" "}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

// export default AddCategory;

function mapDispatchToProps(dispatch) {
  return {
    saveCategory: (data) => dispatch(addCategory(data)),
  };
}

const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(AddCategory);
