import React from 'react';
import Mycards from "../../Shop/Mycards";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useSelector } from "react-redux";

const responsive = {
  0: { items: 1 },
  568: { items: 3 },
  1024: { items: 4 },
};
 
function RelatedProducts({relevant_products}){

  const { currencyItems, currencySelectByUser } = useSelector((state) => state && state.CartReducer);
   
  // for courrency change start
  const { gbp } = currencyItems;
  const baseCurrency = gbp / currencySelectByUser?.value
// for courrency change end

  return (
    <div>
      <h2 style={{textAlign: "center", margin: "2rem 0", color: "#0d6efd" }}>Related Products</h2>
      <AliceCarousel
            mouseTracking
            // items={}
            responsive={responsive}
            controlsStrategy="alternate"
          >
        {relevant_products?.map((relProducts) => {
          return (
            <div key={relProducts.id} style={{ textAlign: "center" }}>
                <Mycards
                  style={{ width: "280px"}}
                  cardLinks={
                          `../product/${relProducts.product_slug}`
                        }
                  product_image={relProducts.product_image}
                  product_image_altName={relProducts.product_name}
                  product_name={relProducts.product_name}
                  mrp={
                    ((relProducts.mrp)/(baseCurrency)).toFixed(2)
                    }
                  sale_price={
                    ((relProducts.sale_price)/(baseCurrency)).toFixed(2)
                    }
                  per={
                     `${((((relProducts.mrp)-(relProducts.sale_price))*100) / (relProducts.mrp)).toFixed()}%`
                     }
                //   buy_now={
                //     <Link to="/checkout">
                //       <Button
                //         className="buynow-btn"
                //         onClick={() => handleClick(relProducts.id)}
                //       >
                //         Buy Now
                //       </Button>
                //     </Link>
                //   }
                />
            </div>
            );
        })}
        </AliceCarousel>
    </div>
  );
}

export default RelatedProducts;
