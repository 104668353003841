import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const mBottom = {
  marginBottom: "40px",
};

function RefundPolicy() {
  return (
    <>
      <Helmet>
        <title>Refund Policy - Gosoftware</title>
        <meta name="description" content="REFUNDS & RETURNS" />
      </Helmet>

      <Container className="gosoftware-padding">
        <div style={mBottom}>
          <h4>REFUNDS & RETURNS</h4>
          <p>
            Gosoftwarebuy aims to provide our buyers with excellent assistance
            and to deliver them with fair return services. To assure our
            customers are satisfied with the product(s) they order, we offer a
            15-day product warranty. Within 15 days of delivery, Gosoftwarebuy
            will reimburse the full amount, minus the cost of transaction fees,
            on new, unused product keys. Before returning any product, you must
            receive authorization by contacting us by email at
            support@gosoftwarebuy.com
          </p>
          <p>
            <strong>Please note:</strong> we will only issue a{" "}
            <strong> replacement</strong> on defective Product Keys and wrong
            version purchase
          </p>
        </div>

        <div style={mBottom}>
          <p>
            Please ensure your replacement request does not meet any of the
            following restrictions before attempting a replacement request.
          </p>
          <h6>
            <strong> 1. Incorrect purchases </strong>
          </h6>
          <p>
            We can issue a replacement if a customer has purchased an incorrect
            product and/or the product is not compatible. We endeavour to make
            the product compatibility as clear and visible as possible, and we
            cannot be held responsible for any incorrect purchases.
          </p>
          <p>
            <strong>example 1</strong>, purchases a windows software package for
            a Mac machine (we can issue replacement keys in some cases) <br />
            <strong>example 2</strong>, purchases a product suitable for windows
            10 only, but has a windows 8 operating system. (we can issue
            replacement keys in some cases)
          </p>
        </div>

        <div style={mBottom}>
          <h6>
            <strong>2. Installation and/or Activation Issues</strong>
          </h6>
          <p>
            Our support team are ready to help with any issues you have with
            your purchased Product Key, therefore before actioning a refund, we
            endeavour to resolve any issues with you. <br />
            We reserve the right to request digital images during this process,
            such as a screenshot of the code being entered and the error
            encountered to help us investigate the issue. <br />
            In some cases, we may need to use remote-based access and support
            software, such as Teamviewer, to resolve your issue. <br />
            Refunds and/or replacements will only be issued in cases where we
            have been allowed access to fully investigate and are unsolvable.
          </p>
        </div>

        <div style={mBottom}>
          <h6>
            <strong>3. Product Use Issues</strong>
          </h6>
          <p>
            For product-related issues, such as making a new document in Word,
            or a program not responding to issues, please contact Microsoft or
            view their{" "}
            <a
                href="https://support.microsoft.com/contactus"
                target="_blank"
                rel="noreferrer"
            >
              online support available here.
            </a>
          </p>
        </div>

        <div style={mBottom}>
          <h6>
            <strong>4. Order Cancellation</strong>
          </h6>
          <p>
            We reserve the right to cancel any incoming order that it might
            believe to be suspicious (such as unauthorised/fraudulent payments
            etc.). Similar paid orders are refunded and the goods are not
            delivered. We cannot cancel orders if you have already received the
            activation code, as this item is no longer safe and secure and there
            is no way to re-sell that item. We cannot cancel or refund an order,
            just because you don’t enjoy / like or use the product. Once you
            activate the code, it is permanently bound to your account,
            therefore we cannot refund you Due to the nature of the product and
            Digital Delivery, once ordered the product key will be shown on the
            screen and emailed directly to you. As we are unable to stop this
            process and be unable to re-issue this unique Product Key, as soon
            as you have purchased your item this would become none refundable
            and you would be unable to cancel your order.
          </p>
        </div>

        <div style={mBottom}>
          <h6>
            <strong>5. Digital Delivery</strong>
          </h6>
          <p>
            As we offer digital products, we are unable to know the identity of
            the person who redeems the code. For each order placed, we track the
            delivery of the Product Key and store vital details of when the code
            has been viewed by the customer, including the date, time, and IP
            address. All of our Product Keys are guaranteed and checked.
          </p>
        </div>

        <div style={mBottom}>
          <h6>
            <strong>6. Error Code Issues</strong>
          </h6>
          <p>
            Redemption errors must be reported within 7 days of purchase. We are
            unable to offer refunds and/or replacements once this date has
            passed.
          </p>
        </div>

        <div style={mBottom}>
          <h6>
            <strong>7. Exchanges</strong>
          </h6>
          <p>
            If the software package is defective and/or cannot be resolved even
            after receiving assistance from our technical support team (Via
            Email/Live Chat), we will issue customers with a replacement product
            key for the same product purchased only.
          </p>
        </div>
        <p>
          Please note that exchange keys are only available within 7 days of the
          original purchase date, therefore we advise you to redeem your product
          straight away
        </p>
      </Container>
    </>
  );
}

export default RefundPolicy;
