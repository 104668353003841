import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const mBottom = {
  marginBottom: "40px",
};

function TermsConditions() {
  return (
    <>
      <Helmet>
        <title>Terms & Conditions - Gosoftware</title>
        <meta
          name="description"
          content="Due to the nature of the digital distribution of active license codes, Gosoftware UK LTD can not offer refunds or exchanges for products once delivered and"
        />
      </Helmet>

      <Container className="gosoftware-padding">
        <div style={mBottom}>
          <p>
            Due to the nature of the digital distribution of active license
            codes, Gosoftware UK LTD can not offer refunds or exchanges for
            products once delivered and activation/license key has been viewed.
            All licensing sales are final.
          </p>
          <p>
            You may request a refund if you have not yet retrieved the
            activation/license key from our system. If you have already
            retrieved the activation/license key, NO REFUNDS will be granted.
            Please make sure the software works on your system before retrieving
            the activation/license key.
          </p>
        </div>
        <div style={mBottom}>
          <h6>
            <strong>Use of Website</strong>
          </h6>
          <p>
            These Terms and Conditions and License Agreement (the “Agreement”)
            are an agreement between Gosoftware UK LTD as( www.gosoftwarebuy.com
            ) (incorporated in England ) doing business as Gosoftware UK LTD
            (“Gosoftware”, “we” or “our”) and you (“you,” “yourself,” “User” or
            “Customer”). This Agreement sets forth the terms and conditions of
            your use of all Gosoftware products and services (collectively, the
            “Services”). Within the scope of such Services, this Agreement
            refers to both (i) your service plan; and (ii) any additional
            services you order to supplement your service plan, as further
            described at Our Services & Prices. By accessing, using, registering
            for or receiving any of the Services, you are agreeing to the terms
            set out below and in our Privacy Policy (“Policies”) which are
            incorporated into this Agreement. Additional terms may apply to
            certain Services that you purchase. In such cases, those additional
            terms also apply in addition to this Agreement. If you do not accept
            and agree to these Terms and Conditions and our Policies then you
            must not access or use the Services.
          </p>
          <p>
            By using the Gosoftware website (“www.gosoftwarebuy.com”), you agree
            to the following terms and conditions of use (“Terms”) and assume
            full responsibility and risk for your use of this Site and any
            services or information contained herein. If you do not agree to the
            Terms of this Website, you should immediately stop utilizing this
            Website.
          </p>
          <p>
            By using this Website, you acknowledge that the content here,
            including these Terms, may change at any time without any
            requirement on the part of Gosoftware to provide notification prior
            to the changes nor notification after the fact. All users are
            responsible for reviewing the Terms and content of this Website for
            changes. Your continued use of this Website after any modification
            to the Terms or content of this Website shall be construed as your
            acceptance of the revisions.
          </p>
          <p>
            By using this Website, you agree that you will use any services or
            content of this Website for lawful purposes only. You are prohibited
            from using this Website to acquire, transfer, store or otherwise
            move any information, data, content, or material in violation of any
            US, Canadian or international law (including law pertaining to
            intellectual property).
          </p>
        </div>
        <div style={mBottom}>
          <h6>
            <strong>Gosoftware Website User Account</strong>
          </h6>
          <p>
            An Gosoftware Website user account is necessary to fully access the
            services and content available through this Website. Having a user
            account for this Website alone does not represent membership within
            Gosoftware. Your having an active and valid user account for this
            Website does not make you an Gosoftware member. However, any
            official Gosoftware membership status may be displayed through your
            user account.
          </p>
          <p>
            By signing up for a Website user account, you agree to provide all
            information required by the signup process factually and only
            information pertaining to your own self. It is prohibited to provide
            third-party information or false information to Gosoftware or
            through this Website in an attempt to impersonate a party you are
            not legally authorized to represent. You may also not use another’s
            party’s account, nor may you allow another party to use your account
            to access content or services that require a user account to access.
          </p>
          <p>
            By signing up for a Website user account, you agree to assume full
            responsibility for all activities that occur on or through your
            account. It is therefore imperative that you keep your account
            information secure. Any breach of your account’s security, such as
            the theft of your password, must be immediately reported to
            Gosoftware. Gosoftware reserves the right to, at its sole
            discretion, terminate (including: ban, delete, etc) your user
            account without prior notice for any reasons included within these
            Terms or otherwise.
          </p>
          <p>
            By signing up for a Website user account, you agree that you have no
            actual ownership of your account or any portion of this Website.{" "}
            <br /> Gosoftware may, at its discretion and without prior notice,
            remove any or all material submitted by your account (including the
            account itself) for reasons including but not limited to: violation
            of these Terms, violation of US, Canadian or international law,
            troublemaking, etc. Gosoftware assumes no liability for any content
            or service removed from our site, whether it originated from
            Gosoftware or a Website user account, and reserves the right to
            permanently restrict or block access to the Website or a user
            account. By using this Website, you agree that Gosoftware will have
            no liability to you if this Website and its content and services are
            discontinued to you or in general.
          </p>
        </div>
        <div style={mBottom}>
          <h6>
            <strong>
              Ownership of Website Content and Intellectual Property
            </strong>
          </h6>
          <p>
            The content and intellectual property on this Website belongs to
            Gosoftware or attributed Gosoftware content providers and licensees
            and protected under Canadian and international law. Content includes
            but is not limited to: information, data, images, video, trademarks,
            service marks, industrial designs, logo, and other material and
            services, both copyrighted and non-copyrighted.
            <br />
            “Gosoftware” and all associated trademarks, service marks, ownership
            designations and logos are the property of Gosoftware. Such markings
            may not be used without the explicit written permission of
            Gosoftware. You may not combine or otherwise modify Gosoftware
            markings to affiliate the Gosoftware markings with that of another
            party to imply a relationship between Gosoftware and the other
            party. All other trademarks, logos or slogans not owned by
            “Gosoftware” that appear on this website are the property of their
            respective owners, who may or may not be affiliated with, connected
            to, or sponsored by “Gosoftware”.
          </p>
          <p>
            The content of this Website may not be reproduced, downloaded,
            screen captured, disseminated, published or otherwise transferred in
            any form or by any means, without the explicit written permission of
            Gosoftware. Unauthorized activities involving Gosoftware Website
            content may be a violation of Canadian or international law, and
            subject to criminal and civil prosecution. By visiting this Website,
            you do not acquire or obtain by implication or otherwise, any
            license or right or permission to use or make additional copies of
            any Gosoftware Website content. You agree not to display or make use
            of any Gosoftware Website content in any manner, including but not
            limited to for commercial purpose, without explicit written
            permission from Gosoftware.
          </p>
        </div>
        <div style={mBottom}>
          <h6>
            <strong>User Submissions</strong>
          </h6>
          <p>
            By submitting material for display on the Gosoftware Website, you
            agree not to submit any content that is illegal, indecent, profane
            (either directly or indirectly through partially obscured words,
            letters, phrases, terminology or similar wording), threatening,
            defamatory, derogatory, or otherwise injurious to Gosoftware or
            third parties. No business solicitation may be made on the
            Gosoftware website without explicit written permission from
            Gosoftware.
          </p>
          <p>
            You also agree that you will not provide personal or confidential
            information about third parties, or infringe on the privacy rights
            or dignity of third parties. You further agree that you will not
            submit materials that consist of or is intended to support,
            disseminate or otherwise promote malicious software code, including
            unsolicited electronic mass mailing (“spam”), regardless of the
            intention or message of the activity or material. As with Website
            User Account, submission of material to Gosoftware may not involve
            any attempt to misrepresentation affiliation or standing with
            Gosoftware or any third party, and may not involve the impersonation
            of any Gosoftware personnel or any third party.
          </p>
          <p>
            All material you submit to Gosoftware become the property of
            Gosoftware without any onus on the part of Gosoftware to provide any
            Gosoftware a non-exclusive, royalty-free, perpetual, irrevocable,
            fully transferable, sub-licensable and assignable right to use,
            edit, modify, adapt, translate, edit, and reformate any material in
            which you may be deemed to continue to hold any ownership right as
            necessary and for any purpose, anywhere in the world, including into
            those forms that may constitute advertising.
          </p>
          <p>
            By using this Website, creating an Gosoftware Website user account,
            and otherwise posting or submitting content to Gosoftware, you
            understand and agree that any material, ideals or communications you
            transmit in any manner and for any reason will not be treated as
            confidential or proprietary.
          </p>
          <p>
            You understand and agree that you will avoid submitting any
            suggestions, materials, ideas or creative concepts for new, modified
            or different products or services to this Website. Failure to comply
            will result in the unsolicited material being deemed to be the full
            property of Gosoftware with all associated rights and privileges,
            without compensation to you. Gosoftware shall not be liable for the
            open disclosure or use of unsolicited material for commercial
            purposes or otherwise.
          </p>
        </div>
        <div style={mBottom}>
          <h6>
            <strong>Professional Certifications</strong>
          </h6>
          <p>
            The various levels and specializations for Gosoftware certification
            is awarded to those businesses or individuals that have successfully
            completed the relevant Gosoftware training or Gosoftware recognized
            equivalency. The certifications are not permanent and must be
            maintained through regular verified technical service activities.
            Gosoftware is not responsible for the conduct or the quality of
            services provided of a Gosoftware certifications.
          </p>
          <p>
            By using this Website, you understand and agree that any reliance on
            any content or service on this Website will be at your own risk.
            Gosoftware makes no representation regarding the length of time that
            any content will be preserved or otherwise stored or displayed.
            Gosoftware reserves the right to revise the content and services of
            this Website without notification to past, present for prospective
            visitors or users. Under no circumstances shall Gosoftware be liable
            for any indirect, special, incidental, or consequential damages
            arising out of any use of the content or services of this Website.
          </p>
        </div>
        <div style={mBottom}>
          <h6>
            <strong>Privacy Statement</strong>
          </h6>
          <p>
            This Website has features a privacy statement setting forth how
            information collected about you is collected, used and stored. Your
            use of this Website constitutes understanding and agreement with our
            Privacy Statement. You further acknowledge and agree that the
            Website may use your personal information in the manner described in
            or Privacy Statement.
          </p>
        </div>
        <div style={mBottom}>
          <h6>
            <strong>Third Party Website</strong>
          </h6>
          <p>
            This Website may be linked to third-party websites which are not
            owned by, maintained by, known by or affiliated with Gosoftware.
            Gosoftware does not sponsor, endorse, approve, certify, or control
            those websites and is not responsible for the contents of such
            sites. Those sites contain information created, published,
            maintained, or otherwise posted by institutions or organizations
            independent of Gosoftware. Gosoftware makes no representations or
            warranties about the content, completeness, or accuracy of those
            third party sites or information located on such sites. Such links
            does not constitute or imply endorsement, recommendation, or
            favouring by Gosoftware.
          </p>
          <p>
            Furthermore, Gosoftware is not responsible for the privacy practices
            of such other sites. The information you submit at a third party
            site accessible from this Website is subject to the terms of that
            site’s privacy policy, and Gosoftware has no control over how your
            information is collected, used, or otherwise handled. Access to any
            other websites linked to or from the Gosoftware site is at your own
            discretion and risk.
          </p>
        </div>
        <div style={mBottom}>
          <h6>
            <strong>Indemnification</strong>
          </h6>
          <p>
            You agree to indemnify and hold Gosoftware, its parents,
            subsidiaries, officers, employees, agents, licensors,
            representatives, and third-party providers to this Website harmless
            for any claims, damages and, expenses, including reasonable
            attorneys’ fees and costs, related to violation of these Terms or
            unlawful or abusive behavior, including the infringement of any
            intellectual property or privacy rights of any person, or any
            violations thereof by your dependants.
          </p>
          <p>
            The above is included in the Terms and Conditions at checkout, by
            acknowledging them, you the buyer agree to them and therefore take
            full responsibility for any products purchased from Gosoftware..
          </p>
          <p>
            Both you and Gosoftware acknowledge and agree that no partnership is
            formed and neither of you nor Gosoftware has the power or the
            authority to obligate or bind the other. These Terms constitute a
            binding agreement between you and Gosoftware, and is accepted by you
            upon your use of this Website. These Terms constitute the entire
            agreement between you and Gosoftware regarding the use of this
            Website.
          </p>
          <p>
            This Agreement will be governed by and construed in accordance with
            US and Canadian law without regard to conflicts of laws principles.
            By using this Website, you hereby agree that any and all disputes
            regarding these Terms will be subject to US and Canadian law. These
            Terms operate to the fullest extent permissible by law. If any
            provision of these Terms is unlawful, void or unenforceable, that
            provision is deemed severable from these Terms and does not affect
            the validity and enforceability of any remaining provisions.
          </p>
          <p>
            Although Gosoftware will not be liable for your losses caused by any
            unauthorized use of your account, you may be liable for the losses
            of Gosoftware or others due to such unauthorized use. If Gosoftware
            fails to act with respect to your breach or anyone else’s breach on
            any occasion, Gosoftware is not waiving its right to act with
            respect to future or similar breaches.
          </p>
        </div>
        <div style={mBottom}>
          <h6>
            <strong>Errors on Our Site</strong>
          </h6>
          <p>
            We try to be as accurate as possible. However, we do not warrant
            that product descriptions or other Site content is accurate,
            complete, reliable, current, or error-free. Prices and promotions
            are subject to change. Despite our best efforts, rarely an item in
            our catalog may not be available, the offer may have been misstated,
            or an item may be mispriced. For any of these reasons, we may cancel
            your order or we may contact you for instructions on the order.
          </p>
        </div>
        <div style={mBottom}>
          <h6>
            <strong>Order Confirmation</strong>
          </h6>
          <p>
            Our order confirmation to you does not signify our acceptance of
            your order, nor does it constitute confirmation of our offer to
            sell. At any time after receipt of your order, we may accept,
            decline, or place quantity or other limits on your order for any
            reason. We may impose these limits on a per-person, per-household,
            per-order, or any other basis. If we reject, limit, or otherwise
            modify your order, we will attempt to notify you using the e-mail
            address you provide to us. If we cancel an order or part of an order
            that we’ve already charged you for, we’ll refund you the full amount
            of the canceled portion of the order.
          </p>
          <p>
            The headings in these Terms are for your convenience and reference.
            These headings do not limit or affect these Terms. These Terms,
            together with those items made a part of these terms by reference,
            make up the entire agreement between you and Gosoftware relating to
            this Website, and replace any prior understandings or agreements
            (whether oral or written) regarding this Website. If a court finds
            any of these terms to be unenforceable or invalid, that term will be
            enforced to the fullest extent permitted by applicable law and the
            other terms will remain valid and enforceable. This Website and any
            promotions contained thereon are void where prohibited.
          </p>
        </div>
        <div style={mBottom}>
          <h6>
            <strong>Registered Address</strong>
          </h6>
          <p>Gosoftware UK LTD</p>
          <p>136-140 Old Shoreham Road,</p>
          <p>Brighton, United Kingdom, BN3BD</p>
          <p>admin@gosoftwarebuy.com</p>
          <p>0800 088 5023 </p>
        </div>
        By using this Website, you understand that these Terms constitute a
        binding Agreement between you and Gosoftware UK LTD.
      </Container>
    </>
  );
}

export default TermsConditions;
