import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Space } from "antd";
import Loader from "../../../Component/Loader/Loader";
import { deleteFromCart, getCart, currencyDetail } from "../../../store/Cart/action";
import { useDispatch, useSelector } from "react-redux";
import CartItem from "./CartItem";
import ApplyCoupon from "./ApplyCoupon";
import LoaderSecond from "../../../Component/Loader/LoaderSecond";


function CartOnCheckount() {

  const [totalAmount, setTotalAmount] = useState();
  const [discountAmount, setDiscountAmount] = useState();


  // redux data
  const dispatch = useDispatch();
  const { isCartItemsFetching, cartItems, currencyItems, currencySelectByUser } = useSelector(
    (state) => state && state.CartReducer
  );

  const { isSubmitting, checkCouponData } =
    useSelector((state) => state && state.couponReducer);


    // for if cartItems is null
    const userIdForSend = localStorage.getItem("userId") ? localStorage.getItem("userId") : "";
    const data = undefined;
    useEffect(() => {
      if(cartItems?.carts === data){
        dispatch(getCart(userIdForSend));
        dispatch(currencyDetail());
      }
    },[userIdForSend]);

    
    // for calculate total Amount
    const { discount_per } = checkCouponData;
    useEffect(() => {
      if (discount_per === data) {
        setTotalAmount(cartItems?.total?.toFixed(2));
      }else { 
        setTotalAmount((cartItems?.total - ((cartItems?.total * discount_per) / 100)).toFixed(2));
        setDiscountAmount(((cartItems?.total) - (cartItems?.total - ((cartItems?.total * discount_per) / 100))).toFixed(2));
      }
    },[isSubmitting, isCartItemsFetching, discount_per]);
    // for calculate total Amount


  // for delete cart
  const handleDelete = (id) => {
    dispatch(deleteFromCart(id));
  };

  const selectedCurrencySaved = JSON.parse(localStorage.getItem("selectedCurrency"));
  // for courrency change start
    const { gbp } = currencyItems;
    const baseCurrency = gbp / (currencySelectByUser?.value ? currencySelectByUser?.value : selectedCurrencySaved?.value)
  // for courrency change end


  
  return (
    <>
      <div className="gosoftware-padding ps-3" id="cart_box_fix">
        <div className="cart_total_checkout position-fixed">
          <h6 className="cart_total_checkout_heading">
            ITEMS SUMMARY <span className="font-weight-normal">({cartItems?.carts?.length} item)</span>
          </h6>

          <Row>
            <Col>
              {isCartItemsFetching ? (
                <p style={{ textAlign: "center" }}>
                  <Loader />
                </p>
              ) : (
                <div>
                  {cartItems?.carts?.map((item) => {
                    return (
                      <div
                        className="cart_total_checkout_product"
                        key={item.id}
                      >
                        <Row>
                          <Col sm={2}>
                            <div>
                              <img
                                src={item.product.product_image}
                                width="60px"
                                alt="cart checkout product"
                              />
                            </div>
                          </Col>
                          <Col sm={7}>
                            <p>
                              {item.product.product_name} <br />
                              <span style={{ color: "#4e4e4e", fontSize: "0.7rem" }}>
                              {currencySelectByUser?.name ? currencySelectByUser?.name : selectedCurrencySaved?.name}
                              {((item.product.sale_price)/(baseCurrency)).toFixed(2)}
                              </span>
                            </p>
                          </Col>
                          <Col sm={3}>
                            <div>
                              <div className="float-sm-end">
                                <Space>
                                  <CartItem value={item.qty} id={item.id} />
                                </Space>
                              </div>
                              <div className="float-sm-end mt-1">
                                <span style={{ color: "#4e4e4e", fontSize: "0.7rem" }}>
                                {currencySelectByUser?.name ? currencySelectByUser?.name : selectedCurrencySaved?.name}
                                {((item.product.sale_price*item.qty)/(baseCurrency)).toFixed(2)}
                                </span>
                              </div>
                              <div className="float-sm-end">
                                <span
                                  title="Remove item"
                                  className="text-danger float-sm-end"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleDelete(item.id)}
                                >
                                  Remove
                                </span>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </div>
              )}
            </Col>
          </Row>

          <section className="bg_grey">
            <Row>
              <div className="d-flex cart_total_checkout_coupon">
                <Col sm={12}>
                  <ApplyCoupon />
                </Col>
              </div>
            </Row>

            <Row>
              <Col sm={12}>
                <div className="">
                  <Row>
                    <Col>
                      {/* <h4>Cart total</h4> */}
                      <div>
                        <div className="checkout-subTotal">
                          <Row>
                            <Col sm={3}>
                              <span style={{ color: "#4e4e4e" }}>Subtotal</span>
                            </Col>
                            <Col sm={9}>
                              {isCartItemsFetching ? <LoaderSecond /> : (
                                  <span style={{ color: "#4e4e4e" }}>
                                    {currencySelectByUser?.name ? currencySelectByUser?.name : selectedCurrencySaved?.name}
                                    {((cartItems?.total)/(baseCurrency)).toFixed(2)}
                                  </span>
                              )}
                            </Col>
                          </Row>
                        </div>

                        {discountAmount && (
                            <div className="checkout-subTotal">
                              <Row>
                                <Col sm={3}>
                                  <h6 style={{ color: "#4e4e4e" }}>Discount</h6>
                                </Col>
                                <Col sm={9}>
                                  <h6 style={{ color: "#4e4e4e" }}>-{currencySelectByUser?.name ? currencySelectByUser?.name : selectedCurrencySaved?.name}
                                    {((discountAmount)/(baseCurrency)).toFixed(2)}</h6>
                                </Col>
                              </Row>
                            </div>
                        )}



                        <div className="checkout-subTotal">
                          <Row>
                            <Col sm={3}>
                              <h6>Total</h6>
                            </Col>
                            <Col sm={9}>
                              {isCartItemsFetching ? <LoaderSecond /> : (
                                  <h6>
                                    {currencySelectByUser?.name ? currencySelectByUser?.name : selectedCurrencySaved?.name}
                                    {((totalAmount)/(baseCurrency)).toFixed(2)}
                                  </h6>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </section>
        </div>
      </div>
    </>
  );
}

export default CartOnCheckount;



