import * as types from "./constant";

export const initialState = {
  isWebProductListFetching: false,
  webProductList: [],
  singleProduct: [],
  productSearchData: [],
  bestSellerProduct: [],
  webProductListErrr: null,
};

const WebProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_WEB_PRODUCT_LIST:
      return {
        ...state,
        isWebProductListFetching: true,
        webProductListErrr: null,
      };
    case types.GET_WEB_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        isWebProductListFetching: false,
        webProductList: action.payload,
        webProductListErrr: null,
      };
    case types.GET_WEB_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        isWebProductListFetching: false,
        webProductListErrr: action.payload,
      };

    case types.GET_SINGLE_PRODUCT:
      return {
          ...state,
          isWebProductListFetching: true,
          webProductListErrr: null,
        };
    case types.GET_SINGLE_PRODUCT_SUCCESS:
      return {
          ...state,
          isWebProductListFetching: false,
          singleProduct: action.payload,
          webProductListErrr: null,
        };
    case types.GET_SINGLE_PRODUCT_FAILURE:
      return {
          ...state,
          isWebProductListFetching: false,
          webProductListErrr: action.payload,
        };


    case types.GET_PRODUCT_BY_SEARCH:
      return {
        ...state,
        isWebProductListFetching: true,
        webProductListErrr: null,
      };

    case types.GET_PRODUCT_BY_SEARCH_SUCCESS:
      return {
        ...state,
        isWebProductListFetching: false,
        productSearchData: action.payload,
        webProductListErrr: null,
      };

    case types.GET_PRODUCT_BY_SEARCH_FAILURE:
      return {
        ...state,
        isWebProductListFetching: false,
        webProductListErrr: action.payload,
      };

    case types.GET_BEST_SELLER_PRODUCT:
      return {
        ...state,
        isWebProductListFetching: true,
        webProductListErrr: null,
      };
    case types.GET_BEST_SELLER_PRODUCT_SUCCESS:
      return {
        ...state,
        isWebProductListFetching: false,
        bestSellerProduct: action.payload,
        webProductListErrr: null,
      };
    case types.GET_BEST_SELLER_PRODUCT_FAILURE:
      return {
        ...state,
        isWebProductListFetching: false,
        webProductListErrr: action.payload,
      };

    default:
      return state;
  }
};

export default WebProductReducer;
