import React, { useState } from "react";
import "./sidebar.scss";
import { Outlet, Link } from "react-router-dom";
import {
  ShoppingCartOutlined,
  UserOutlined,
  HomeOutlined,
  ProfileOutlined,
  LogoutOutlined,
  RiseOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { Menu, Layout, Avatar, Button, Popover } from "antd";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../store/Auth/action";
import { Helmet } from "react-helmet";


function Sidebar() {

  // for extra menu

  const { Header, Sider, Content } = Layout;

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  // for extra menu
  const dispach = useDispatch();
  const content = (
    <div>
      <Link to="/admin/profile">
        <Button className="admin-topbar-btn" icon={<ProfileOutlined />}>
          My Profile
        </Button>{" "}
        <br />
      </Link>
      {/* <Button className="admin-topbar-btn" icon={<SettingOutlined />}>
        Account Setting
      </Button>{" "}
      <br /> */}
      <Button
        className="admin-topbar-btn"
        icon={<LogoutOutlined />}
        onClick={() => dispach(logoutUser())}
      >
        LogOut
      </Button>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Gosoftware - Admin panel</title>
      </Helmet>

      {/* Menu extra start */}

      {/* <Switch
              checked={theme === 'dark'}
              onChange={changeTheme}
              checkedChildren="Dark"
              unCheckedChildren="Light"
            />
            <br />
            <br /> */}
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={240}
          style={{
            position: "fixed",
            height: "100vh",
            zIndex: "999",
            left: "0",
          }}
        >
          <button
            title="Toggle Menu"
            className="sidebarWrapperButton sidebarWrapperButton-in"
            onClick={toggleCollapsed}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>

          <div className="sidebarLogo">
            {collapsed ? (
              <span style={{ marginLeft: "0.6rem" }}>
                <img
                    src={`${process.env.PUBLIC_URL}/Assets/logofooter-icon.png`}
                    alt="Gosoftware admin"
                />
              </span>
            ) : (
              <img
                  src={`${process.env.PUBLIC_URL}/Assets/logofooter.png`}
                  width="180"
                  alt="Gosoftware admin"
              />
            )}
          </div>
          <Menu
            mode="inline"
            theme={"dark"}
            defaultOpenKeys={['product', 'categories', 'sales']}
            // inlineCollapsed={collapsed}
          >
            {/* <Menu.SubMenu
              title="Dashboard"
              key="dashboard"
              icon={<HomeOutlined />}
            > */}
              <Menu.Item key="Dashboard" icon={<HomeOutlined />}>
                <span>Dashboard</span>
                <Link to="/admin" />
              </Menu.Item>
            {/* </Menu.SubMenu> */}

            <Menu.SubMenu
              title="Product"
              key="product"
              icon={<ShoppingCartOutlined />}
            >
              <Menu.Item key="Manage Product">
                <span>Manage Product</span>
                <Link to="/admin/product" />
              </Menu.Item>
              <Menu.Item key="Add Product">
                <span>Add Product</span>
                <Link to="/admin/add-product" />
              </Menu.Item>
              <Menu.Item key="Product Keys">
                <span>Product Keys</span>
                <Link to="/admin/product-key" />
              </Menu.Item>
              <Menu.Item key="Coupon Code">
                <span>Coupon Code</span>
                <Link to="/admin/coupon" />
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              title="Categories"
              key="categories"
              icon={<FileSearchOutlined />}
            >
              <Menu.Item key="Category">
                <span>Category</span>
                <Link to="/admin/category" />
              </Menu.Item>
              {/* <Menu.Item key="Add Category">
                <span>Add Category</span>
                <Link to="/admin/add-category" />
              </Menu.Item> */}
              <Menu.Item key="SubCategory">
                <span>SubCategory</span>
                <Link to="/admin/sub-category" />
              </Menu.Item>
            </Menu.SubMenu>

            {/* <Menu.SubMenu 
              title="Sales" 
              key="sales" 
              icon={<RiseOutlined />}
            > */}
              <Menu.Item icon={<RiseOutlined />} key="Sales Orders">
                <span>Orders</span>
                <Link to="/admin/orders" />
              </Menu.Item>
            {/* </Menu.SubMenu> */}

          </Menu>
        </Sider>
        <Layout>
          <Header
            style={{ position: "fixed", top: "0", zIndex: "1", width: "100%" }}
          >
            <div className="topbar">
              <div className="topbarWrapper">
                <div className="topLeft">
                  <span className="admin-logo">
                    {/* <img src="../../Images/logofooter.png" /> */}
                  </span>
                </div>
                <div className="topRight">
                  <div className="topbar-text">
                    <h6>Admin</h6>
                  </div>
                  <div className="topbarIcons">
                    <Popover content={content} trigger="hover">
                      <Avatar
                        className="admin-avatar"
                        title="Admin"
                        icon={<UserOutlined />}
                      />
                    </Popover>
                  </div>
                </div>
              </div>
            </div>
          </Header>

          {collapsed ? (
            <Content style={{ marginLeft: "80px", marginTop: "60px" }}>
              {" "}
              <Outlet />{" "}
            </Content>
          ) : (
            <Content style={{ margin: "60px 0 0 240px" }}>
              {" "}
              <Outlet />{" "}
            </Content>
          )}
        </Layout>
      </Layout>

      {/* Menu extra end */}
    </>
  );
}

export default Sidebar;
