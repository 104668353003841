import { takeLatest, put, all, call} from "redux-saga/effects";
import { ADD_COUPON, CHECK_COUPON_CODE, DELETE_COUPON, GET_ALL_COUPON, GET_COUPON_BY_ID, UPDATE_COUPON } from "./constant";
import api from "../../utils/api"
import { addCouponFailure, addCouponSuccess, checkCouponCodeFailure, checkCouponCodeSuccess, deleteCouponFailure, deleteCouponSuccess, getAllCoupon, getAllCouponFailure, getAllCouponSuccess, getCouponById, getCouponByIdFailure, getCouponByIdSuccess, updateCouponFailure, updateCouponSuccess } from "./action";


// get all coupon data
export function* fetchCouponListData({payload}) {
    const token = localStorage.getItem("token");
    try {
        let query = payload ? payload : ''
        const response = yield call(api(token).get, `/admin/coupon${query}`,);
        if (response) {
            yield put(getAllCouponSuccess(response && response.result))
        }
    } catch(e) {
        yield put(getAllCouponFailure(e));
    }
} 


// add coupon 
export function* addCouponData({payload}){
    const token = localStorage.getItem("token");
    try {
        const response = yield call(api(token).post, `/admin/coupon/create`, payload);
        if(response) {
            yield put(addCouponSuccess(response));
            yield put(getAllCoupon());
        }
    } catch (e) {
        yield put(addCouponFailure(e));
    }
}

// get coupon by id
export function* fetchCouponByIdData({payload}){
    const token = localStorage.getItem("token");
    try {
        let query = payload ? payload : ''
        const response = yield call(api(token).get, `/admin/coupon/get/${query}`,);
        if(response){
            yield put(getCouponByIdSuccess(response && response.result));
        }
    } catch (e) {
        yield put(getCouponByIdFailure(e));
    }
}

// update coupon
export function* updateCouponData({payload}){
    const token = localStorage.getItem("token");
    try {
        const response = yield call(api(token).post, `/admin/coupon/update/${payload.id}`, payload.formData);
        if(response){
            const id = response.result.id
            yield put(updateCouponSuccess(response));
            yield put(getCouponById(id));
        }
    } catch (e) {
        yield put(updateCouponFailure(e));
    }
}

// delete coupon
export function* deleteCouponData({payload}){
    const token = localStorage.getItem("token");
    try {
        const response = yield call(api(token).delete, `/admin/coupon/delete/${payload}`, );
        if(response){
            yield put(deleteCouponSuccess(response));
            yield put(getAllCoupon());
        }
    } catch(e){
        yield put(deleteCouponFailure(e));
    }
}

// check coupon code for  web
export function* checkCouponCodeData({payload}){
    try {
        const response = yield call(api().post, `/coupon_code`, payload);
        if(response) {
            yield put(checkCouponCodeSuccess(response));
        }
    } catch (e) {
        yield put(checkCouponCodeFailure(e));
    }
}


// get all Coupon saga flow
export function* fetchCouponListFlow() {
    yield takeLatest(GET_ALL_COUPON, fetchCouponListData);
}

// add coupon
export function* addCouponFlow(){
    yield takeLatest(ADD_COUPON, addCouponData);
}

// get coupon by id flow
export function* fetchCouponByIdFlow(){
    yield takeLatest(GET_COUPON_BY_ID, fetchCouponByIdData);
}

// update coupon flow
export function* updateCouponFlow(){
    yield takeLatest(UPDATE_COUPON, updateCouponData);
}

// delete coupon flow
export function* deleteCouponFlow(){
    yield takeLatest(DELETE_COUPON, deleteCouponData);
}


// for web check coupon code
export function* checkCouponCodeFlow(){
    yield takeLatest(CHECK_COUPON_CODE, checkCouponCodeData);
}

export default function* CouponSaga() {
    yield all([
        fetchCouponListFlow(),
        addCouponFlow(),
        fetchCouponByIdFlow(),
        updateCouponFlow(),
        deleteCouponFlow(),
        checkCouponCodeFlow(),
    ]);
}