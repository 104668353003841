import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const mBottom = {
  marginBottom: "40px",
};

function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - Gosoftware</title>
        <meta
          name="description"
          content="This privacy policy sets out how Gosoftware UK LTD ( Gosoftware) uses and protects any information that you give Gosoftware UK LTD when you use this website."
        />
      </Helmet>

      <Container className="gosoftware-padding">
        <div style={mBottom}>
          <p>
            This privacy policy sets out how Gosoftware UK LTD ( Gosoftware)
            uses and protects any information that you give Gosoftware UK LTD
            when you use this website.
          </p>
          <p>
            Gosoftware is committed to ensuring that your privacy is protected.
            Should we ask you to provide certain information by which you can be
            identified when using this website, you can be assured that it will
            only be used in accordance with this privacy statement.
          </p>
          <p>
            Gosoftwaremay change this policy from time to time by updating this
            page. You should check this page from time to time to ensure that
            you are happy with any changes. This policy is effective from Feb
            21, 2020.
          </p>
        </div>

        <div style={mBottom}>
          <h6>
            <strong>What we collect</strong>
          </h6>
          <p>We may collect the following information:</p>
          <p>Name</p>
          <p>Address</p>
          <p>E-mail Address</p>
          <p>Phone Number</p>
          <p>What we do with the information we gather</p>
          <p>
            We require this information to understand your needs and provide you
            with better service, and in particular for the following reasons:
          </p>
        </div>

        <div style={mBottom}>
          <h6>
            <strong>Internal record keeping</strong>
          </h6>
          <p>
            We may use the information to improve our products and services.{" "}
            <br />
            We may periodically send promotional emails about new products,
            special offers or other information that we think you may find
            interesting using the email address which you have provided.
          </p>
          <p>
            From time to time, we may also use your information to contact you
            for market research purposes. We may contact you by email, phone,
            fax or mail. We may use the information to customize the website
            according to your interests.
          </p>
        </div>

        <div style={mBottom}>
          <h6>
            <strong>Security</strong>
          </h6>
          <p>
            We are committed to ensuring that your information is secure. In
            order to prevent unauthorized access or disclosure, we have put in
            place suitable physical, electronic and managerial procedures to
            safeguard and secure the information we collect online.
          </p>
        </div>

        <div style={mBottom}>
          <h6>
            <strong>How we use cookies</strong>
          </h6>
          <p>
            A cookie is a small file which asks permission to be placed on your
            computer’s hard drive. Once you agree, the file is added and the
            cookie helps analyze web traffic or lets you know when you visit a
            particular site. Cookies allow web applications to respond to you as
            an individual. The web application can tailor its operations to your
            needs, likes and dislikes by gathering and remembering information
            about your preferences.
          </p>
          <p>
            We use traffic log cookies to identify which pages are being used.
            This helps us analyze data about web page traffic and improve our
            website in order to tailor it to customer needs. We only use this
            information for statistical analysis purposes and then the data is
            removed from the system.
          </p>
          <p>
            Overall, cookies help us provide you with a better website, by
            enabling us to monitor which pages you find useful and which you do
            not. A cookie in no way gives us access to your computer or any
            information about you, other than the data you choose to share with
            us.
          </p>
          <p>
            You can choose to accept or decline cookies. Most web browsers
            automatically accept cookies, but you can usually modify your
            browser setting to decline cookies if you prefer. This may prevent
            you from taking full advantage of the website.
          </p>
        </div>

        <div style={mBottom}>
          <h6>
            <strong>Links to other websites</strong>
          </h6>
          <p>
            Our website may contain links to other websites of interest.
            However, once you have used these links to leave our site, you
            should note that we do not have any control over that other website.
            Therefore, we cannot be responsible for the protection and privacy
            of any information which you provide whilst visiting such sites and
            such sites are not governed by this privacy statement. You should
            exercise caution and look at the privacy statement applicable to the
            website in question.
          </p>
        </div>

        <div style={mBottom}>
          <h6>
            <strong>Controlling your personal information</strong>
          </h6>
          <p>
            You may choose to restrict the collection or use of your personal
            information in the following ways:
          </p>
          <p>
            Whenever you are asked to fill in a form on the website, look for
            the box that you can click to indicate that you do not want the
            information to be used by anybody for direct marketing purposes.
          </p>
          <p>
            If you have previously agreed to us using your personal information
            for direct marketing purposes, you may change your mind at any time
            by writing to or emailing us at support@computosolution.co.uk.
          </p>
          <p>
            We will not sell, distribute or lease your personal information to
            third parties unless we have your permission or are required by law
            or under contract to do so. In limited circumstances, we may share
            your personal information with the software manufacturer(s) relevant
            to your purchase if necessary for the completion of the subscription
            activation process or where required by distribution contract. We
            may use your personal information to send you promotional
            information about third parties which we think you may find
            interesting if you tell us that you wish this to happen.
          </p>
          <p>Statement about the Protection of Personal Data Transfer</p>
          <p>
            Data protection pursuant to the General Data Protection Regulation
            of the European Parliament and the Council no. 2016/679- Regulation
            and implementation of the GDPR
          </p>
          <p>
            WSPay, being the processor of authorization and payment made by
            credit cards, uses personal data as the processor pursuant to the
            General Data Protection Regulation of the European Parliament and
            the Council no. 2016/679, and compliant with PCI DSS Level 1
            Regulations for data transfers.
          </p>
          <p>
            WSPay uses 256-bit SSL encryption and TLS 1.2 cryptographic protocol
            as the highest protection standards for data entry and transfer.
          </p>
          <p>
            Personal data used for the purposes of authorization and payment are
            deemed to be confidential data.
          </p>
          <p>
            WSPay does not process or use these personal data except for the
            purpose of fulfilling the Agreement, the authorization and the
            payment.
          </p>
          <p>
            WSPay ensures to meet the requirements determined by applicable
            personal data protection regulations, for the processors of personal
            data, especially taking all necessary technical, organizational or
            security measures confirmed by PCI DSS Level 1 certificate.
          </p>
        </div>

        <div style={mBottom}>
          <h6>
            We do not store credit card details nor do we share financial
            details with any 3rd parties”{" "}
          </h6>
          <p>Gosoftware UK LTD</p>
          <p>136-140 Old Shoreham Road,</p>
          <p>Brighton, United Kingdom, BN3 7BD</p>
        </div>

        <div style={mBottom}>
          <p>
            If you believe that any information we are holding on you is
            incorrect or incomplete, please write to or email us as soon as
            possible, at the above address. We will promptly correct any
            information found to be incorrect.
          </p>
        </div>
      </Container>
    </>
  );
}

export default PrivacyPolicy;
