import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import "../style.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import PreviewProductImage from "../Component/PreviewProductImage";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// start redux
import { getCategoryList } from "../../../../../store/Category/action";
import { getSubCategoryByCategoryId } from "../../../../../store/SubCategory/action";
import { addProduct } from "../../../../../store/Product/action";
import { useDispatch, useSelector, connect } from "react-redux";
import { compose } from "redux";
// end redux



function AddProduct({ saveProduct }) {

  const navigate = useNavigate();
  const fileRef = useRef(null);
  //  const galleryRef = useRef(null);
  const [visible, setIsVisible] = useState(false);
  // redux data start
  const dispatch = useDispatch();
  
  const { isSubmitting, productSubmitErrr } = useSelector(
    (state) => state && state.ProductReducer
  );

  const { categoryList } = useSelector((state) => state && state.CategoryReducer);
  const { webSubCategoryList } = useSelector((state) => state && state.SubCategoryReducer);
  // redux data end

  useEffect(() => {
    dispatch(getCategoryList());
  }, []);

  // for gallery view

  const validationSchema = yup.object().shape({
    product_name: yup.string().required("Product name is required."),
    category_id: yup.string().required("Product category is required."),
    sub_category_id: yup.string().required("Sub category is required."),
    product_status: yup.string().required("Product status is required"),
    best_seller: yup.string(),
    mrp: yup.string().required("MRP is required."),
    sale_price: yup.string().required("Sale price is required."),
    short_desc: yup.string().required("Short description is required."),
    desc: yup.string().required("Description is required."),
    product_image: yup.mixed().required("Product image is required."),
    // product_gallery: yup.mixed().required("gallery is required."),
    download_link: yup.string(),
    seo_title: yup.string().required("SEO title is required."),
    seo_description: yup.string().required("SEO description is required."),
  });

  // onSubmit form data
  const onSubmit = () => {
    // console.log("values", values);
    const formData = new FormData();
    if (values.product_image) {
      formData.append("product_image", values.product_image);
    }
    // if (values.product_gallery) {
    //   formData.append("product_gallery[]", values.product_gallery);
    // }
    formData.append("product_name", values.product_name);
    formData.append("category_id", values.category_id);
    formData.append("sub_category_id", values.sub_category_id);
    formData.append("product_status", values.product_status);
    formData.append("best_seller", values.best_seller);
    formData.append("mrp", values.mrp);
    formData.append("sale_price", values.sale_price);
    formData.append("short_desc", values.short_desc);
    formData.append("desc", values.desc);
    formData.append("download_link", values.download_link);
    formData.append("seo_title", values.seo_title);
    formData.append("seo_description", values.seo_description);

    saveProduct(formData);

    // for error message start
        setIsVisible(true);
      // for error message end
      setTimeout(function () {
        setIsVisible(false);
      }, 8000);
  };

  

  const {
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      product_name: "",
      category_id: "",
      sub_category_id: "",
      product_status: "",
      best_seller: "No",
      mrp: "",
      sale_price: "",
      short_desc: "",
      desc: "",
      product_image: null,
      // product_gallery: null,
      download_link: "",
      seo_title: "",
      seo_description: "",
    },
    validateOnBlur: true,
    validationSchema,
    onSubmit,
    // enableReinitialize: true,
  });


  return (
    <>
      <Helmet>
        <title>Gosoftware - Add Product</title>
      </Helmet>

      <div className="admin-product-page">
        <div className="admin-product-page-data">
          <div className="admin-product-page-title">
            <h4>Add Product</h4>
            <Link to="/admin/product" className="admin-page-button">
              {" "}
              <LeftOutlined /> Back
            </Link>
          </div>

          <div className="admin-product" style={{ padding: "1rem 2.2rem" }}>
            <Form
              noValidate
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              className="ant-table-admin"
              style={{ padding: "2.2rem" }}
            >
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>
                    Product Name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="product_name"
                    value={values.product_name}
                    onChange={handleChange}
                    isInvalid={!!errors.product_name && touched.product_name}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.product_name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>
                    Product Category<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    type="text"
                    name="category_id"
                    defaultValue={values.category_id}
                    onChange={(e) => {
                      setFieldValue("category_id", e.target.value);
                      dispatch(getSubCategoryByCategoryId(e.target.value));
                    }}
                    isInvalid={!!errors.category_id && touched.category_id}
                  >
                    <option>Select category</option>
                    {categoryList.map((option) => (
                      <option value={option.id} key={option.id}>{option.category_name}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.category_id}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>
                    Product Sub Category<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    type="text"
                    name="sub_category_id"
                    value={values.sub_category_id}
                    onChange={handleChange}
                    isInvalid={!!errors.sub_category_id && touched.sub_category_id}
                  >
                    <option>Select sub category</option>
                    {webSubCategoryList.map((option) => (
                      <option value={option.id} key={option.id}>{option.sub_category_name}</option>
                    ))}
                  </Form.Select>

                  <Form.Control.Feedback type="invalid">
                    {errors.sub_category_id}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>
                    Product Status<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    type="text"
                    name="product_status"
                    value={values.product_status}
                    onChange={handleChange}
                    isInvalid={
                      !!errors.product_status && touched.product_status
                    }
                  >
                    <option>Select status</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </Form.Select>

                  <Form.Control.Feedback type="invalid">
                    {errors.product_status}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>
                    Best Seller
                  </Form.Label>
                  <Form.Select
                    type="text"
                    name="best_seller"
                    value={values.best_seller}
                    onChange={handleChange}
                    isInvalid={
                      !!errors.best_seller && touched.best_seller
                    }
                  >
                    <option value="Yes">Yes</option>
                    <option value="No" selected>No</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.best_seller}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>


              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>
                    MRP<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    className="Form-control"
                    type="number"
                    name="mrp"
                    step={0.01}
                    precision={2}
                    value={values.mrp}
                    onChange={handleChange}
                    isInvalid={!!errors.mrp && touched.mrp}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mrp}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>
                    Sale Price<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="sale_price"
                    step={0.01}
                    precision={2}
                    value={values.sale_price}
                    onChange={handleChange}
                    isInvalid={!!errors.sale_price && touched.sale_price}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.sale_price}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="short_desc">
                  <Form.Label>
                    Short Description<span className="text-danger">*</span>
                  </Form.Label>
                  <div className="editor">
                    <CKEditor
                      editor={ClassicEditor}
                      id="inputText"
                      name="short_desc"
                      // data={text}
                      // onChange={handleCKeditor}
                      onChange={(event, editor) => {
                        setFieldValue("short_desc", editor.getData());
                      }}
                    />
                  </div>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>
                    Description<span className="text-danger">*</span>
                  </Form.Label>
                  <div className="editor">
                    <CKEditor
                      editor={ClassicEditor}
                      id="inputText"
                      name="desc"
                      // data={text}
                      // onChange={handleCKeditor}
                      onChange={(event, editor) => {
                        setFieldValue("desc", editor.getData());
                      }}
                    />
                  </div>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Form.Group>
                    {/* <Form.Label>Product Image<span className='text-danger'>*</span></Form.Label> */}
                    <Form.Label htmlFor="product_image_id">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        onClick={() => fileRef.current.click()}
                        className="product-image-button"
                      >
                        Upload image
                      </Button>
                    </Form.Label>
                    <Form.Control
                      hidden
                      id="product_image_id"
                      ref={fileRef}
                      type="file"
                      required
                      name="product_image"
                      accept="image/*"
                      onChange={(event) => {
                        setFieldValue("product_image", event.target.files[0]);
                      }}
                      isInvalid={!!errors.product_image && touched.sale_price}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.product_image}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col>
                  <div>
                    {values.product_image && (
                      <PreviewProductImage file={values.product_image} />
                    )}
                  </div>
                </Col>
              </Row>

              {/* <Row>
                <div>
                  <ImageGallery setFieldValue={setFieldValue} />
                </div>
              </Row> */}

              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>
                    Download link
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={1}
                    name="download_link"
                    value={values.download_link}
                    onChange={handleChange}
                    isInvalid={
                      !!errors.download_link && touched.download_link
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.download_link}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>


              <Row className="mb-3">
                <h4>SEO</h4>
                <Form.Group as={Col}>
                  <Form.Label>
                    Title<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="seo_title"
                    value={values.seo_title}
                    onChange={handleChange}
                    isInvalid={!!errors.seo_title && touched.seo_title}
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.seo_title}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>
                    Meta description<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="seo_description"
                    value={values.seo_description}
                    onChange={handleChange}
                    isInvalid={
                      !!errors.seo_description && touched.seo_description
                    }
                  />

                  <Form.Control.Feedback type="invalid">
                    {errors.seo_description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <div>
                <Button
                  type="submit"
                  className="subFormButton"
                  size="lg"
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <Spinner animation="border" size="sm" as="span" />
                  )}
                  Submit{" "}
                </Button>
              </div>
            </Form>
          </div>

              <div>
              {isSubmitting ? (
                ""
              ) : (
                <>
                  {visible && (
                    <>
                      {productSubmitErrr === "Success" ? (
                        navigate("/admin/product")
                      ) : (
                        <p className="error-message">
                          *{productSubmitErrr}
                        </p>
                      )}
                    </>
                  )}
                </>
              )}
            </div>

        </div>
      </div>
    </>
  );
};

// export default AddProduct;

function mapDispatchToProps(dispatch) {
  return {
    saveProduct: (data) => dispatch(addProduct(data)),
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(AddProduct);
