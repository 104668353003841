import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import FrequentlyAskedQuestions from "../FrequentlyAskedQuestions/index";

function CategoryBottom() {
  return (
    <React.Fragment>
      <Container className="category_bottom">
        <h3>Requirements</h3>
        <ul>
          <li>
            Internet and Microsoft Account are required for installation. Once
            it is installed, can use it without the internet.
          </li>
          <li>
            PC: Windows 10 & 11, 1.6 GHz, dual-core processor, 4 GB, 2 GB (32/64
            bit) RAM, 4 GB available disk space, 1280×768 screen resolution.
          </li>
          <li>
            Mac: macOS 11.0: Big Sur, macOS 12.0: Monterey & macOS 13.0:
            Ventura.
          </li>
        </ul>
      </Container>

      <Container fluid className="category_bottom_divider">
        <Container className="category_bottom_divider_child">
          <h4>
            Genuine official licenses <br />
            you'll shop secure and trusted.
          </h4>
          <h4>
            #1 Software Specialist <br />
            GOSOFTWARE
          </h4>
        </Container>
      </Container>

      <Container>
        <Row>
          <Col className="category_bottom_icons">
            <img
              src={`${process.env.PUBLIC_URL}/Assets/pf-09f3c29f-AOneTimePurchase2.gif`}
              alt="A One Time Purchase"
              height="90px"
            />
            <h4>A One Time Purchase</h4>
            <p>
              No annual or monthly subscription. Buy and use forever perpetual
              License
            </p>
          </Col>

          <Col className="category_bottom_icons">
            <img
              src={`${process.env.PUBLIC_URL}/Assets/pf-9f04db24-InstallOnOnePC2.gif`}
              alt="Install on one PC"
              height="90px"
            />
            <h4>Install on one PC</h4>
            <p>
              Compatible with Once device not compatible with Chromebook, iPad,
              iPhone or mobile
            </p>
          </Col>

          <Col className="category_bottom_icons">
            <img
              src={`${process.env.PUBLIC_URL}/Assets/classic-office-apps.gif`}
              alt="Classic Office Apps"
              height="90px"
            />
            <h4>Classic Office Apps</h4>
            <p>Word, Excel, PowerPoint, Outlook, Publisher, Access & OneNote</p>
          </Col>
        </Row>

        <Row>
          <Col className="category_bottom_information">
            <h3>Information about Office 2021</h3>
            <p>
              Office 2021 is compatible with Windows 10, Windows 11 and recent
              three versions of Mac OS(latest Monterey). Select a complete
              Office 2021 Home & Business including Outlook and Teams where Home
              & business use is allowed. If you want to use the package for home
              or if you are a student, select Office 2021 Home & Student
              includes Word, Excel, Powerpoint, OneNote and Teams. Apps like
              OneDrive and Skype are not included.
            </p>
            <h3 style={{ marginTop: "3rem" }}>Frequently Asked Questions</h3>
            <FrequentlyAskedQuestions />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
export default CategoryBottom;
