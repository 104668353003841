import * as types from "./constant";

      const min = 0;
      const max = 99999;
      const random = Math.floor(Math.random() * (max - min + 1)) + min;
       

export const initialState = {
  isAuthenticated: !!localStorage.getItem("token"),
  isRegisterUser: false,
  errorMessagelog: null,
  
  //update basic details / update password data
  isUpdateBasicDetailsData:[],
  
  // update / edit / error handle
  isSubmitting: false,
  isSubmittingPassword: false,
  isSubmittingReg: false,
  errorMessageReg: null,
  errorMessageUpdatePass: null,
  errorMessageBasicDetail: null,
  errorMessageforgotPass: null,

  // modal
  resetPasswordModalOpen: false,

  //user id
  userId: random,

  // countries Currency
  countriesCurrencySelected: "GBP£",

};


const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REGISTER_USER:
      return {
        ...state,
        isRegisterUser: false,
        isAuthenticated: false,
        isSubmittingReg: true,
        errorMessageReg: null,
      };
    case types.REGISTER_USER_SUCCESS:
      return {
        ...state,
        isRegisterUser: true,
        isAuthenticated: true,
        isSubmittingReg: false,
        errorMessageReg: "You have successfully registered.",
      };
    case types.REGISTER_USER_FAILURE:
      return {
        ...state,
        isRegisterUser: false,
        isAuthenticated: false,
        isSubmittingReg: false,
        errorMessageReg: action.payload.error,
      };

    case types.LOGIN_USER:
      return {
        ...state,
        isAuthenticated: false,
        // isAuthenticatedErr: null,
        isSubmitting: true,
        errorMessagelog: null,
      };
    case types.LOGIN_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isSubmitting: false,
      };
    case types.LOGIN_USER_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isSubmitting: false,
        errorMessagelog: action.payload,
      };
    case types.LOGOUT_USER:
      localStorage.removeItem("token");
      localStorage.removeItem("id");
      localStorage.removeItem("user");
      localStorage.removeItem("userId");
      return {
        ...state,
        isAuthenticated: false,
      };

    case types.UPDATE_PASSWORD:
      return {
        ...state,
        isSubmittingPassword: true,
        errorMessageUpdatePass: null,
      };
    case types.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isSubmittingPassword: false,
        errorMessageUpdatePass: "Password has been changed successfully.",
      };
    case types.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        isSubmittingPassword: false,
        errorMessageUpdatePass: action.payload.error,
      };

    case types.UPDATE_BASIC_DETAIL:
      return {
        ...state,
        isSubmitting: true,
        errorMessageBasicDetail: null,
      };
    case types.UPDATE_BASIC_DETAIL_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isUpdateBasicDetailsData: action.payload.result,
        errorMessageBasicDetail: "Personal details has been changed successfully.",
      };
    case types.UPDATE_BASIC_DETAIL_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        errorMessageBasicDetail: action.payload.error,
      };

    case types.FORGOT_PASSWORD:
      return {
        ...state,
        isSubmitting: true,
        errorMessageforgotPass: null,
      };
    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        errorMessageforgotPass: "Send link on your email.",
      };
    case types.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        errorMessageforgotPass: action.payload.error,
      };

    case types.RESET_PASSWORD:
      return {
        ...state,
        isSubmitting: true,
        resetPasswordModalOpen: false,
      };
    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        resetPasswordModalOpen: true,
      };
    case types.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        resetPasswordModalOpen: false,
      };

    default:
      return state;
  }
};

export default AuthReducer;
