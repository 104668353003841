import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const regResultBg = {
  backgroundColor: "#e8e8e8",
  borderTop: "4px solid #0d6efd",
  padding: "20px 0px 10px 16px",
};

function Downloads() {
  return (
    <>
      <Container>
        <Row style={regResultBg}>
          <Col sm={8}>
            <p>No downloads available yet.</p>
          </Col>
          <Col sm={4}>
            <Link to="/shop" className="goSoftbtn">
              Browse product
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Downloads;
