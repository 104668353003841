import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Col, Container, Button } from "react-bootstrap";
import Mycards from "../../Shop/Mycards";

// redux --------------
import { getBestSellerProduct } from "../../../store/Product/action";
import { addToCart } from "../../../store/Cart/action";
import { useDispatch, useSelector } from "react-redux";
// redux end------------
import Loader from "../../../Component/Loader/Loader";
import NoDataFound from "../../../Component/NoDataFound/index";

const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  1024: { items: 3 },
};

function Service() {

  let navigate = useNavigate();

  //redux data--------------
  const dispatch = useDispatch();
  const { currencyItems, currencySelectByUser } = useSelector((state) => state && state.CartReducer);
  const { isWebProductListFetching, bestSellerProduct } = useSelector(
    (state) => state && state.WebProductReducer
  );
  //redux data end----------

  useEffect(() => {
    dispatch(getBestSellerProduct());
  },[]);

  // for send add to cart
  const user_id = localStorage.getItem("userId") ? localStorage.getItem("userId") : "";

  const handleAddToCart = (id) => {
    dispatch(
      addToCart({
        product_id: id,
        qty: 1,
        user_id: user_id,
      })
    );
    // TODO: send to checkout page
    navigate(`${process.env.PUBLIC_URL}/checkout`);
  };

  // for currency change start
  const { gbp } = currencyItems;
  const baseCurrency = gbp / currencySelectByUser?.value
  // for currency change end


  return (
    <>
      <Container className="gosoftware-padding">
        <h1 className="mb-4 home-page-heading">Best Seller Products</h1>
        {isWebProductListFetching ? (
          <p style={{ textAlign: "center", margin: "120px 0" }}>
            {" "}
            <Loader />{" "}
          </p>
        ) : (
          <>
          {bestSellerProduct.length === 0 ? 
          <NoDataFound title="No product found in best seller product"/> : 
          <AliceCarousel
            mouseTracking
            // items={}
            responsive={responsive}
            controlsStrategy="alternate"
          >
            {bestSellerProduct?.map((product, index) => {
              return (
                <Col key={index} sm={11} style={{ textAlign: "center" }}>
                    <Mycards
                      cardLinks={
                        `../product/${product.product_slug}`
                      }
                      product_image={product.product_image}
                      product_image_altName={product.product_name}
                      product_name={product.product_name}
                      mrp={
                        ((product.mrp) / (baseCurrency)).toFixed(2)
                        }
                      sale_price={
                        ((product.sale_price) / (baseCurrency)).toFixed(2)
                        }
                      per={`${(
                        ((product.mrp - product.sale_price) * 100) /
                        product.mrp
                      ).toFixed()}%`}
                      buy_now={
                        // <Link to="/checkout">
                          <Button
                            className="buynow-btn"
                            onClick={() => handleAddToCart(product.id)}
                          >
                            Buy Now
                          </Button>
                        // </Link>
                      }
                    />
                </Col>
              );
            })}
          </AliceCarousel>
        }
          </>
        )}
      </Container>
    </>
  );
}

export default Service;
