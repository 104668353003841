import React from "react";
import "./loader.scss";


function Loader(props){
  return (
    <>
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
    </>
  );
};

export default Loader;
