import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaArrowUp } from "react-icons/fa";


function GoToTop() {

    const [isVisible, setIsVisible] = useState(false);

  const goToBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const listenToScroll = () => {

    let heightToHidden = 250;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHidden) {
        setIsVisible(true);
    } else {
        setIsVisible(false);
    }
  }; 
  
  useEffect(() => {
      window.addEventListener("scroll", listenToScroll);
      return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  return (
    <Wrapper>
        {isVisible && (
            <div className="top-btn" onClick={goToBtn}>
               <FaArrowUp  className="top-btn--icon" />  
            </div>
        )}
  </Wrapper>
  );
};

const Wrapper = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;

    .top-btn {
        font-size: 1.8rem;
        width: 3.5rem;
        height: 3.5rem;
        color: #fff;
        background-color: #0d6efd;
        box-shadow: 0px 0px 10px #f2f2f2;
        border-radius: 50%;
        position: fixed;
        bottom: 3rem;
        right: 3rem;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &--icon {
            animation: goToTop 1.2s linear infinite alternate-reverse;
        }

        @keyframes goToTop {
            0% {
                transform: translateY(-0.5rem);
            }
            100% {
                transform: translateY(1rem);
            }
        }
    }

`;

export default GoToTop;
