import * as types from "./constant"

export const openCategoryModal = () => ({
    type: types.OPEN_CATEGORY_MODAL,
  });
  
export const closeCategoryModal = () => ({
    type: types.CLOSE_CATEGORY_MODAL,
  });


// for get category
export const getCategoryList = (payload) => ({
    type: types.GET_CATEGORY_LIST,
    payload,
});

export const getCategoryListSuccess = (payload) => ({
    type: types.GET_CATEGORY_LIST_SUCCESS,
    payload,
});

export const getCategoryListFailure = (payload) => ({
    type: types.GET_CATEGORY_LIST_FAILURE,
    payload,
});

// Add category

export const addCategory = (payload) => ({
    type: types.ADD_CATEGORY,
    payload,
});

export const addCategorySuccess = (payload) => ({
    type: types.ADD_CATEGORY_SUCCESS,
    payload,
});

export const addCategoryFailure = (payload) => ({
    type: types.ADD_CATEGORY_FAILURE,
    payload,
});

// for admin edit category
export const getEditCategory = (payload) => ({
    type: types.GET_EDIT_CATEGORY,
    payload,
});

export const getEditCategorySuccess = (payload) => ({
    type: types.GET_EDIT_CATEGORY_SUCCESS,
    payload,
});

export const getEditCategoryFailure = (payload) => ({
    type: types.GET_EDIT_CATEGORY_FAILURE,
    payload,
});

// for admin update category
export const updateCategory = (payload) => ({
    type: types.UPDATE_CATEGORY,
    payload,
});

export const updateCategorySuccess = (payload) => ({
    type: types.UPDATE_CATEGORY_SUCCESS,
    payload,
});

export const updateCategoryFailure = (payload) => ({
    type: types.UPDATE_CATEGORY_FAILURE,
    payload,
});


//for admin delete category
export const deleteCategory = (payload) => ({
    type: types.DELETE_CATEGORY,
    payload,
});

export const deleteCategorySuccess = (payload) => ({
    type: types.DELETE_CATEGORY_SUCCESS,
    payload,
});

export const deleteCategoryFailure = (payload) => ({
    type: types.DELETE_CATEGORY_FAILURE,
    payload,
});



// for web category
export const getWebCategoryList = (payload) => ({
    type: types.GET_WEB_CATEGORY_LIST,
    payload,
});

export const getWebCategoryListSuccess = (payload) => ({
    type: types.GET_WEB_CATEGORY_LIST_SUCCESS,
    payload,
});

export const getWebCategoryListFailure = (payload) => ({
    type: types.GET_WEB_CATEGORY_LIST_FAILURE,
    payload,
});

//for get single category slug
export const getSingleCategory = (payload) => ({
    type: types.GET_SINGLE_CATEGORY,
    payload,
});

export const getSingleCategorySuccess = (payload) => ({
    type: types.GET_SINGLE_CATEGORY_SUCCESS,
    payload,
});

export const getSingleCategoryFailure = (payload) => ({
    type: types.GET_SINGLE_CATEGORY_FAILURE,
    payload,
});


// for get category with sub category
export const getCategoryWithSubCategoryList = (payload) => ({
    type: types.GET_CATEGORY_WITH_SUB_CATEGORY_LIST,
    payload,
});
export const getCategoryWithSubCategoryListSuccess = (payload) => ({
    type: types.GET_CATEGORY_WITH_SUB_CATEGORY_LIST_SUCCESS,
    payload,
});
export const getCategoryWithSubCategoryListFailure = (payload) => ({
    type: types.GET_CATEGORY_WITH_SUB_CATEGORY_LIST_FAILURE,
    payload,
});









