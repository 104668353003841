import { takeLatest, put, all, call } from "redux-saga/effects";
import api from "../../utils/api";
import { ADD_TO_CART, CURRENCY_DETAIL, DELETE_FROM_CART, GET_CART, UPDATE_CART } from "./constant";
import { 
        addToCartFailure,
        addToCartSuccess,
        currencyDetailFailure,
        currencyDetailSuccess,
        deleteFromCartFailure,
        deleteFromCartSuccess,
        getCart,
        getCartFailure, 
        getCartSuccess, 
        updateCartFailure, 
        updateCartSuccess 
    } from "./action";




// get cart data
export function* getCartData({payload}){
    try {
        let query = payload ? payload : ""; 
        const response = yield call(api().get, `/cart?user_id=${query}`,);
        if(response) {
            yield put(getCartSuccess(response && response.result));
        }
    } catch(e) {
        yield put(getCartFailure(e));
    }
}


// add to cart data
export function* addToCartData({payload}){
    const userId = localStorage.getItem("userId") ? localStorage.getItem("userId") : "";
    try {
        // let query = payload ? payload : "";  
       const response = yield call(api().post, `/cart/create`, payload);
       if(response) {
        yield put(addToCartSuccess(response));
        yield put(getCart(userId)); 
       }
    } catch(e) {
        yield put(addToCartFailure(e));
    }
}


// update cart data
export function* updateCartData({payload}){
    const userId = localStorage.getItem("userId") ? localStorage.getItem("userId") : "";
    try {
        // let query = payload ? payload : ""; 
        const response = yield call(api().post, `/cart/update`, payload);
        if(response) {
          yield put(updateCartSuccess(response));
          yield put(getCart(userId));
      }
    } catch(e){
        yield put(updateCartFailure(e));
    }
}



// delete Cart Data
export function* deleteCartData({payload}) {
    const userId = localStorage.getItem("userId") ? localStorage.getItem("userId") : "";
    try {
        // let query = payload ? payload : "";
        const response = yield call(api().delete, `/cart/delete/${payload}`,);
        if(response) {
          yield put(deleteFromCartSuccess(response));
          yield put(getCart(userId));
        //   yield put(getCart());
        }
    } catch(e){
        yield put(deleteFromCartFailure(e));
    }
}


// for currency Detail Data
export function* currencyDetailData({payload}) {
    try {
        const response = yield call(api().post, `/currency`, payload);
        if(response){
            yield put(currencyDetailSuccess(response && response.result))
        }
    } catch(e){
        yield put(currencyDetailFailure(e));
    }
}



// get cart flow
export function* getCartFlow() {
    yield takeLatest(GET_CART, getCartData);
}

// Add cart flow
export function* addToCartFlow() {
    yield takeLatest(ADD_TO_CART, addToCartData);
}

// update cart flow
export function* updateCartFlow() {
    yield takeLatest(UPDATE_CART, updateCartData);
}


// delete cart flow
export function* deleteCartFlow() {
    yield takeLatest(DELETE_FROM_CART, deleteCartData);
}


// for currency flow
export function* currencyDetailFlow() {
    yield takeLatest(CURRENCY_DETAIL, currencyDetailData);
}


// cart saga
export default function* CartSaga() {
    yield all([
        getCartFlow(),
        addToCartFlow(),
        updateCartFlow(),
        deleteCartFlow(),
        currencyDetailFlow(),
    ]);
}