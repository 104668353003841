import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Card } from "antd";
import Loader from "../../../Component/Loader/Loader";
import NoDataFound from "../../../Component/NoDataFound/index";
import moment from "moment";
import { getPlacedOrder } from "../../../store/Order/action";
import { useSelector, useDispatch } from "react-redux";


function Orders() {
  
  const dispatch = useDispatch();
  const { isOrderPlacedListFetching, orderPlacedList } = useSelector(
    (state) => state && state.OrderReducer
  );

  useEffect(() => {
    dispatch(getPlacedOrder());
  }, [dispatch]);


  return (
    <>
      {isOrderPlacedListFetching ? (
        <p style={{ textAlign: "center", marginTop: "80px" }}>
          {" "}
          <Loader />{" "}
        </p>
      ) : (
        <>
        {orderPlacedList?.length === 0 ?
        <NoDataFound title="No orders found" /> : 
        <Container>
        {orderPlacedList?.map((item)=>
              <Card key={item.id} className="orderCard">
                <div>
                  <h4>Order #{item.order_id}</h4>
                  <p>Order date: {moment(item.created_at).format("DD/MM/YYYY LT")}</p>
                  <hr className="gosoftwar-hr" />
                  <div className="order-info-responsive">
                    <Row>
                      <Col sm={4}>
                        <img
                          src={item.product.product_image}
                          width="200"
                          alt="Order product"
                        />
                      </Col>
                      <Col sm={8} style={{ marginTop: "1rem" }}>
                        <h6>
                          <strong>
                            {item.product.product_name}
                          </strong>
                        </h6>
                        <span className="text-uppercase">{item.order.currency}{item.order.curr_symbol}{item.sale_price}</span>
                        <hr className="gosoftwar-hr"/>
                        <h6>
                          <span className="text-muted">Qty:</span>{" "}
                          <strong>{item.qty}</strong>
                        </h6>
                        <hr className="gosoftwar-hr"/>
                        <h6>
                          <span className="text-muted">Total amount:</span>{" "}
                          <strong className="text-uppercase">
                            {item.order.currency}{item.order.curr_symbol}
                            {((item.sale_price)*(item.qty)).toFixed(2)}
                          </strong>
                        </h6>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                        <h6 className="text-muted">Product Key:</h6>{" "}
                      <h6>
                        <strong>
                        {item.product_keys?.map((item)=>
                              <h6>
                                <strong>{item.code}</strong>
                              </h6>
                              )}
                        </strong>
                      </h6>
                      <h6>
                        <span className="text-muted">Download Link:</span>{" "}
                        <strong>
                          <a
                              href={item.product.download_link}
                              target="_blank"
                              style={{color: "#000"}}
                              rel="noreferrer"
                          >
                            {item.product.download_link}
                          </a>
                        </strong>
                      </h6>
                    </Row>
                  </div>
                </div>
              </Card>
            )}
        </Container>
      }
      </>
      )}
    </>
  );
}

export default Orders;
