import React from "react";
import { Container } from "react-bootstrap";

function Microsoftpartner() {
  return (
    <>
      <Container>
        <div className="microPart Banner_contant">
          <h6>
            Gosoftware is an authorized Microsoft partner (Partner No. 6285889)
          </h6>
          <p>
            Being a certified Microsoft partner means we resell genuine
            Microsoft products
            <a
              href="https://appsource.microsoft.com/en-gb/marketplace/partner-dir/9ffcbedc-e7f7-4238-aeef-0b5888494edf/overview"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              click here to verify
            </a>
          </p>
        </div>
      </Container>
    </>
  );
}

export default Microsoftpartner;
