import React, { useEffect, useState } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import Loader from "../../../../Component/Loader/Loader";
import NoDataFound from "../../../../Component/NoDataFound/index";
import KeyUpdateModal from "./Component/KeyUpdateModal";
import { PlusOutlined, DeleteOutlined, EditOutlined, FileWordOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Table, Space, Select } from "antd";
import "antd/dist/antd.css";

// for redux start
import {
  getProductKeyList,
  deleteProductKey,
} from "../../../../store/ProductKey/action";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
// for redux end

function ProductKey() {

  // // for modal data start
  const [show, setShow] = useState(false);
  const [keyId, setKeyId] = useState();
  const [proId, setProId] = useState();
  const [keyCode, setKeyCode] = useState();
  const [keyStatus, setKeyStatus] = useState();

  // for redux start...
  const dispatch = useDispatch();
  const { isProductKeyListFetching, productKeyList } =
    useSelector((state) => state.ProductKeyReducer);

    
  useEffect(() => {
    dispatch(getProductKeyList({
      status: keyStatus ? keyStatus : "",
    }));
  },[keyStatus]);
  // for redux end...



  const handleShow = (id, productId, code) => {
    setKeyId(id);
    setProId(productId);
    setKeyCode(code);
    setShow(true);
  };

  const keysData = productKeyList.map((item) => ({
    key: item.id,
    id: item.id,
    productId: item.product.id,
    product_name: item.product.product_name,
    status: item.status,
    code: item.code,
    order_detail_id: item.order_detail_id,
    first_name: item.order_detail?.order.first_name,
    last_name: item.order_detail?.order.last_name,
    country: item.order_detail?.order.country,
  }));

  const columns = [
    {
      title: "Product Key",
      dataIndex: "code",
      render: (_, record) => (
        <>
          <div>{record.code}</div>
          <div>
            {record.order_detail_id ? 
            <Link style={{ color: "#1e5a9b", fontWeight: "600" }} title="View order" to={"/admin/order/" + record.order_detail_id}>Order id: {record.order_detail_id}</Link>:
            ""
            }
          </div>
        </>
      ),
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      render: (_, record) => (
        <>
          <div>{record.product_name}</div>
          <div>
            {record.order_detail_id ? 
            <span><Link style={{ color: "#1e5a9b", fontWeight: "600" }} title="View order" to={"/admin/order/" + record.order_detail_id}>Customer: {record.first_name} {record.last_name} | Country: {record.country}</Link></span>:
            ""
            }
          </div>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Space>
          {status === "pending" ? (
            <span className="text-success text-capitalize">{status === "pending" ? "Available" : ""}</span>
          ) : (
            <span className="text-danger text-capitalize">{status}</span>
          )}
        </Space>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      // align: 'center',
      render: (_, record) => (
        <Space>
        {(record.status === "sold") ? "" : 
          <>
          <Button
            type="primary"
            title="Edit"
            style={{ padding: "4px 8px" }}
            onClick={() => handleShow(record.id, record.productId, record.code)}
          >
            {" "}
            <strong>
              {" "}
              <EditOutlined
                style={{
                  fontWeight: "900",
                  fontSize: "16px",
                  verticalAlign: "middle",
                }}
              />{" "}
            </strong>{" "}
          </Button>

          <Popconfirm
            title="Are you sure want to delete ?"
            key={record.id}
            onConfirm={() => dispatch(deleteProductKey(record.id))}
          >
            <Button
              danger
              type="primary"
              title="Delete"
              style={{ padding: "4px 8px" }}
            >
              {" "}
              <strong>
                {" "}
                <DeleteOutlined
                  style={{
                    fontWeight: "900",
                    fontSize: "16px",
                    verticalAlign: "middle",
                  }}
                />{" "}
              </strong>{" "}
            </Button>
          </Popconfirm>
        </>
        }
        </Space>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Gosoftware - Product Keys</title>
      </Helmet>
     
      <div className="admin-product-key-page">
      
        {show &&
        <KeyUpdateModal
          show={show}
          setShow={setShow}
          keyId={keyId}
          proId={proId}
          keyCode={keyCode}
        />
       }

        <div className="admin-product-key-page-data">
          <div className="admin-product-key-page-title">
            <h4>Product Keys</h4>
            <div className="col-sm-3 col-xs-12">
            <Space>
              <Select
                allowClear
                placeholder="Select a status"
                type="text"
                style={{
                  width: 230,
                }}
                value={keyStatus}
                onChange={(value) => setKeyStatus(value)}
              >
                    <option value="sold">Sold</option>
                    <option value="pending">Available</option>
              </Select>
             </Space>
            </div>

            <Button className="import_keys_btn">
              <Link to="/admin/product-key/import-key">
                <FileWordOutlined /> Import Keys
              </Link>
            </Button>

            <Link to="/admin/add-product-key" className="admin-page-button">
              {" "}
              <PlusOutlined /> Add Product Key
            </Link>
          </div>
          <div className="admin-product-key">
            {isProductKeyListFetching ? (
              <p style={{ textAlign: "center", margin: "160px 0" }}>
                {" "}
                <Loader />{" "}
              </p>
            ) : (
              <>
              {productKeyList?.length === 0 ?
              <NoDataFound title="No product keys found" /> : 
              <Table
                // bordered
                className="ant-table-admin"
                dataSource={keysData}
                columns={columns}
                // loading={loading}
              />
              }
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductKey;
