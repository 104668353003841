import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { Form, Row, Col, Button, InputGroup, Spinner } from "react-bootstrap";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai"

import { loginUser } from "../../../store/Auth/action";
import { useDispatch, useSelector } from "react-redux";


function Login() {

  const [type, setType] = useState("password");
  const [eyeIcon, setEyeIcon] = useState(<AiOutlineEyeInvisible />);
  const dispatch = useDispatch();

  const { isAuthenticated, errorMessagelog, isSubmitting } = useSelector((state)=>state && state.AuthReducer);
  

  const validationSchema = yup.object().shape({
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  // setMyToken(false);
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : "";

    const { roles } = user
    const userName = roles ? roles[0].name : ""

    useEffect(() => {
      if(userName === "admin"){
        navigate("/admin");
      } else if(userName === "customer"){
        navigate("/shop");
      }
    },[isAuthenticated, userName])


  const onSubmit = () => {
    // console.log("values", values);
    let formData = new FormData();
    formData.append("email", values.email);
    formData.append("password", values.password);

    dispatch(loginUser(formData));

  };

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit,
  });

  const passwordView = () => {
    if(type === "password"){
      setType("text");
      setEyeIcon(<AiOutlineEye />);
    }else{
      setType("password");
      setEyeIcon(<AiOutlineEyeInvisible />);
    }
  }


  return (
    <>

      <div className="login-box">
        <h2 style={{ textAlign: "center" }}>Login</h2>
        <div className="login-box-form">
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group >
                <Form.Label>
                  Username or email<span className="text-danger">*</span>
                </Form.Label>
              
                <Form.Control
                  type="email"
                  placeholder=""
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email && touched.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>
                  Password<span className="text-danger">*</span>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type={type}
                    placeholder=""
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={!!errors.password && touched.password}
                  />
                  <InputGroup.Text 
                    onClick={passwordView} 
                    className="icon-btn"
                  > 
                    {eyeIcon}
                  </InputGroup.Text>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <div className="d-grid mt-2">
              <Button 
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting && (
                  <Spinner animation="border" size="sm" as="span" style={{marginRight: "2px"}} />
                )}
                Login
              </Button>
            </div>
          </Form>
          { errorMessagelog &&
              <p className="error-message">{errorMessagelog}</p>
          }
          <div className="mt-4">
            <Link to="/lost-password">
              <h6>Lost your password?</h6>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
