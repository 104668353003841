import React, { useState, useEffect, useRef } from 'react';
import "../style.scss";
import {Link, useParams} from "react-router-dom";
import {LeftOutlined} from "@ant-design/icons";
import {useFormik} from "formik";
import * as yup from "yup";
import {Helmet} from 'react-helmet';
import {Form, Row, Col, Button, Spinner} from "react-bootstrap";
import PreviewImage from '../Component/PreviewImage';
import Loader from '../../../../../Component/Loader/Loader';

// start redux
import {getEditCategory, updateCategory} from "../../../../../store/Category/action";
import {useDispatch, useSelector, connect} from "react-redux";
import {compose} from "redux";

// end redux data


function EditCategory({updateSaveCategory}) {

    const [visible, setIsVisible] = useState(false);
    let params = useParams();

    const fileRef = useRef(null);

    const validationSchema = yup.object().shape({
        category_name: yup.string().required("Category name is required."),
        order_no: yup.string().required("Order number is required."),
        image: yup.mixed(),
        category_slug: yup.string().required("Category slug is required."),
        seo_title: yup.string().required("SEO title is required"),
        seo_description: yup.string().required("SEO description is required"),
    });

    // redux data
    const dispatch = useDispatch();
    const {
        isCategoryListFetching,
        isSubmitting,
        categorySubmitingErrr,
        editCategory
    } = useSelector((state) => state && state.CategoryReducer);

    const {category_name, order_no, category_slug, seo_title, seo_description} = editCategory;

    useEffect(() => {
        dispatch(getEditCategory(params.id));
    }, [params.id])
    //redux data start

    const onSubmit = () => {
        // console.log("category data values", values);
        const formData = new FormData();
        if(values.image) {
            formData.append("image", values.image);
        }
        formData.append("category_name", values.category_name);
        formData.append("order_no", values.order_no);
        formData.append("category_slug", values.category_slug);
        formData.append("seo_title", values.seo_title);
        formData.append("seo_description", values.seo_description);

        updateSaveCategory({
            id: params.id,
            formData: formData
        });

        // for error message start
            setIsVisible(true);
        // for error message end
        setTimeout(function() {
            setIsVisible(false);
        }, 8000);
    }

    const {
        handleSubmit,
        handleChange,
        values,
        setFieldValue,
        touched,
        errors,
    } = useFormik({
        initialValues: {
            category_name: category_name ? category_name : "",
            order_no: order_no ? order_no : "",
            image: null,
            category_slug: category_slug ? category_slug : "",
            seo_title: seo_title ? seo_title : "",
            seo_description: seo_description ? seo_description : "",
        },
        validateOnBlur: true,
        validationSchema,
        onSubmit,
        enableReinitialize: true,
    });



    return (
        <>

            <Helmet>
                <title>Gosoftware - Edit Category</title>
            </Helmet>

            <div className='admin-category-page'>

                <div className="admin-category-page-data">
                    <div className='admin-category-page-title'>
                        <h4>Edit Category</h4>
                        <Link to="/admin/category" className="admin-page-button"> <LeftOutlined/> Back</Link>
                    </div>

                    <div className='admin-category' style={{ padding: "1rem 2.2rem" }}>

                        {isCategoryListFetching ? (<p style={{textAlign: "center", margin: "160px 0"}}><Loader/></p>) : (
                                <>
                                <div>
                                    {isSubmitting ? "" :
                                        <>
                                        {visible && (
                                            <>
                                            {categorySubmitingErrr === "Category has been updated successfully." ?
                                                <p className="error-message-success">*{categorySubmitingErrr}</p> :
                                                <p className="error-message">*{categorySubmitingErrr}</p>
                                            }
                                            </>
                                        )}
                                        </>
                                    }
                                </div>

                            <Form
                                noValidate
                                onSubmit={handleSubmit}
                                encType="multipart/form-data"
                                className="ant-table-admin"
                                style={{ padding: "2.2rem" }}
                            >

                                <Row className="mb-3">
                                    <Form.Group as={Col}>
                                        <Form.Label>Category name<span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="category_name"
                                            // value={category_name}
                                            defaultValue={category_name}
                                            onChange={handleChange}
                                            isInvalid={touched.category_name && errors.category_name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.category_name}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col}>
                                        <Form.Label>Order No.<span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="order_no"
                                            // value={order_no}
                                            defaultValue={order_no}
                                            // setFieldValue={order_no}
                                            onChange={handleChange}
                                            isInvalid={touched.order_no && errors.order_no}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.order_no}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row className='mb-3'>
                                    <Col>
                                        <Form.Group>
                                            {/* <Form.Label>Category Image</Form.Label> */}
                                            <Form.Label htmlFor='category_image_id'>
                                                <Button variant="contained" color="primary" component="span"
                                                        onClick={() => fileRef.current.click()}
                                                        className="category-image-button"
                                                >
                                                    Upload banner
                                                </Button>
                                            </Form.Label>

                                            <Form.Control
                                                ref={fileRef}
                                                id="category_image_id"
                                                hidden
                                                type="file"
                                                // required
                                                name="image"
                                                accept="image/*"
                                                onChange={(event) => {
                                                    setFieldValue("image", event.target.files[0]);
                                                }}
                                                isInvalid={!!errors.image}
                                            />


                                            <Form.Control.Feedback type="invalid">
                                                {errors.image}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col>
                                        <div>
                                            {editCategory?.image ? 
                                            <img
                                                src={editCategory?.image}
                                                height="120px"
                                                alt="product"
                                            /> :
                                            ""
                                            }
                                            {values.image && <PreviewImage file={values.image}/>}

                                        </div>
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Form.Group as={Col}>
                                        <Form.Label>Category slug<span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="category_slug"
                                            defaultValue={category_slug}
                                            onChange={handleChange}
                                            isInvalid={!!errors.category_slug && touched.category_slug}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.category_slug}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row className='mb-3'>
                                    <h5>SEO</h5>
                                    <Form.Group as={Col}>
                                        <Form.Label>Title<span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="seo_title"
                                            defaultValue={seo_title}
                                            onChange={handleChange}
                                            isInvalid={!!errors.seo_title && touched.seo_title}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.seo_title}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row className='mb-3'>
                                    <Form.Group as={Col}>
                                        <Form.Label>Meta description<span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="seo_description"
                                            defaultValue={seo_description}
                                            onChange={handleChange}
                                            isInvalid={!!errors.seo_description && touched.seo_description}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.seo_description}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <div>
                                    <Button type="submit" className='subFormButton' size="lg" disabled={isSubmitting}>
                                        {isSubmitting &&
                                            (<Spinner animation="border" size='sm' as="span"/>
                                            )}
                                        Submit </Button>
                                </div>

                            </Form>
                            </>
                            )}

                    </div>
                </div>
            </div>
        </>
    );
}


// export default EditCategory;


function mapDispatchToProps(dispatch) {
    return {
        updateSaveCategory: (data) => dispatch(updateCategory(data)),
    }
}

const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(EditCategory);
