import React from "react";
import "./style.scss";
import { Container, Row, Col } from "react-bootstrap";
import Helmet from "react-helmet";
import { AiOutlineMail } from "react-icons/ai";
import { FiMapPin } from "react-icons/fi";
import { BiTimeFive } from "react-icons/bi";
import ContactForm from "./Component/ContactForm";


function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact us - Gosoftware</title>
        <meta
          name="description"
          content="If you have any questions or need help please contact us via email, livechat or send us a message."
        />
      </Helmet>

      <Container className="gosoftware-padding">
        <div style={{ textAlign: "center" }} className="mb-2">
          <h2>Contact us</h2>
        </div>

        <Row>
          <Col>
            <div className="contact-information">
              <h6>
                <AiOutlineMail /> Support@gosoftwarebuy.com
              </h6>
            </div>
          </Col>

          <Col>
            <div className="contact-information">
              <h6>
                <span>
                  <FiMapPin />
                </span>
                136-140 Old Shoreham Road Brighton, United Kingdom BN3 7BD
              </h6>
            </div>
          </Col>

          <Col>
            <div className="contact-information">
              <h6>Monday-Friday</h6>
              <h6>
                <span>
                  <BiTimeFive />
                </span>
                10:00 AM- 7:00 PM
              </h6>
              <h6>Saturday-Sunday</h6>
              <h6>
                <span>
                  <BiTimeFive />
                </span>
                11:00 AM- 2:00 PM
              </h6>
            </div>
          </Col>
        </Row>

        <Row>
          <Col><ContactForm /></Col>
        </Row>
      </Container>
    </>
  );
}

export default Contact;
