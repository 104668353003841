import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import { updateBasicDetail } from "../../../../store/Auth/action";
import { useDispatch, useSelector } from "react-redux";

function PersonalDetailWeb() {

  const [visible, setIsVisible] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const {
    isSubmitting,
    errorMessageBasicDetail,
    isUpdateBasicDetailsData,
  } = useSelector((state) => state && state.AuthReducer);

  const user = JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : "";

  useEffect(() => {
    setName(
      isUpdateBasicDetailsData?.name
        ? isUpdateBasicDetailsData?.name
        : user?.name
    );
    setEmail(
      isUpdateBasicDetailsData?.email
        ? isUpdateBasicDetailsData?.email
        : user?.email
    );
  }, [isSubmitting]);

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is a required."),
    email: yup.string().required("Email address is a required."),
  });

  const onSubmit = () => {
    // console.log("values", values);

    let formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);

    dispatch(updateBasicDetail(formData));

    // for error message start
      setIsVisible(true);
    // for error message end
    setTimeout(function () {
      setIsVisible(false);
    }, 8000);
  };

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      name: name ? name : "",
      email: email ? email : "",
    },
    validateOnBlur: true,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <h6>Personal detail</h6>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="validationFormik01">
            <Form.Label>
              Display name<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              defaultValue={name}
              onChange={handleChange}
              isInvalid={!!errors.name && touched.name}
            />
            <Form.Text muted>
              This will be how your name will be displayed in the account
              section and in reviews.
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="validationFormik02">
            <Form.Label>
              Email address<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              name="email"
              defaultValue={email}
              onChange={handleChange}
              isInvalid={!!errors.email && touched.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Button type="submit" className="mt-4" disabled={isSubmitting}>
          {isSubmitting && <Spinner animation="border" size="sm" as="span" />}
          Save Changes
        </Button>
      </Form>

      {isSubmitting ? (
        ""
      ) : (
        <>
          {visible && (
            <>
              {errorMessageBasicDetail ===
              "Personal details has been changed successfully." ? (
                <p className="error-message-success">
                  *{errorMessageBasicDetail}
                </p>
              ) : (
                <p className="error-message">*{errorMessageBasicDetail}</p>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default PersonalDetailWeb;
