import { takeLatest, put, all, call } from "redux-saga/effects";
import api from "../../utils/api"
import { addSubCategoryFailure, addSubCategorySuccess, deleteSubCategoryFailure, deleteSubCategorySuccess, getEditSubCategoryFailure, getEditSubCategorySuccess, getProductsBySubCategorySlugFailure, getProductsBySubCategorySlugSuccess, getSubCategoryByCategoryIdFailure, getSubCategoryByCategoryIdSuccess, getSubCategoryList, getSubCategoryListFailure, getSubCategoryListSuccess, updateSubCategoryFailure, updateSubCategorySuccess } from "./action";
import { ADD_SUB_CATEGORY, DELETE_SUB_CATEGORY, GET_EDIT_SUB_CATEGORY, GET_PRODUCTS_BY_SUB_CATEGORY_SLUG, GET_SUB_CATEGORY_BY_CATEGORY_ID, GET_SUB_CATEGORY_LIST, UPDATE_SUB_CATEGORY } from "./constant";


// get sub category list saga
export function* fetchSubCategoryList({ payload }){
    const token = localStorage.getItem("token");
    try {
        let query = payload ? payload : ""
        const response = yield call(api(token).get, `/admin/subcategory${query}`,);
        if(response) {
            yield put(getSubCategoryListSuccess(response && response.result));
        }
    } catch (e) {
        yield put(getSubCategoryListFailure(e));
    }
}


// add sub category saga
export function* addSubCategoryData({ payload }){
    const token = localStorage.getItem("token");
    try {
        const response = yield call(api(token).post, `/admin/subcategory/create`, payload);
        if(response){
            yield put(addSubCategorySuccess(response));
            yield put(getSubCategoryList());
        }
    } catch (e) {
        yield put(addSubCategoryFailure(e));
    }
}

// get edit sub category
export function* fetchEditSubCategory({ payload }){
    const token = localStorage.getItem("token");
    try {
        let query = payload ? payload : ''
        const response = yield call(api(token).get, `/admin/subcategory/${query}`,);
        if(response){
            yield put(getEditSubCategorySuccess(response && response.result));
        }
    } catch (e) {
        yield put(getEditSubCategoryFailure(e));
    }
}

// for update sub category
export function* updateSubCategoryData({ payload }){
    const token = localStorage.getItem("token");
    try {
        const response = yield call(api(token).post, `/admin/subcategory/update/${payload.id}`, payload.formData);
        if(response){
            yield put(updateSubCategorySuccess(response));
            yield put(getSubCategoryList());
        }
    } catch (e) {
        yield put(updateSubCategoryFailure(e));
    }
}

// for delete sub category data
export function* deleteSubCategoryData({ payload }){
    const token = localStorage.getItem("token");
    try {
        const response = yield call(api(token).delete, `/admin/subcategory/delete/${payload}`,);
        if(response){
            yield put(deleteSubCategorySuccess(response));
            yield put(getSubCategoryList());
        }
    } catch (e) {
        yield put(deleteSubCategoryFailure(e));
    }
} 

// for web get sub category by category id
export function* webGetSubCategoryByCategoryIdData({payload}){
    try {
        let query = payload ? payload:''
        const response = yield call(api().get, `/subcategory/list/${query}`,);
        if(response) {
            yield put(getSubCategoryByCategoryIdSuccess(response && response.result));
        }
    } catch (e) {
        yield put(getSubCategoryByCategoryIdFailure(e));
    }
}

// for web get products by sub category slug
export function* fetchProductsBySubCategorySlugData({payload}){
    try {
        let query = payload ? payload:''
        const response = yield call(api().get, `/subcategory/${query}`,);
        if(response){
            yield put(getProductsBySubCategorySlugSuccess(response && response.result));
        }
    } catch (e) {
        yield put(getProductsBySubCategorySlugFailure(e));
    }
}


// get sub category saga flow
export function* fetchSubCategoryListFlow(){
    yield takeLatest(GET_SUB_CATEGORY_LIST, fetchSubCategoryList);
}

// add sub category saga flow
export function* addSubCategoryFlow(){
    yield takeLatest(ADD_SUB_CATEGORY, addSubCategoryData);
}

// for get edit sub category flow
export function* fetchEditSubCategoryFlow(){
    yield takeLatest(GET_EDIT_SUB_CATEGORY, fetchEditSubCategory);
}

// for update sub category flow
export function* updateSubCategoryFlow(){
    yield takeLatest(UPDATE_SUB_CATEGORY, updateSubCategoryData);
}

// for delete sub category saga flow
export function* deleteSubCategoryFlow(){
    yield takeLatest(DELETE_SUB_CATEGORY, deleteSubCategoryData);
}

// for web get sub category by category id
export function* webGetSubCategoryByCategoryIdFlow(){
    yield takeLatest(GET_SUB_CATEGORY_BY_CATEGORY_ID, webGetSubCategoryByCategoryIdData);
}

// for web get products by sub category slug
export function* fetchProductsBySubCategorySlugFlow(){
    yield takeLatest(GET_PRODUCTS_BY_SUB_CATEGORY_SLUG, fetchProductsBySubCategorySlugData);
}

export default function* SubCategorySaga() {
    yield all([
        fetchSubCategoryListFlow(),
        addSubCategoryFlow(),
        fetchEditSubCategoryFlow(),
        updateSubCategoryFlow(),
        deleteSubCategoryFlow(),
        webGetSubCategoryByCategoryIdFlow(),
        fetchProductsBySubCategorySlugFlow(),
    ]);
}