import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import MyRoutes from './Routes/index';
import ScrollToTop from './ScrollToTop';


function App() {
    return (
        <>
            <BrowserRouter>
                <ScrollToTop />
                <MyRoutes />
            </BrowserRouter> 
            
        </>
    );
};

export default App;
