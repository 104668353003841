import React from "react";
import { Helmet } from "react-helmet";
import Login from "./LogIn/index";
import Register from "./Register/index";
import { Container, Row, Col } from "react-bootstrap";

function Account() {
  return (
    <>
      <Helmet>
        <title>My Account - Gosoftware</title>
      </Helmet>

      <Container className="gosoftware-padding">
        <Row>
          <Col sm={6}>
            <Login />
          </Col>

          <Col sm={6}>
            <Register />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Account;
