import { takeLatest, put, all, call } from "redux-saga/effects";
import api from "../../utils/api";
import { LOGIN_USER, REGISTER_USER, UPDATE_PASSWORD, UPDATE_BASIC_DETAIL, FORGOT_PASSWORD, RESET_PASSWORD } from "./constant";
import { forgotPasswordFailure, forgotPasswordSuccess, loginUserFailure, loginUserSuccess, registerUserFailure, registerUserSuccess, resetPasswordFailure, resetPasswordSuccess, updateBasicDetailFailure, updateBasicDetailSuccess, updatePasswordFailure, updatePasswordSuccess } from "./action";


// for get regiter user
export function* getRegisterUser({payload}) {
    try {
        const response = yield call(api().post, `/register`, payload);
        let token = response.result.token
        const id = response.result.id
        localStorage.setItem("token", token);
        localStorage.setItem("id", id);
        if (response) {
            localStorage.setItem("user", JSON.stringify(response.result));
            yield put(registerUserSuccess(response));
        }
    } catch (e) {
       yield put(registerUserFailure(e));
    }
}


// for get login
export function* getLoginUser({payload}) {
     try {
        const response = yield call(api().post, `/login`, payload);

        // console.log("response",response)
        
         if(!response.error){
             let token = response.result.token
             const id = response.result.id
             localStorage.setItem("token", token);
             localStorage.setItem("id", id);
            localStorage.setItem("user", JSON.stringify(response.result));
            yield put(loginUserSuccess(response));
        }else{
             yield put(loginUserFailure(response.message));
         }
    } catch (e) {
        yield put(loginUserFailure(e.error));
    }
}

// for update password
export function* updatePasswordData({payload}) {
    const token = localStorage.getItem("token");
    try {
       const response = yield call(api(token).post, `/password/update`, payload);
       if (response) {
        yield put(updatePasswordSuccess(response));
       }
    } catch (e) {
        yield put(updatePasswordFailure(e));
    }
}

// for update basic detail
export function* updateBasicDetailData({payload}){
    const token = localStorage.getItem("token");
    try {
       const response = yield call(api(token).post, `/account/update`, payload);
       if (response) {
        yield put(updateBasicDetailSuccess(response));
       }
    } catch (e) {
       yield put(updateBasicDetailFailure(e));
    }
}

// forgot Password Data
export function* forgotPasswordData({payload}){
    try {
        // let query = payload ? payload: ""
        // console.log("query", query);
       const response = yield call(api().post, `/password/forgot`, payload);
       if (response) {
        yield put(forgotPasswordSuccess(response));
       }
    } catch (e) {
        yield put(forgotPasswordFailure(e));
    }
}

// reset Password
export function* resetPasswordData({payload}){
    try {
        const response = yield call(api().post, `/password/reset`, payload);
        if (response) {
            yield put(resetPasswordSuccess(response));
        }
    } catch (e) {
        yield put(resetPasswordFailure(e));
    }
}



// for register user flow
export function* getRegisterUserFlow() {
    yield takeLatest(REGISTER_USER, getRegisterUser);
}

// for get Login Flow
export function* getLoginFlow() {
    yield takeLatest(LOGIN_USER, getLoginUser);
}


// for update password flow
export function* updatePasswordFlow() {
    yield takeLatest(UPDATE_PASSWORD, updatePasswordData);
}

// update basic detail
export function* updateBasicDetailFlow(){
    yield takeLatest(UPDATE_BASIC_DETAIL, updateBasicDetailData);
}

// forgot password
export function* forgotPasswordFlow(){
    yield takeLatest(FORGOT_PASSWORD, forgotPasswordData);
}

// reset password
export function* resetPasswordFlow(){
    yield takeLatest(RESET_PASSWORD, resetPasswordData);
}

export default function* AuthSaga() {
    yield all([
        getLoginFlow(),
        getRegisterUserFlow(),
        updatePasswordFlow(),
        updateBasicDetailFlow(),
        forgotPasswordFlow(),
        resetPasswordFlow(),
    ]);
}