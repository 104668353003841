import * as types from "./constant";


// for get cart
export const getCart = (payload) => ({
    type: types.GET_CART,
    payload,
});
export const getCartSuccess = (payload) => ({
    type: types.GET_CART_SUCCESS,
    payload,
});
export const getCartFailure = (payload) => ({
    type: types.GET_CART_FAILURE,
    payload,
});



// for add to cart
export const addToCart = (payload) => ({
    type: types.ADD_TO_CART,
    payload,
});
export const addToCartSuccess = (payload) => ({
    type: types.ADD_TO_CART_SUCCESS,
    payload,
});
export const addToCartFailure = (payload) => ({
    type: types.ADD_TO_CART_FAILURE,
    payload,
});


// update cart
export const updateCart = (payload) => ({
    type: types.UPDATE_CART,
    payload,
});
export const updateCartSuccess = (payload) => ({
    type: types.UPDATE_CART_SUCCESS,
    payload,
});
export const updateCartFailure = (payload) => ({
    type: types.UPDATE_CART_FAILURE,
    payload,
});


// delete cart
export const deleteFromCart = (payload) => ({
    type: types.DELETE_FROM_CART,
    payload,
});
export const deleteFromCartSuccess = (payload) => ({
    type: types.DELETE_FROM_CART_SUCCESS,
    payload,
});
export const deleteFromCartFailure = (payload) => ({
    type: types.DELETE_FROM_CART_FAILURE,
    payload,
});



// for currency
export const currencyDetail = (payload) => ({
    type: types.CURRENCY_DETAIL,
    payload,
});
export const currencyDetailSuccess = (payload) => ({
    type: types.CURRENCY_DETAIL_SUCCESS,
    payload,
});
export const currencyDetailFailure = (payload) => ({
    type: types.CURRENCY_DETAIL_FAILURE,
    payload,
});

// for currency change
export const currencyChange = (payload) => ({
    type: types.CURRENCY_CHANGE,
    payload,
});


