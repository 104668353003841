import React from "react";
import "./style.scss";
// import Featuredinfo from "./Component/Featuredinfo";

function Dashboard() {
  return (
    <>
      <div className="admin-home">
        <div className="dashboardData">
          <h4 style={{ marginLeft: "1rem" }}>Welcome to admin panel.</h4>
          {/* <Featuredinfo /> */}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
