import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { updateCart } from "../../../store/Cart/action";
import { useDispatch } from "react-redux";

function CartDataItem({ value, id }) {
  
  const [count, setCount] = useState(value);
  const [countChangeValue, setCountChangeValue] = useState();

  useEffect(()=> {
    setCountChangeValue(false);
  },[countChangeValue])

  // redux
  const dispatch = useDispatch();

  useEffect(()=> {
    if((countChangeValue === true) && (count > 0 )){
      dispatch(updateCart({ id: id, qty: count }));
    }
  },[count])
  
  
  const countChange = (event) => {
    setCount(event.target.value);
  };

  const countInc = () => {
    //    console.log();
    if (count < 20) {
      setCount(Number(count) + 1);
    }
    setCountChangeValue(true);
  };

  const countDesc = () => {
    if (count > 1) {
      setCount(count - 1);
    }
    setCountChangeValue(true);
  };

  return (
    <>
      <Form.Group className="d-flex col-md-6 m-auto" style={{ border: "1px solid #d5d5d5", boxShadow: "1px 1px 8px #e5e5e5" }}>
        <div className="input-group-prepend">
          <button
            className="btn IncrDecrStyle"
            type="button"
            onClick={countDesc}
          >
            -
          </button>
        </div>
        <Form.Control
          type="text"
          className="form-control IncrDecrStyle"
          name="qty"
          value={count}
          onChange={(event) => countChange(event)}
        />
        <div className="input-group-prepend">
          <button
            className="btn IncrDecrStyle"
            type="button"
            onClick={countInc}
          >
            +
          </button>
        </div>
      </Form.Group>
    </>
  );
}

export default CartDataItem;
