import React from "react";
import { Container } from "react-bootstrap";


function Addresses() {
    return(
        <>
            <Container>
                <p>The following addresses will be used on the checkout page by default.</p>
                <h4>Billing address</h4>
                <h4>Add</h4>
                <p>You have not set up this type of address yet.</p>
            </Container>
        </>
    );
}

export default Addresses;