import React from "react";
import Accordion from "react-bootstrap/Accordion";

function FrequentlyAskedQuestions() {
  return (
    <>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0" className="product-questions">
          <Accordion.Header><h6 className="mb-0">What is a Digital Download?</h6></Accordion.Header>
          <Accordion.Body>
            A digital download is described as a product which will be delivered
            to you through the internet, most commonly through email, giving you
            access to download the product you have purchased immediately.
            Gosoftware will not dispatch and physical DVD or CD. Your software
            will be sent within minutes to the email address provided during the
            order process.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="product-questions">
          <Accordion.Header>
            <h6 className="mb-0">How soon after purchase will I receive my software?</h6>
          </Accordion.Header>
          <Accordion.Body>
            As soon as your payment has been successfully processed you will be
            able to instantly download. You will also receive a download link in
            a confirmation email to the email address provided.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2" className="product-questions">
          <Accordion.Header>
          <h6 className="mb-0">How can I be certain that my copy of Microsoft Office is legitimate?</h6>
          </Accordion.Header>
          <Accordion.Body>
            Gosoftware encourages all customers to register their email address
            with their specific Microsoft Product Key through the Microsoft
            online activation to ensure verification and tracking of their
            authentic software. By doing this you will know the Microsoft Office
            product you have purchased is 100% legitimate.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3" className="product-questions">
          <Accordion.Header>
          <h6 className="mb-0">How are you able to sell software for such low prices?</h6>
          </Accordion.Header>
          <Accordion.Body>
            We work directly with a variety of suppliers to purchase large
            volumes to get the lowest prices possible, as we save on costs we
            want to ensure our customers save too. As an online store we have
            minimal overheads which is also another reason we can offer the
            product at a reasonable price.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4" className="product-questions">
          <Accordion.Header>
          <h6 className="mb-0">Can I buy one licensed software and install it on multiple
            computers.</h6>
          </Accordion.Header>
          <Accordion.Body>
            No. Retail Product software licenses are designed to install and
            active on one computer only. We do however offer bulk deals on all
            our products.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5" className="product-questions">
          <Accordion.Header>
          <h6 className="mb-0">Do you supply your products in large wholesale or bulk orders?</h6>
          </Accordion.Header>
          <Accordion.Body>
            Yes. Bulk orders can be processed by contacting us directly through
            live chat or emailing our support team at order@gosoftwarellp.com
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

export default FrequentlyAskedQuestions;
