import React from "react";
import { Helmet } from "react-helmet";
import "./style.scss";
import About from "./Component/About";
import Service from "./Component/Servicess";
import Work from "./Component/Work";
import Microsoftpartner from "../../Component/MicrosoftPartner/Microsoftpartner";
import GosoftwareSlider from "./Component/GosoftwareSlider";


function Home() {
  return (
    <>
      <Helmet>
        <title>Gosoftware - Buy Software Online At Best Deals</title>
        <meta
          name="description"
          content="Buy Antivirus and Microsoft Office License Keys online. The best way to buy software online and we provide 100% genuine product keys."
        />
      </Helmet>

      <GosoftwareSlider />

      <Microsoftpartner />

      <Service />

      <Work />

      <About />
    </>
  );
}

export default Home;
