import * as types from "./constant";

export const getProductList = (payload) => ({
    type: types.GET_PRODUCT_LIST,
    payload,
});

export const getProductListSuccess = (payload) => ({
    type: types.GET_PRODUCT_LIST_SUCCESS,
    payload,
});

export const getProductListFailure = (payload) => ({
    type: types.GET_PRODUCT_LIST_FAILURE,
    payload,
});

// add product
export const addProduct = (payload) => ({
    type: types.ADD_PRODUCT,
    payload,
});

export const addProductSuccess = (payload) => ({
    type: types.ADD_PRODUCT_SUCCESS,
    payload,
});

export const addProductFailure = (payload) => ({
    type: types.ADD_PRODUCT_FAILURE,
    payload,
});


// for admin edit product
export const getEditProduct = (payload) => ({
    type: types.GET_EDIT_PRODUCT,
    payload,
});

export const getEditProductSuccess = (payload) => ({
    type: types.GET_EDIT_PRODUCT_SUCCESS,
    payload,
});

export const getEditProductFailure = (payload) => ({
    type: types.GET_EDIT_PRODUCT_FAILURE,
    payload,
});


// for admin update product 
export const updateProduct = (payload) => ({
    type: types.UPDATE_PRODUCT,
    payload,
});

export const updateProductSuccess = (payload) => ({
    type: types.UPDATE_PRODUCT_SUCCESS,
    payload,
});

export const updateProductFailure = (payload) => ({
    type: types.UPDATE_PRODUCT_FAILURE,
    payload,
});


// for admin delete product
export const deleteProduct = (payload) => ({
    type: types.DELETE_PRODUCT,
    payload,
});

export const deleteProductSuccess = (payload) => ({
    type: types.DELETE_PRODUCT_SUCCESS,
    payload,
});

export const deleteProductFailure = (payload) => ({
    type: types.DELETE_PRODUCT_FAILURE,
    payload,
});



// for web product
export const getWebProductList = (payload) => ({
    type: types.GET_WEB_PRODUCT_LIST,
    payload,
});

export const getWebProductListSuccess = (payload) => ({
    type: types.GET_WEB_PRODUCT_LIST_SUCCESS,
    payload
});

export const getWebProductListFailure = (payload) => ({
    type: types.GET_WEB_PRODUCT_LIST_FAILURE,
    payload
});



// for single product from slug
export const getSingleProduct = (payload) => ({
    type: types.GET_SINGLE_PRODUCT,
    payload,
});

export const getSingleProductSuccess = (payload) => ({
    type: types.GET_SINGLE_PRODUCT_SUCCESS,
    payload,
});

export const getSingleProductFailure = (payload) => ({
    type: types.GET_SINGLE_PRODUCT_FAILURE,
    payload,
});


// get product by search web
export const getProductBySearch = (payload) => ({
    type: types.GET_PRODUCT_BY_SEARCH,
    payload,
});

export const getProductBySearchSuccess = (payload) => ({
    type: types.GET_PRODUCT_BY_SEARCH_SUCCESS,
    payload,
});

export const getProductBySearchFailure = (payload) => ({
    type: types.GET_PRODUCT_BY_SEARCH_FAILURE,
    payload,
});


// get best seller for web
export const getBestSellerProduct = (payload) => ({
    type: types.GET_BEST_SELLER_PRODUCT,
    payload,
});
export const getBestSellerProductSuccess = (payload) => ({
    type: types.GET_BEST_SELLER_PRODUCT_SUCCESS,
    payload,
});
export const getBestSellerProductFailure = (payload) => ({
    type: types.GET_BEST_SELLER_PRODUCT_FAILURE,
    payload,
});