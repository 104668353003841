import React, { useEffect } from "react";
import "./style.scss";
import { Container, Row, Col, Button } from "react-bootstrap";
import Mycards from "./Mycards";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

// redux --------------
import { getWebProductList } from "../../store/Product/action";
import { addToCart } from "../../store/Cart/action";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Component/Loader/Loader";
import NoDataFound from "../../Component/NoDataFound/index";
// redux end------------


function Shop() {

  let navigate = useNavigate();

  //redux data--------------
  const dispatch = useDispatch();
  const { currencyItems, currencySelectByUser } = useSelector((state) => state && state.CartReducer);
  const { isWebProductListFetching, webProductList } = useSelector(
    (state) => state && state.WebProductReducer
  );

  //redux data end----------

  useEffect(() => {
    dispatch(getWebProductList());
  }, [dispatch]);

  // for products show

  const ProductData = () => {
    const user_id = localStorage.getItem("userId") ? localStorage.getItem("userId") : "";

    const handleClick = (id) => {
      dispatch(
        addToCart({
          product_id: id,
          qty: 1,
          user_id: user_id,
        })
      );
         navigate(`${process.env.PUBLIC_URL}/checkout`);
    };


      // for courrency change start
        const { gbp } = currencyItems;
        const baseCurrency = gbp / currencySelectByUser?.value
      // for courrency change end


    return (
      <>
        {webProductList.map((productShop) => {
          return (
            <Col sm={3} key={productShop.id} style={{ textAlign: "center" }}>
                <Mycards
                  cardLinks={
                      `${process.env.PUBLIC_URL}/product/${productShop.product_slug}`
                  }
                  product_image={
                      productShop.product_image
                    }
                  product_image_altName={productShop.product_name}
                  product_name={productShop.product_name}
                  mrp={
                    ((productShop.mrp)/(baseCurrency)).toFixed(2)
                    }
                  sale_price={
                    ((productShop.sale_price)/(baseCurrency)).toFixed(2)
                    }
                  per={
                     `${((((productShop.mrp)-(productShop.sale_price))*100) / (productShop.mrp)).toFixed()}%`
                     }
                  buy_now={
                      <Button
                        className="buynow-btn"
                        onClick={() => handleClick(productShop.id)}
                      >
                        Buy Now
                      </Button>
                  }
                />
            </Col>
          );
        })}
      </>
    );
  };
  // for products show

  return (
    <>
      <Helmet>
        <title>
          Buy Microsoft Office at the best deals online - Gosoftware
        </title>
        <meta
          name="description"
          content="Buy Microsoft Office at the best deals online. Get all apps including Word, Excel, Powerpoint, Outlook, Publisher & Access Lifetime use."
        />
      </Helmet>

      <Container className="gosoftware-padding">
        <h2 className="shop_page_heading">
          Best Prices on Microsoft Office for Windows PC and Mac
        </h2>

        <Row>
          {isWebProductListFetching ? (
            <p style={{ textAlign: "center", margin: "160px 0" }}>
              <Loader />
            </p>
          ) : (
            <>
            {webProductList?.length === 0 ? 
             <NoDataFound title="No product found on shop" /> :
            <ProductData />
             }
            </>
          )}
        </Row>
      </Container>
    </>
  );
}

export default Shop;
