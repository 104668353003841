import React, { useRef } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";

const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

function ContactForm() {
  const reCaptchaRef = useRef();

  const onSubmit = () => {
    console.log("Contact details", values);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("message", values.message);
    formData.append("number", values.number);
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().required("Email is required"),
    number: yup.string().required("Number is required"),
    message: yup.string().required("Message is required"),
    recaptcha: yup.string().required(),
  });

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      number: "",
      message: "",
      recaptcha: "",
    },
    validationSchema,
    onSubmit,
  });
  return (
    <>
      <div className="ContactBg">
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationFormik01">
              <Form.Label>
                Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                isInvalid={!!errors.name && touched.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik02">
              <Form.Label>
                Email<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                isInvalid={!!errors.email && touched.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="validationFormik03">
              <Form.Label>
                Contact number<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="number"
                value={values.number}
                onChange={handleChange}
                isInvalid={!!errors.number && touched.number}
              />

              <Form.Control.Feedback type="invalid">
                {errors.number}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} controlId="validationFormik04">
              <Form.Label>
                Your message<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                value={values.message}
                onChange={handleChange}
                isInvalid={!!errors.message && touched.message}
              />
              <Form.Control.Feedback type="invalid">
                {errors.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <div>
            <p>
              Note:{" "}
              <strong>
                Please mention your order number if you have placed an order
                with us
              </strong>
            </p>
          </div>

          <Row className="mt-4 mb-4">
            <Form.Group>
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey={TEST_SITE_KEY}
                onChange={(value) => {
                  setFieldValue("recaptcha", value);
                }}
                isInvalid={!!errors.recaptcha && touched.recaptcha}
              />
              <Form.Control.Feedback type="invalid">
                {errors.recaptcha}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          

          <div className="d-grid mt-4">
            <Button type="submit" variant="dark" style={{ padding: "0.6rem 0rem" }}>
              {" "}
              Submit form{" "}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default ContactForm;
