import * as types from "./constant";

export const initialState = {
  isWebCategoryListFecthing: false,
  webCategoryList: [],
  singleCategory: [],
  isWebCategoryWithSubCategoryFecthing: false,
  webCategoryWithSubCategory: [],
  webCategoryListErrr: null,
};

const WebCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_WEB_CATEGORY_LIST:
      return {
        ...state,
        isWebCategoryListFecthing: true,
        webCategoryListErrr: null,
      };
    case types.GET_WEB_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        isWebCategoryListFecthing: false,
        webCategoryList: action.payload,
        webCategoryListErrr: null,
      };
    case types.GET_WEB_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        isWebCategoryListFecthing: false,
        webCategoryListErrr: action.payload,
      };

    case types.GET_SINGLE_CATEGORY:
      return {
        ...state,
        isWebCategoryListFecthing: true,
        webCategoryListErrr: null,
        };
    case types.GET_SINGLE_CATEGORY_SUCCESS:
      return {
        ...state,
        isWebCategoryListFecthing: false,
        singleCategory: action.payload,
        webCategoryListErrr: null,
      };
    case types.GET_SINGLE_CATEGORY_FAILURE:
      return {
        ...state,
        isWebCategoryListFecthing: false,
        webCategoryListErrr: action.payload,
      };

    case types.GET_CATEGORY_WITH_SUB_CATEGORY_LIST:
      return {
        ...state,
        isWebCategoryWithSubCategoryFecthing: true,
        webCategoryListErrr: null,
      };
    case types.GET_CATEGORY_WITH_SUB_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        isWebCategoryWithSubCategoryFecthing: false,
        webCategoryWithSubCategory: action.payload,
        webCategoryListErrr: null,
      };
    case types.GET_CATEGORY_WITH_SUB_CATEGORY_LIST_FAILURE:
      return {
        ...state,
        isWebCategoryWithSubCategoryFecthing: false,
        webCategoryListErrr: action.payload,
      };

    default:
      return state;
  }
};

export default WebCategoryReducer;
