import React from 'react';
import { Routes, Route } from "react-router-dom";

import Home from '../Pages/Home/index';
import About from '../Pages/Home/Component/About';
import Service from '../Pages/Home/Component/Servicess';
import Work from '../Pages/Home/Component/Work';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Product from '../Pages/Products/index';
import Shop from '../Pages/Shop/index';
import Cart  from '../Pages/Cart/Index';
import OurReviews from '../Pages/OurReviews/Index';
import Checkout from '../Pages/Checkout/Index';
import Contact from '../Pages/Contact/Index';
import TermsConditions from '../Pages/TermsConditions/Index';
import PrivacyPolicy from '../Pages/PrivacyPolicy/Index';
import RefundPolicy from '../Pages/RefundPolicy/Index';
import OrderShipment from '../Pages/OrderShipment/Index';
import Search from '../Pages/Search/Index';
import ProductCategory from '../Pages/ProductCategory/index';
import ProductSubCategory from '../Pages/ProductCategory/ProductSubCategory/index';
import PageNotFound from '../Pages/OtherPages/PageNotFound';
import Myaccount from '../Pages/Myaccount/Myaccount';
import AuthorizedReseller from '../Pages/AuthorizedReseller/index';
import Sustainability from '../Pages/Sustainability/index';
import AffiliateProgram from '../Pages/AffiliateProgram/index';
import Account from '../Pages/Myaccount/Account';
import LostPassword from '../Pages/Myaccount/LostPasswordResetPassword/LostPassword';
import ResetPassword from '../Pages/Myaccount/LostPasswordResetPassword/ResetPassword';
import PlacedOrder from '../Pages/Checkout/Component/PlacedOrder';

// for admin
import Sidebar from "../Pages/AdminPanel/Layout/Sidebar";
import ManageProduct from "../Pages/AdminPanel/Pages/Product/index";
import AddProduct from "../Pages/AdminPanel/Pages/Product/AddProduct/index";
import Category from "../Pages/AdminPanel/Pages/Category/index";
import AddCategory from "../Pages/AdminPanel/Pages/Category/AddCategory/index";
import EditProduct from "../Pages/AdminPanel/Pages/Product/EditProduct/index";
import Dashboard from "../Pages/AdminPanel/Pages/Dashboard/index";
import ProductKey from "../Pages/AdminPanel/Pages/ProductKey/index";
import AddProductKeys from "../Pages/AdminPanel/Pages/ProductKey/AddProductKey/index";
import EditCategory from "../Pages/AdminPanel/Pages/Category/EditCategory/index";
import NewOrder from "../Pages/AdminPanel/Pages/Sales/NewOrder/index";
import Order from "../Pages/AdminPanel/Pages/Sales/ViewOrder/index";
import Profile from '../Pages/AdminPanel/Pages/Profile/index';
import CouponCode from '../Pages/AdminPanel/Pages/CouponCode/index';
import AddCouponCode from '../Pages/AdminPanel/Pages/CouponCode/AddCouponCode/index';
import EditCouponCode from '../Pages/AdminPanel/Pages/CouponCode/EditCouponCode/index';
import SubCategory from '../Pages/AdminPanel/Pages/SubCategory';
import AddSubCategory from '../Pages/AdminPanel/Pages/SubCategory/AddSubCategory';
import EditSubCategory from '../Pages/AdminPanel/Pages/SubCategory/EditSubCategory';


import ProtectedRoute from './ProtectedRoute';
import ImportKey from '../Pages/AdminPanel/Pages/ProductKey/ImportKey/ImportKey';




function WebRoute() {
  return (
                <Routes>
                  <Route path="/" element={<Header />}>
                        <Route index element={<Home />}/>
                        <Route path="/about" element={<About />}/>
                        <Route path="/service" element={<Service />}/>
                        <Route path="/work" element={<Work />} />
                        <Route path="/product/:slug" element={<Product />}/>
                        <Route path="/shop" element={<Shop />} />
                        <Route path="/cart" element={<Cart />}/>
                        <Route path="/reviews" element={<OurReviews />}/>
                        <Route path="/contact" element={<Contact />}/>
                        <Route path="/footer" element={<Footer />}/>
                        <Route path="/terms-conditions" element={<TermsConditions />}/>
                        <Route path="/privacy-policy" element={<PrivacyPolicy />}/>
                        <Route path="/refund-policy" element={<RefundPolicy />}/>
                        <Route path="/order-shipment" element={<OrderShipment />}/>
                        <Route path="/account" element={<Account />} />
                        <Route path="/search/:slug" element={<Search />} />
                        <Route path="/product-category/:slug" element={<ProductCategory />} />
                        <Route path="/product-category/:categoryslug/:subcategoryslug" element={<ProductSubCategory />}/>
                        <Route path="/my-account/:active_tab" element={<Myaccount />} />
                        <Route path="/authorized-reseller" element={<AuthorizedReseller /> } />
                        <Route path="/sustainability" element={<Sustainability />}/>
                        <Route path="/affiliate-program" element={<AffiliateProgram />} />
                        <Route path="/lost-password" element={<LostPassword /> } />
                        <Route path="/password/reset/:token/:email" element={<ResetPassword />} />
                        <Route path="/checkout/placed-order" element={<PlacedOrder />} />
                    </Route>
                        <Route path="/checkout" element={<Checkout />} />


                    <Route path="/admin" element={<ProtectedRoute Component={Sidebar} />} >
                        <Route index element={<Dashboard />} />
                        <Route path="/admin/product" element={<ManageProduct />} />
                        <Route path="/admin/add-product" element={<AddProduct />} />
                        <Route path="/admin/edit-product/:id" element={<EditProduct />} />
                        <Route path="/admin/category" element={<Category />} />
                        <Route path="/admin/add-category" element={<AddCategory />} />
                        <Route path="/admin/edit-category/:id" element={<EditCategory /> } />
                        <Route path="/admin/product-key" element={<ProductKey />} />
                        <Route path="/admin/add-product-key" element={<AddProductKeys />} />
                        <Route path="/admin/orders" element={<NewOrder />} />
                        <Route path="/admin/order/:id" element={<Order />} />
                        <Route path="/admin/profile" element={ <Profile /> }/>
                        <Route path="/admin/coupon" element={ <CouponCode /> } />
                        <Route path="/admin/add-coupon" element={<AddCouponCode />}/>
                        <Route path="/admin/edit-coupon/:id" element={ <EditCouponCode />}/>
                        <Route path="/admin/sub-category" element={<SubCategory />}/>
                        <Route path="/admin/add-sub-category" element={<AddSubCategory />}/>
                        <Route path="/admin/edit-sub-category/:id" element={<EditSubCategory />}/>
                        <Route path="/admin/product-key/import-key" element={<ImportKey />} />
                    </Route>

                    <Route path="*" element={<PageNotFound />} />

                  </Routes>    
                   
  );
}

export default WebRoute;


