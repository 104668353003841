import { put, takeLatest, call, all } from "redux-saga/effects";
import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  GET_BEST_SELLER_PRODUCT,
  GET_EDIT_PRODUCT,
  GET_PRODUCT_BY_SEARCH,
  GET_PRODUCT_LIST,
  GET_SINGLE_PRODUCT,
  GET_WEB_PRODUCT_LIST,
  UPDATE_PRODUCT,
} from "./constant";
import {
  addProductSuccess,
  getProductListFailure,
  getProductListSuccess,
  getProductList,
  addProductFailure,
  getWebProductListSuccess,
  getWebProductListFailure,
  getSingleProductSuccess,
  getSingleProductFailure,
  getEditProductSuccess,
  getEditProductFailure,
  deleteProductSuccess,
  deleteProductFailure,
  updateProductSuccess,
  updateProductFailure,
  getProductBySearchSuccess,
  getProductBySearchFailure,
  getBestSellerProductSuccess,
  getBestSellerProductFailure,
  getEditProduct,
  
} from "./action";
import api from "../../utils/api";

// const token = localStorage.getItem("token")

export function* fetchProductsList({ payload }) {
  const token = localStorage.getItem("token");
  try {
    // let query = payload ? payload : "";
    const response = yield call(api(token).get, `/admin/product?category=${payload.category}&search=${payload.search}`);
    // data = yield data.json();
    // console.warn("API data", data);
    if (response) {
      yield put(getProductListSuccess(response && response.result));
    }
  } catch (e) {
    yield put(getProductListFailure(e));
  }
}

// add product --------------

export function* addProductData({ payload }) {
  // console.warn("Add Product data by form", payload );
  const token = localStorage.getItem("token");
  try {
    const response = yield call(
      api(token).post,
      `/admin/product/create`,
      payload
    );
    if (response) {
      yield put(addProductSuccess(response));
      yield put(getProductList());
    }
    // console.warn("Add Product by saga", response);
  } catch (e) {
    yield put(addProductFailure(e));
  }
}


// for admin edit product
export function* fetchEditProduct({payload}) {
  const token = localStorage.getItem("token");
  try {
    let query = payload ? payload: ""
    const response = yield call(api(token).get, `/admin/product/edit/${query}`,);
    if (response) {
      yield put(getEditProductSuccess(response && response.result));
    }
  } catch (e) {
    yield put(getEditProductFailure(e));
  }
}


// for admin update product
export function* updateProductData({payload}) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(api(token).post, `/admin/product/update/${payload.id}`, payload.formData);
    if (response) {
      const id = response.result.id
      yield put(updateProductSuccess(response));
      yield put(getEditProduct(id));
    }
  } catch (e) {
    yield put(updateProductFailure(e));
  }
}


// for admin delete product 
export function* deleteProductData({payload}) {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(api(token).delete, `/admin/product/delete/${payload}`, );
    if (response) {
      yield put(deleteProductSuccess(response));
      yield put(getProductList());
    }
  } catch (e) {
    yield put(deleteProductFailure(e));
  }
}



// for web product saga
export function* fetchWebProductList({ payload }) {
  try {
    let query = payload ? payload : "";
    const response = yield call(api().get, `/product${query}`);
    if (response) {
      yield put(getWebProductListSuccess(response && response.result));
    }
  } catch (e) {
    yield put(getWebProductListFailure(e));
  }
}

// for single product 
export function* fetchSingleProduct({payload}) {
  try {
    let query = payload ? payload : "";
    const response = yield call(api().get, `/product/${query}`,);
    // response = yield response.json();
    // console.log("response", response);
    if (response) {
      yield put(getSingleProductSuccess(response && response.result));
    }
  } catch (e) {
    yield put(getSingleProductFailure(e));
  }
}


// get product by search web
export function* fetchProductBySearchData({payload}) {
  try{
    let query = payload ? payload : "";
    const response = yield call(api().get, `/search?q=${query}`,);
    if(response) {
      yield put(getProductBySearchSuccess(response && response.result));
    }
  } catch (e) {
    yield put(getProductBySearchFailure(e));
  }
}

// get best seller product for web
export function* fetchBestSellerProductData({payload}) {
  try{
    let query = payload ? payload : "";
    const response = yield call(api().get, `/product/best_seller${query}`);
    if(response) {
      yield put(getBestSellerProductSuccess(response && response.result));
    }
  } catch (e) {
    yield put(getBestSellerProductFailure(e));
  }
}


// saga flow --------------

export function* fetchProductListFlow() {
  yield takeLatest(GET_PRODUCT_LIST, fetchProductsList);
}

export function* addProductFlow() {
  yield takeLatest(ADD_PRODUCT, addProductData);
}


// for admin edit product flow
export function* fetchEditProductFlow() {
  yield takeLatest(GET_EDIT_PRODUCT, fetchEditProduct);
}

// for admin update product flow
export function* updateProductFlow() {
  yield takeLatest(UPDATE_PRODUCT, updateProductData);
}

// for admin delete product flow 
export function* deleteProductFlow() {
  yield takeLatest(DELETE_PRODUCT, deleteProductData);
}


// for web product saga flow
export function* fetchWebProductListFlow() {
  yield takeLatest(GET_WEB_PRODUCT_LIST, fetchWebProductList);
}

// for single product
export function* fetchSingleProductFlow() {
  yield takeLatest(GET_SINGLE_PRODUCT, fetchSingleProduct);
}


// get product by search web
export function* fetchProductBySearchFlow(){
  yield takeLatest(GET_PRODUCT_BY_SEARCH, fetchProductBySearchData);
}


// get best seller product for web
export function* fetchBestSellerProductFlow(){
  yield takeLatest(GET_BEST_SELLER_PRODUCT, fetchBestSellerProductData);
}

export default function* productSaga() {
  yield all([
    fetchProductListFlow(),
    addProductFlow(),
    fetchEditProductFlow(),
    updateProductFlow(),
    deleteProductFlow(),
    fetchWebProductListFlow(),
    fetchSingleProductFlow(),
    fetchProductBySearchFlow(),
    fetchBestSellerProductFlow(),
  ]);
}
