import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

function PageNotFound() {
  return (
    <>
      <div style={{ textAlign: "center", margin: "4rem 0 4rem 0" }}>
        <h2>Sorry, page not found!</h2>
        <p>
          Sorry, we couldn’t find the page you’re looking for. Perhaps <br />{" "}
          you’ve mistyped the URL? Be sure to check your spelling.
        </p>

        <div>
          <img
              src={`${process.env.PUBLIC_URL}/Assets/error-page-img.png`}
              alt="404 error"
          />
        </div>

        <div style={{ marginTop: "2rem" }}>
          <Link to="/">
            <Button>Go To Home</Button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
