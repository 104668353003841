import * as types from "./constant";

export const initialState = {


    // sub category list
    isSubCategoryListFetching: false,
    subCategoryList: [],
    editSubCategory: [],
    subCategoryListErrr: null,

    // sub category list for web // get product by sub category slug for web
    isWebSubCategoryListFetching: false,
    webSubCategoryList: [],
    webProductBySubCategoryList: [],
    webSubCategoryListErrr: null,

    // Add/Edit
    isSubmitting: false,
    subCategorySubmitingErrr: null,
    subCategoryUpdatingErrr: null,
}


const SubCategoryReducer = (state = initialState, action) => {
    switch (action.type){
        case types.GET_SUB_CATEGORY_LIST:
            return {
                ...state,
                isSubCategoryListFetching: true,
                subCategoryListErrr: null,
            };
        case types.GET_SUB_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                isSubCategoryListFetching: false,
                subCategoryList: action.payload,
                subCategoryListErrr: null,
            };
        case types.GET_SUB_CATEGORY_LIST_FAILURE:
            return {
                ...state,
                isSubCategoryListFetching: false,
                subCategoryListErrr: action.payload,
            };  
            

        case types.ADD_SUB_CATEGORY:
            return {
                ...state,
                isSubmitting: true,
                subCategorySubmitingErrr: null,
            };
        case types.ADD_SUB_CATEGORY_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                subCategorySubmitingErrr: action.payload.message,
            };
        case types.ADD_SUB_CATEGORY_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                subCategorySubmitingErrr: action.payload.error,
            }; 
            
        case types.GET_EDIT_SUB_CATEGORY:
            return {
                ...state,
                isSubCategoryListFetching: true,
                subCategoryListErrr: null,
            };
        case types.GET_EDIT_SUB_CATEGORY_SUCCESS:
            return {
                ...state,
                isSubCategoryListFetching: false,
                editSubCategory: action.payload,
                subCategoryListErrr: null,
            };
        case types.GET_EDIT_SUB_CATEGORY_FAILURE:
            return {
                ...state,
                isSubCategoryListFetching: false,
                subCategoryListErrr: action.payload,
            };

        case types.UPDATE_SUB_CATEGORY:
            return {
                ...state,
                isSubmitting: true,
                subCategoryUpdatingErrr: null,
            };
        case types.UPDATE_SUB_CATEGORY_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
                subCategoryUpdatingErrr: "Sub category has been updated successfully.",
            };
        case types.UPDATE_SUB_CATEGORY_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                subCategoryUpdatingErrr: action.payload.error,
            };
        
        case types.DELETE_SUB_CATEGORY:
            return {
                ...state,
                isSubmitting: true,
            };
        case types.DELETE_SUB_CATEGORY_SUCCESS:
            return {
                ...state,
                isSubmitting: false,
            };
        case types.DELETE_SUB_CATEGORY_FAILURE:
            return {
                ...state,
                isSubmitting: false,
            }; 

        case types.GET_SUB_CATEGORY_BY_CATEGORY_ID:
            return {
                ...state,
                isWebSubCategoryListFetching: true,
                webSubCategoryListErrr: null,
            };
        case types.GET_SUB_CATEGORY_BY_CATEGORY_ID_SUCCESS:
            return {
                ...state,
                isWebSubCategoryListFetching: false,
                webSubCategoryList: action.payload,
                webSubCategoryListErrr: null,
            };
        case types.GET_SUB_CATEGORY_BY_CATEGORY_ID_FAILURE:
            return {
                ...state,
                isWebSubCategoryListFetching: false,
                webSubCategoryListErrr: action.payload,
            };

        case types.GET_PRODUCTS_BY_SUB_CATEGORY_SLUG:
            return {
                ...state,
                isWebSubCategoryListFetching: true,
                webSubCategoryListErrr: null,
            };
        case types.GET_PRODUCTS_BY_SUB_CATEGORY_SLUG_SUCCESS:
            return {
                ...state,
                isWebSubCategoryListFetching: false,
                webProductBySubCategoryList: action.payload,
                webSubCategoryListErrr: null,
            };
        case types.GET_PRODUCTS_BY_SUB_CATEGORY_SLUG_FAILURE:
            return {
                ...state,
                isWebSubCategoryListFetching: false,
                webSubCategoryListErrr: action.payload,
            };    
            
        default:
            return state;    
    }
}


export default SubCategoryReducer;