import React from 'react';
import SlideTopbar from "../SlideTopbar/SlideTopbar";
import Microsoftpartner from '../MicrosoftPartner/Microsoftpartner';

function CategoryBanner({categoryImage, categoryImageAlt}) {
  return (
    <>
      <div className="category_banner">
        <SlideTopbar />
        <img src={categoryImage} className="category_banner_image" alt={categoryImageAlt}  />
        <Microsoftpartner />
      </div>
    </>
  );
}

export default CategoryBanner;
