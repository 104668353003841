import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  FloatingLabel,
  Spinner,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";

// start redux
import { orderPlaced } from "../../../store/Order/action";
import { loginUserSuccess } from "../../../store/Auth/action";
import { useSelector, connect } from "react-redux";
import { compose } from "redux";
// end redux data
import countries from "../../../utils/countries.json";


function FormCheckout({ createOrder, emailId, createUser }) {
  
  const [visible, setIsVisible] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const { isSubmitOrder, orderPlacedMessage } = useSelector((state)=> state && state.OrderReducer);
  
  const navigate = useNavigate();

  const { checkCouponData } =
    useSelector((state) => state && state.couponReducer);
  const { currencySelectByUser } = useSelector(
    (state) => state && state.CartReducer
  ); 

    // for calculate total Amount
    const { coupon_code } = checkCouponData;

// for countries name
  useEffect(() => {
    setCountryData(countries.countries);
  },[])


  const newCountryData = countryData?.map((item) => {
    return {
      value: item.value,
      label: item.value,
    };
  });

  const user = JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : "";

  const { id, email } = user;

  const setEmail = email == null ? emailId : email;

  const user_Id = id ? id : "";
  const session_user_id = localStorage.getItem("userId") ? localStorage.getItem("userId") : "";


  const selectedCurrencySaved = JSON.parse(localStorage.getItem("selectedCurrency"));
  // current Currency
  const currentCurrency = currencySelectByUser?.name ? currencySelectByUser?.name : selectedCurrencySaved?.name;
  const currentCurrencyType = currentCurrency.slice(0,3);
  const currentCurrencySymbol = currentCurrency.slice(3);
  const currentCurrencyTypeText = currentCurrencyType.toLowerCase();


  const onSubmit = () => {
    // console.log("values", values);
    const expiry_date = values.expiry_date;
    const myArray = expiry_date.split("/");

    const formData = new FormData();

    formData.append("card_no", values.card_number);
    formData.append("expiry_month", myArray[0]);
    formData.append("expiry_year", myArray[1]);
    formData.append("email", setEmail);
    formData.append("currency", currentCurrencyTypeText);
    formData.append("curr_symbol", currentCurrencySymbol);
    formData.append("user_id", user_Id);
    formData.append("session_user_id", session_user_id);
    formData.append("coupon_code", coupon_code);
    formData.append("cvv_no", values.cvc_code);
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("country", values.country);
    formData.append("post_code", values.post_code);
    formData.append("phone", values.phone);

    createOrder(formData);
    createUser();


    // for error message start
      setIsVisible(true);
    // for error message end
    setTimeout(function() {
      setIsVisible(false);
    }, 16000);
  };

  const validationSchema = yup.object().shape({
    card_number: yup.string().required("Card number is required"),
    expiry_date: yup.string().required("Expiry date is required"),
    cvc_code: yup.string().min(3).max(4).required("CVC Code is required"),

    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    country: yup.string().required("Country is required"),
    post_code: yup.string().required("Post code is required"),
    phone: yup.string().required("Phone number is required"),
    terms: yup.bool().required().oneOf([true], "Terms must be accepted"),
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      card_number: "",
      expiry_date: "",
      cvc_code: "",

      first_name: "",
      last_name: "",
      country: "",
      post_code: "",
      phone: "",
      terms: false,
    },
    validationSchema,
    onSubmit,
  });


  return (
    <>
      <div className="pay-steps gosoftware-padding">
        <div className="pay-step">

          <h6 className="pay-title mb-3"> <span className="pay_title_number">2</span>Payment Details</h6>

          <Row>
            <Col sm={4}>
              <div className="cre-stripe">
                <p style={{ marginBottom: "0px" }}>◉ Credit Card (Stripe)</p>
              </div>
            </Col>
            <Col sm={8}>
              <Card className="secure-card">
                <Card.Body>
                  <div className="secure-info">
                    <strong>Secure Credit/Debit Card Payment</strong> <br />
                    This 256-bit TLS encrypted payment is secured by Stripe.
                  </div>
                  <p>Pay with your Credit or Debit card via Stripe.</p>
                  <div>
                    <Form noValidate onSubmit={handleSubmit}>
                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="validationFormik01">
                          <Form.Label>
                            Card Number<span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="card_number"
                            value={values.card_number}
                            placeholder="0000 0000 0000 0000"
                            required
                            onChange={(e) => {
                              const inputVal = e.target.value.replace(/ /g, "");
                              let inputNumbersOnly = inputVal.replace(
                                /\D/g,
                                ""
                              );
                              if (inputNumbersOnly.length > 16) {
                                inputNumbersOnly = inputNumbersOnly.substr(
                                  0,
                                  16
                                );
                              }
                              const splits = inputNumbersOnly.match(/.{1,4}/g);
                              let spacedNumber = "";
                              if (splits) {
                                spacedNumber = splits.join(" ");
                              }
                              setFieldValue("card_number", spacedNumber);
                            }}
                            isValid={touched.card_number && !errors.card_number}
                            isInvalid={!!errors.card_number && touched.card_number}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.card_number}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>

                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="validationFormik02">
                          <Form.Label>
                            Expiry Date<span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="MM/YY"
                            required
                            name="expiry_date"
                            value={values.expiry_date}
                            onChange={(e) => {
                              e.preventDefault();
                              const { value } = e.target;
                              const expDateFormatter =
                                value.replace(/\//g, "").substring(0, 2) +
                                (value.length > 2 ? "/" : "") +
                                value.replace(/\//g, "").substring(2, 4);
                              setFieldValue("expiry_date", expDateFormatter);
                            }}
                            isValid={touched.expiry_date && !errors.expiry_date}
                            isInvalid={!!errors.expiry_date && touched.expiry_date}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.expiry_date}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="validationFormik03">
                          <Form.Label>
                            Card Code (CVC)
                            <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="CVC"
                            required
                            name="cvc_code"
                            maxlength={4}
                            value={values.cvc_code}
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setFieldValue("cvc_code", e.target.value);
                              }
                            }}
                            isValid={touched.cvc_code && !errors.cvc_code}
                            isInvalid={!!errors.cvc_code && touched.cvc_code}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.cvc_code}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <div className="Mypadding">
            <p>BILLING ADDRESS</p>

            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="validationFormik04">
                  <FloatingLabel
                    controlId="floatingFullname"
                    label={
                      <span>
                        {" "}
                        First name<span className="text-danger">*</span>{" "}
                      </span>
                    }
                  >
                    <Form.Control
                      style={{
                        border: touched.first_name ? "1px solid #198754" : "1px solid #cc2131"
                      }}
                      type="text"
                      placeholder="First name"
                      name="first_name"
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.first_name && !errors.first_name}
                      isInvalid={!!errors.first_name && touched.first_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.first_name}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>

                <Form.Group as={Col} controlId="validationFormik05">
                  <FloatingLabel
                    controlId="floatingFullname"
                    label={
                      <span>
                        {" "}
                        Last name<span className="text-danger">*</span>{" "}
                      </span>
                    }
                  >
                    <Form.Control
                      style={{
                        border: touched.last_name ? "1px solid #198754" : "1px solid #cc2131"
                      }}
                      type="text"
                      placeholder="Last name"
                      name="last_name"
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.last_name && !errors.last_name}
                      isInvalid={!!errors.last_name && touched.last_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.last_name}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="validationFormik06">
                  <FloatingLabel
                    controlId="floatingFullname"
                    label={
                      <span>
                        Country / Region<span className="text-danger">*</span>{" "}
                      </span>
                    }
                  >
                    <Form.Select
                      style={{
                        border: touched.country ? "1px solid #198754" : "1px solid #cc2131"
                      }}
                      type="text"
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.country && !errors.country}
                      isInvalid={!!errors.country && touched.country}
                    >
                      <option>Select your country</option>
                      {newCountryData.map((option)=>(
                      <option value={option.value}>{option.label}</option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                    {errors.country}
                  </Form.Control.Feedback>
                  </FloatingLabel>
                  
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="validationFormik07">
                  <FloatingLabel
                    controlId="floatingZip"
                    label={
                      <span>
                        Post code<span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <Form.Control
                      style={{
                        border: touched.post_code ? "1px solid #198754" : "1px solid #cc2131"
                      }}
                      type="text"
                      placeholder="Postcode / ZIP"
                      name="post_code"
                      maxlength={16}
                      value={values.post_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.post_code && !errors.post_code}
                      isInvalid={!!errors.post_code && touched.post_code}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.post_code}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="validationFormik08">
                  <FloatingLabel
                    controlId="floatingPhone"
                    label={
                      <span>
                        Phone<span className="text-danger">*</span>
                      </span>
                    }
                  >
                    <Form.Control
                      style={{
                        border: touched.phone ? "1px solid #198754" : "1px solid #cc2131"
                      }}
                      type="text"
                      placeholder="Phone"
                      name="phone"
                      maxlength={15}
                      value={values.phone}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setFieldValue("phone", e.target.value);
                        }
                      }}
                      isValid={touched.phone && !errors.phone}
                      isInvalid={!!errors.phone && touched.phone}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Form.Group>
              </Row>

              <div style={{ marginTop: "20px" }}>
                <hr />
                <h4>Review & Place Order</h4>
                <p>
                  Please review your payment details above and your order
                  summary on the right.When you are ready, click on the Place
                  Order button.
                </p>
                <p>
                  Your personal data will be used to process your order, support
                  your experience throughout this website, and for other
                  purposes described in our{" "}
                  <Link to="/refund-policy" target="_blank">Refund Policy</Link>.
                </p>
              </div>

              <Form.Group className="mb-3">
                <Form.Check
                  required
                  name="terms"
                  label={
                    <span>
                      I have read and agree to the website{" "}
                      <Link to="/terms-conditions" target="_blank">terms and conditions </Link>{" "}
                      & <Link to="/refund-policy" target="_blank">Refund Policy</Link>
                      <span className="text-danger">*</span>
                    </span>
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.terms && touched.terms}
                  feedback={errors.terms}
                  feedbackType="invalid"
                  id="validationFormik0"
                />
              </Form.Group>

              <div className="d-grid">
                <Button 
                  type="submit" 
                  variant="dark" 
                  size="lg"
                  id="form_checkout_btn"
                  disabled={isSubmitOrder}
                >
                  {isSubmitOrder && (
                    <Spinner animation="border" size="sm" as="span" style={{marginRight: "3px"}} />
                  )}
                  <span>Place order securely</span> 
                </Button>
              </div>
            </Form>
              
              {isSubmitOrder ? "" : 
              <>
              {visible && (
                <>
                {orderPlacedMessage === "Your order has been placed successfully." ? 
                   navigate("/checkout/placed-order") :
                   <p className="error-message">*{orderPlacedMessage}</p>
                }
                </>
                )}
              </>
            }

          </div>
        </div>
      </div>
    </>
  );
}

// export default FormCheckout;

function mapDispatchToProps(dispatch) {
  return {
    createOrder: (data) => dispatch(orderPlaced(data)),
    createUser: (data) => dispatch(loginUserSuccess(data)),
  };
}

const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(FormCheckout);
