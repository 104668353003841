import * as types from './constant';

export const getProductKeyList = (payload) => ({
    type: types.GET_PRODUCT_KEY_LIST,
    payload,
});

export const getProductKeyListSuccess = (payload) => ({
    type: types.GET_PRODUCT_KEY_LIST_SUCCESS,
    payload,
});

export const getProductKeyListFailure = (payload) => ({
    type: types.GET_PRODUCT_KEY_LIST_FAILURE,
    payload,
});


// add product key
export const addProductKey = (payload) => ({
    type: types.ADD_PRODUCT_KEY,
    payload,
});

export const addProductKeySuccess = (payload) => ({
    type: types.ADD_PRODUCT_KEY_SUCCESS,
    payload,
});

export const addProductKeyFailure = (payload) => ({
    type: types.ADD_PRODUCT_KEY_FAILURE,
    payload,
});


// update product key
export const updateProductKey = (payload) => ({
    type: types.UPDATE_PRODUCT_KEY,
    payload,
});

export const updateProductKeySuccess = (payload) => ({
    type: types.UPDATE_PRODUCT_KEY_SUCCESS,
    payload,
});

export const updateProductKeyFailure = (payload) => ({
    type: types.UPDATE_PRODUCT_KEY_FAILURE,
    payload,
});


// delete product key
export const deleteProductKey = (payload) => ({
    type: types.DELETE_PRODUCT_KEY,
    payload,
});

export const deleteProductKeySuccess = (payload) => ({
    type: types.DELETE_PRODUCT_KEY_SUCCESS,
    payload,
});

export const deleteProductKeyFailure = (payload) => ({
    type: types.DELETE_PRODUCT_KEY_FAILURE,
    payload,
});


// for import product key
export const importProductKey = (payload) => ({
    type: types.IMPORT_PRODUCT_KEY,
    payload,
});
export const importProductKeySuccess = (payload) => ({
    type: types.IMPORT_PRODUCT_KEY_SUCCESS,
    payload,
});
export const importProductKeyFailure = (payload) => ({
    type: types.IMPORT_PRODUCT_KEY_FAILURE,
    payload,
});


