import React, { useState } from 'react';
import { Container, Row, Col, Form, Spinner, Button } from 'react-bootstrap';
import * as yup from "yup";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import ModalBoxWeb from "../../../Component/ModalBoxWeb/ModalBoxWeb";
import { resetPassword } from "../../../store/Auth/action";
import { useDispatch, useSelector } from "react-redux";


function ResetPassword() {

    const [messageCondition, setMessageCondition] = useState(false);
    const param = useParams();
    const dispatch = useDispatch();
    const { isSubmitting, resetPasswordModalOpen } = useSelector((state) => state && state.AuthReducer);

    const validationSchema = yup.object().shape({
        password: yup.string().required("New password is required"),
        password_confirmation: yup.string().required("Re-enter new password is required"),
      });
    
      const token = param.token
      const email = param.email

      const onSubmit = () => {
        // console.log("values", values);
        let formData = new FormData();
        formData.append("password", values.password);
        formData.append("password_confirmation", values.password_confirmation);
        formData.append("token", token);
        formData.append("email", email);

        dispatch(resetPassword(formData));
        setMessageCondition(true);
    };
    
      const { handleSubmit, handleChange, values, errors, touched } = useFormik({
        initialValues: {
          password: "",
          password_confirmation: "",
        },
        validationSchema,
        onSubmit,
      });

  return (
    <>
      {resetPasswordModalOpen && messageCondition ? (
          <ModalBoxWeb 
            show={resetPasswordModalOpen} 
            modalname="Password Update"
            message="Your password has been updated successfully." 
            btnlink="/shop"
            btntext="Shop"
          />
        ): "" }
   
    <Container className='gosoftware-padding'>
        <h4>Enter a new password below.</h4>
        
      <Row>
        <Col sm={10} className="mt-4">
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>
                  New password<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder=""
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  isInvalid={!!errors.password && touched.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>
                  Re-enter new password<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder=""
                  name="password_confirmation"
                  value={values.password_confirmation}
                  onChange={handleChange}
                  isInvalid={!!errors.password_confirmation && touched.password_confirmation}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password_confirmation}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Button 
              type="submit"
              className="mt-2"
              disabled={isSubmitting}
            >
              {isSubmitting && (
                <Spinner animation="border" size="sm" as="span" />
              )}
              Save Password
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
    </>
  );
}

export default ResetPassword;
