import React, { useState } from "react";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { updatePassword } from "../../../../../store/Auth/action";
import { useDispatch, useSelector } from "react-redux";

function PasswordUpdate() {

  const [visible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const { isSubmittingPassword, errorMessageUpdatePass } = useSelector(
    (state) => state && state.AuthReducer
  );


  const onSubmit = () => {
    // console.log("values", values);
    let formData = new FormData();
    formData.append("old_password", values.old_password);
    formData.append("password", values.password);
    formData.append("password_confirmation", values.password_confirmation);

    dispatch(updatePassword(formData));

    // for error message start
      setIsVisible(true);
    // for error message end
    setTimeout(function() {
      setIsVisible(false);
    }, 8000);
  };

  const validationSchema = yup.object().shape({
    old_password: yup.string().required("Old password is a required"),
    password: yup.string().required("New password is a required"),
    password_confirmation: yup
      .string()
      .required("Confirm password is a required"),
  });

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema,
    onSubmit,
  });
  return (
    <>

      <Form noValidate onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group controlId="validationFormik01">
            <Form.Label>
              Old Password<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="password"
              name="old_password"
              value={values.old_password}
              onChange={handleChange}
              isInvalid={!!errors.old_password && touched.old_password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.old_password}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="validationFormik02">
            <Form.Label>
              New Password<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              isInvalid={!!errors.password && touched.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="validationFormik02">
            <Form.Label>
              Confirm Password<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="password"
              name="password_confirmation"
              value={values.password_confirmation}
              onChange={handleChange}
              isInvalid={
                !!errors.password_confirmation && touched.password_confirmation
              }
            />
            <Form.Control.Feedback type="invalid">
              {errors.password_confirmation}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Button type="submit" className="mt-4" disabled={isSubmittingPassword}>
          {isSubmittingPassword && <Spinner animation="border" size="sm" as="span" />}
          Update password
        </Button>
        <div>
            {isSubmittingPassword ? "" :
              <>
              {visible && (
                  <>
                    {errorMessageUpdatePass === "Password has been changed successfully." ?
                        <p className="error-message-success">*{errorMessageUpdatePass}</p> :
                        <p className="error-message">*{errorMessageUpdatePass}</p>
                    }
                  </>
              )}
              </>
            }
        </div>
      </Form>


    </>
  );
}

export default PasswordUpdate;
