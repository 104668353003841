import React from "react";
import WebRoute from "./WebRoute";

function MyRoutes() {
    return(
        <>  
            <WebRoute /> 
        </>
    );
}

export default MyRoutes;