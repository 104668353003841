import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

function Sustainability() {
  return (
    <>
      <Helmet>
        <title>Environment Sustainability - Gosoftware</title>
        <meta
          name="description"
          content="Please consider the environment before printing"
        />
      </Helmet>

      <Container className="py-4">
        <h4>WORKING FOR A BETTER TOMORROW</h4>
        <img
            src={`${process.env.PUBLIC_URL}/Assets/Environmental-Sustainability.jpg`}
            width="100%"
            alt="Environmental sustainability"
        />

        <div className="mt-4">
          <h6>WE’RE NOT AGAINST PRINTING. WE’RE AGAINST WASTING RESOURCES.</h6>
          <p>
            WE DON’T WANT TO STOP PEOPLE FROM PRINTING. WE SIMPLY THINK THAT
            SOMETIMES, PEOPLE, AND NOT EVERYONE, NEED REMINDING THAT WASTING
            PAPER, INK AND TONER DOESN’T MAKE ECONOMIC OR ENVIRONMENTAL SENSE.
          </p>
        </div>

        <div>
          <h6>NOT JUST EMAIL</h6>
          <p>
            OUR MESSAGE ISN’T LIMITED TO EMAILS.
            <br />
            EVERY DAY, PEOPLE HIT THE PRINT BUTTON WITHOUT REALLY CONSIDERING
            WHY. OFTEN IT’S BECAUSE WE’RE USED TO SEEING THINGS ON PAPER WE HAVE
            SOME IRRATIONAL DESIRE TO HOARD INFORMATION “SO WE’VE GOT IT TO
            HAND”, BUT SO OFTEN THIS SPONTANEOUS COMPULSION TO PRINT IS
            UNNECESSARY.
          </p>
        </div>

        <div>
          <h6>MORE THAN HALF OF ALL PAGES PRINTED ARE NEVER USED</h6>
          <p>
            WE ESTIMATE THAT OVER 50% OF PAGES PRINTED ARE NEVER LOOKED AT.
            CONSIDER HOW MANY TIMES AN ALMOST COMPLETELY BLANK “PAGE 2 OF 2”
            COMES OUT OF YOUR PRINTER WHEN PRINTING EMAILS AND DOCUMENTS.
          </p>
        </div>

        <div>
          <h6>SUBTLE REMINDERS</h6>
          <p>
            WE HOPE THAT BY PUTTING OUR GENTLE REMINDERS ON THE DOCUMENTS THAT
            ARE SO OFTEN NEEDLESSLY PRINTED WE MIGHT START A SMALL MOVEMENT OF
            CHANGE.
          </p>
        </div>

        <div>
          <h6>WE’RE NOT BOSSY</h6>
          <p>
            WE’RE NOT BOSSY PEOPLE, AND WE HOPE OUR MESSAGE ISN’T BOSSY; WE’RE
            ASKING PEOPLE TO THINK BEFORE PRINTING, AND WE’RE NOT TELLING THEM
            THEY HAVE TO.
          </p>
        </div>

        <h5>JOIN THE CAMPAIGN</h5>
      </Container>
    </>
  );
}

export default Sustainability;
