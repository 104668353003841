import React, { useState } from "react";
import { Container, Form, Row, Col, Button, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";

import { forgotPassword } from "../../../store/Auth/action";
import { useDispatch, useSelector } from "react-redux";


function LostPassword() {

  const [visible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const { isSubmitting, errorMessageforgotPass } = useSelector((state) => state && state.AuthReducer);

  const validationSchema = yup.object().shape({
    email: yup.string().required("Email is required"),
  });

  const onSubmit = () => {
    // console.log("values", values);
    let formData = new FormData();
    formData.append("email", values.email);

    dispatch(forgotPassword(formData));

    // for error message
    setIsVisible(true);
    setTimeout(function() {
      setIsVisible(false);
    }, 8000);
  };

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit,
  });

  return (
    <Container className="gosoftware-padding">
      <h4>
        Lost your password? Please enter your username or email address. You
        will receive a link to create a new password via email.
      </h4>
              {isSubmitting ? "" :
              <>
               {visible && (
                <>
                {errorMessageforgotPass === "Send link on your email." ? 
                <p className="error-message-success">*{errorMessageforgotPass}</p> : 
                <p className="error-message">*{errorMessageforgotPass}</p>
                }
                </>
                )}
              </>
              }
      <Row>
        <Col sm={6} className="mt-4">
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>
                  Username or email<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder=""
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email && touched.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Button 
              type="submit"
              className="mt-2"
              disabled={isSubmitting}
            >
              {isSubmitting && (
                <Spinner animation="border" size="sm" as="span" />
              )}
              Reset Password
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default LostPassword;
