import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";

// for redux start
import { getProductList } from "../../../../../store/Product/action";
import { updateProductKey } from "../../../../../store/ProductKey/action";
import { useDispatch, useSelector } from "react-redux";
// for redux end

function KeyUpdateModal({ show, setShow, keyId, proId, keyCode }) {

  //--------redux data start
  const dispatch = useDispatch();
  const { productList } = useSelector(
    (state) => state && state.ProductReducer
  );

  const handleClose = () => {
    setShow(false);
  };


  useEffect(() => {
    dispatch(getProductList(
        {
          category: "",
          search: "",
        }
    ));
  }, [dispatch]);

  //--------redux data end

  const id = keyId;
  const product_id = proId;
  const code = keyCode;

  const validationSchema = yup.object().shape({
    product_id: yup.string().required("Product id is required"),
    code: yup.string().required("Product key is required"),
  });

  // onSubmit form data
  const onSubmit = () => {
    // console.log("values", values);
    const formData = new FormData();
    formData.append("product_id", values.product_id);
    formData.append("code", values.code);

    dispatch(
      updateProductKey({
        id: id,
        formData: formData,
      })
    );

    handleClose();
  };

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      product_id: product_id ? product_id : "",
      code: code ? code : "",
    },
    validateOnBlur: true,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  // for modal data end

  return (
    <>
      <Modal 
        show={show} 
        onHide={handleClose}
        className="fade"
        >
        <Modal.Header closeButton>
          <Modal.Title>Update Product Key</Modal.Title>
        </Modal.Header>

        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Body>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Product Name</Form.Label>
                <Form.Select
                  type="text"
                  name="product_id"
                  //  value={product_id}
                  defaultValue={product_id}
                  onChange={handleChange}
                  isInvalid={!!errors.product_id && touched.product_id}
                >
                  {/* <option>Select product name</option> */}
                  {productList.map((option) => {
                    return (
                      <option
                        value={option.id}
                        key={option.id}
                        selected={option.id === product_id ? "selected" : ""}
                      >
                        {option.product_name}
                      </option>
                    );
                  })}
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                  {errors.product_id}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Product Key</Form.Label>
                <Form.Control
                  type="text"
                  //  as="textarea"
                  name="code"
                  //  value={code}
                  defaultValue={code}
                  onChange={handleChange}
                  isInvalid={!!errors.code && touched.code}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.code}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
           
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" className="subFormButton">
              Submit
            </Button>
            <Button variant="dark" onClick={() => handleClose()}>Cancel</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default KeyUpdateModal;
