import React, { useEffect } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import Loader from "../../../../Component/Loader/Loader";
import NoDataFound from "../../../../Component/NoDataFound/index";

// for ntd table
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Table, Space } from "antd";
import "antd/dist/antd.css";
// for redux start
import { getAllCoupon, deleteCoupon } from "../../../../store/Coupon/action";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
// for redux end

function CouponCode() {
  const dispatch = useDispatch();

  const { isCouponListFetching, couponList } = useSelector(
    (state) => state && state.couponReducer
  );

  useEffect(() => {
    dispatch(getAllCoupon());
  }, [dispatch]);


  const couponData = couponList.map((item) => ({
    key: item.id,
    id: item.id,
    coupon_code: item.coupon_code,
    discount_per: item.discount_per,
    status: item.status,
  }));

  const columns = [
    {
      title: "Coupon Code",
      dataIndex: "coupon_code",
      width: "30%",
      align: "center",
    },
    {
      title: "Discount %",
      dataIndex: "discount_per",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (status) => (
        <Space>
          {status === "Active" ? (
            <span className="text-success">{status}</span>
          ) : (
            <span className="text-danger">{status}</span>
          )}
        </Space>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (_, record) => (
        <Space>
        <Link to={"/admin/edit-coupon/" + record.id}>
          <Button
            type="primary"
            title="Edit"
            style={{ padding: "4px 8px" }}
            href={"../admin/edit-coupon/" + record.id}
          >
            {" "}
            <strong>
              {" "}
              <EditOutlined
                style={{
                  fontWeight: "900",
                  fontSize: "16px",
                  verticalAlign: "middle",
                }}
              />{" "}
            </strong>{" "}
          </Button>
          </Link>

          <Popconfirm
            title="Are you sure want to delete ?"
            key={record.id}
            onConfirm={() => dispatch(deleteCoupon(record.id))}
          >
            <Button
              danger
              type="primary"
              title="Delete"
              style={{ padding: "4px 8px" }}
            >
              {" "}
              <strong>
                {" "}
                <DeleteOutlined
                  style={{
                    fontWeight: "900",
                    fontSize: "16px",
                    verticalAlign: "middle",
                  }}
                />{" "}
              </strong>{" "}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Gosoftware - Coupon Code</title>
      </Helmet>

      <div className="admin-coupon-code-page">
        <div className="admin-coupon-code-page-data">
          <div className="admin-coupon-code-page-title">
            <h4>Coupon Code</h4>
            <Link to="/admin/add-coupon" className="admin-page-button">
              {" "}
              <PlusOutlined /> Add Coupon
            </Link>
          </div>
          <div className="admin-coupon-code">
            {isCouponListFetching ? (
              <p style={{ textAlign: "center", margin: "160px 0" }}>
                <Loader />
              </p>
            ) : (
              <>
              {couponList?.length === 0 ? 
              <NoDataFound title="No coupon found" /> : 
              <Table
                // bordered
                className="ant-table-admin"
                dataSource={couponData}
                columns={columns}
                // loading={loading}
              />
              }</>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CouponCode;
