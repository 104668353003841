import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FaPhoneAlt, FaMapMarkerAlt, FaRegCalendarAlt } from "react-icons/fa";
import { RiMailOpenLine } from "react-icons/ri";

function Footer() {
  return (
    <>
      <Container
        fluid
        style={{ backgroundColor: "#0B162A", paddingTop: "2rem" }}
      >
        <Container className="gosoftware-padding">
          <Row>
            <Col sm={4}>
              <div>
                <img
                  src={`${process.env.PUBLIC_URL}/Assets/logofooter.png`}
                  className="mt-2"
                  alt="Gosoftware"
                />
                <p className="text-light text-light-footer mt-4">
                  We provide award-winning, security products We are offering
                  the high quality genuine and legal licenses you need at
                  unbeatable prices.
                </p>
                <img
                  src={`${process.env.PUBLIC_URL}/Assets/trustedsite-logo.png`}
                  width="160px"
                  alt="Trustedsite certified secure"
                />
              </div>
            </Col>

            <Col sm={3}>
              <div>
                <h4 className="footer-heading">Contact Us</h4>
                <p className="mb-1">
                  <a
                    className="text-light footertext"
                    href="tel:+18887061596"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaPhoneAlt /> +1888-706-1596
                  </a>
                </p>

                <p className="mb-1">
                  <a
                    className="text-light footertext"
                    href="mailto:support@gosoftwarebuy.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <RiMailOpenLine /> support@gosoftwarebuy.com
                  </a>
                </p>

                <p className="mb-1">
                  <span className="text-light footertext">
                    <FaMapMarkerAlt /> 136-140 Old Shoreham Road, Brighton,
                    United Kingdom, BN3 7BD
                  </span>
                </p>

                <p className="mb-1">
                  <span className="text-light footertext">
                    <FaRegCalendarAlt /> Mon.-Fri : 9:00 AM- 7:00 PM PST
                  </span>
                </p>
              </div>
            </Col>

            <Col sm={2}>
              <div>
                <h4 className="footer-heading">About</h4>
                <p className="mb-1">
                  <Link to="/authorized-reseller" className="footerLink">
                    Authorized Reseller
                  </Link>
                </p>
                <p className="mb-1">
                  <Link to="/sustainability" className="footerLink">
                    Sustainability
                  </Link>
                </p>
                <p className="mb-1">
                  <Link to="/affiliate-program" className="footerLink">
                    Affiliate Program
                  </Link>
                </p>
                <p className="mb-1">
                  <Link to="/contact" className="footerLink">
                    Contact Us
                  </Link>
                </p>
              </div>
            </Col>

            <Col sm={2}>
              <div>
                <h4 className="footer-heading">Quick Links</h4>

                <p className="mb-1">
                  <Link to="/terms-conditions" className="footerLink">
                    Terms & Conditions
                  </Link>
                </p>
                <p className="mb-1">
                  <Link to="/privacy-policy" className="footerLink">
                    Privacy Policy
                  </Link>
                </p>
                <p className="mb-1">
                  <Link to="/refund-policy" className="footerLink">
                    Refund Policy
                  </Link>
                </p>
                <p className="mb-1">
                  <Link to="/order-shipment" className="footerLink">
                    Order & Shipment
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>

        <hr />
        <Container>
          <div style={{ textAlign: "center" }}>
            <p className="text-light text-light-footer">
              Disclaimer: Product images, logos, and brands displayed on our
              website may be protected by copyright. The copyright of this
              belongs to the brand owner. these products can also be purchased
              from the respective brand owners or somewhere else.
            </p>
            <p className="text-light text-light-footer">
              @2022 Gosoftware UK Ltd. Company No. 12476066 | VAT: GB380889155 |
              All Rights Reserved. All third-party trademarks are the property
              of their respective owners.
            </p>
          </div>
        </Container>
        <hr />
      </Container>
    </>
  );
}

export default Footer;
